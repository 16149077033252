import * as CryptoJs from 'crypto-js'
import Config from '../lib/config'

export const FirstLetterCaps = (data) => {
  try {
    data = data[0].toUpperCase() + data.substring(1, data.length);
    return data;
  }
  catch (err) {
    console.log('FirstLetterCaps error', err)
    return data;
  }
}

export const isEmpty = value =>
  value === undefined ||
  value == "undefined" ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0) ||
  (typeof value === 'string' && value === '0') ||
  (typeof value === 'number' && value === 0);

export const EncryptData = (data) => {
  if (typeof (data) == 'string') {
    return CryptoJs.AES.encrypt(data, Config.KEY).toString()
  }
  else {
    return CryptoJs.AES.encrypt(JSON.stringify(data), Config.KEY).toString()
  }
}

export const DecryptData = (data) => {
  try {
    var bytes = CryptoJs.AES.decrypt(data, Config.KEY);

    var decryptedData;
    try {
      decryptedData = JSON.parse(bytes.toString(CryptoJs.enc.Utf8));
    }
    catch (err) {

      try {
        decryptedData = bytes.toString(CryptoJs.enc.Utf8);


      }
      catch (dcpterr) {

        return '';
      }
    }
    return decryptedData;
  }
  catch (error) {
    return '';
  }
}

export const handleCallback = (callback) => {
  return async (data) => {
    console.log('kdsjlsjdlsjd', callback)
    try {
      return await callback(data);
    } catch (error) {
      console.error("handleCallback error", error)
      return ({
        error: error,
        Success: false,
        data: [],
        msg: `${'From user' + error.toString()}`
      })
    }
  };
};
