import React, {
    forwardRef,
    useImperativeHandle
} from 'react';

import { Button } from '@material-ui/core';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import isEmpty  from 'lib/isEmpty';
import randomInteger from 'random-int';

import config from '../../lib/config';
import SINGLE from '../../ABI/SINGLE.json'
import MULTIPLE from '../../ABI/MULTIPLE.json'
import Convert from '../separate/Convert'
import {
    TokenPriceChange_update_Action,
    checkOtherPlatformDetais1155
} from '../../actions/v1/token';
import { getReceipt } from 'actions/v1/getReceiptFunc';
import Trade from '../../ABI/Trade.json'
import Convert1 from '../../views/separate/Convert1'


import { connectWallet, WalletConnect } from '../../views/hooks/useWallet';
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import { useSelector,useDispatch } from "react-redux";
import { network } from "../../views/network"
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Settings } from '@material-ui/icons';
import Market from "../../ABI/market"
import { UserCheck } from 'actions/v1/user';
toast.configure();
let toasterOption = config.toasterOption;



export const CancelOrderRef = forwardRef((props, ref) => {


    const dispatch = useDispatch();
    const history = useHistory()

    const Wallet_Details = useSelector(state=>state.wallet_connect_context);

  
    const [FormSubmitLoading, Set_FormSubmitLoading] = React.useState('start');
    const [TokenPrice, Set_TokenPrice] = React.useState(0);
    const [Owners, Set_Owners] = React.useState({});
    const [Item_Owner, Set_Item_Owner] = React.useState({});
    const [Items, Set_Item] = React.useState({}); 
 
    var {
        UserAccountAddr,
      } = props;
    async function FormSubmit(){
        if(Wallet_Details.providerss) {

            var web3 = new Web3(Wallet_Details.providerss)
            if (
                web3
            ) {
                try{
                    var CoursetroContract = new web3.eth.Contract(
                        Market,
                       Wallet_Details.networkConfiguration.TradeContract
                    );
         
                    var handle=null;
                    var receipt=null;
                    var signature = null;
                    var _nonce = null;
                    var Randomname = null;
                    Set_FormSubmitLoading('processing');
                    var web3 = new Web3(Wallet_Details.providerss)
                        if (web3) {
                            // var web3Rpc = new Web3(config.RPC_URL)
                            // console.log("qweqwewqewqewqewqqwvbv", web3Rpc);
                            var randomNum = randomInteger(10000000, 100000000);
                            console.log("sdcfsdfsdfsdvsdfdsf", randomNum);
                            var generator = require('generate-password');
                            var password = generator.generate({
                                length: 10,
                                numbers: true
                            });
                            console.log("asdasdasdas", password);
                            if (web3) {
                                // console.log("checking window.ethereum", web3.givenProvider,Wallet_Details.providerss)
                                var web3RpcPro = new Web3(web3.providers);
                                console.log("asddfsfsdvbvbvcb", web3RpcPro, Wallet_Details.UserAccountAddr);
                                const to = Wallet_Details.UserAccountAddr
                                const _amount =  0;
                                console.log("ajhghjas", _amount);
                                _nonce = Date.now();
                                console.log("qwewqewqreqwrqrq", password);
    
                                Randomname = password;
                                var tot = _nonce + Number(randomNum);
                                _nonce = tot;
                                console.log("qwewqeqwewqeqweasdas", to, _amount, password, tot);
                                const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
                                console.log("asdsadasfdafaf", result);
                                const signhash = await web3.eth.personal.sign(result, to);
                                console.log("Signature", result, signhash)
                                if (signhash) {
                                    signature = signhash;
                                }
                            }
                        }
                    
                    var postData = {
                        tokenOwner: Wallet_Details.UserAccountAddr,
                        tokenCounts: Items.tokenCounts,
                        tokenPrice: 0,
                        putonsalequantity: 0,
                        PutOnSaleType: "UnLimitedAuction",
                        Randomname: Randomname ,
                        _nonce: _nonce,
                        signature: signature,
                    }
                    var Resp = await TokenPriceChange_update_Action(postData)
                    if (Resp && Resp.data && Resp.data.RetType && Resp.data.RetType === 'success') {
                        Set_FormSubmitLoading('done');

                        toast.success("Collectible Cancelled successfully", toasterOption)
                        window.$('.modal').modal('hide');
                        setTimeout(() => {
                            // history.push("/my-items")
                            props.GetUpdatas();
                        }, 1000);

                    }  
                // await CoursetroContract.methods
                // .cancelOrder(
                //     Items.tokenCounts,
                // )
                // .send({ from: Wallet_Details.UserAccountAddr })
                // .on('transactionHash', async (transactionHash) => {
                //     handle = setInterval(async () => {
                     
                //       receipt = await getReceipt(web3, transactionHash)
                //       //console.log("fghmjdghmjkfghmdhgm",receipt);
                //       clr1();
                //     }, 8000) })
            }
             
                catch(error)  {
                    Set_FormSubmitLoading('try');
                    toast.error('Order not placed', toasterOption)}
                
            }
            async function clr1() {
                if (receipt != null){     
                    clearInterval(handle);
                    if(receipt.status===true){  
                    
                    // Set_FormSubmitLoading('done');
                }
                }
            }
        
        }
       
    }

    var {
        item,
        UserAccountAddr,
    } = props;

    useImperativeHandle(
        ref,
        () => ({
            async CancelOrder_Click(item, Owner, itemOwner) {
                let useractive = await UserCheck({ currAddr: Wallet_Details?.UserAccountAddr });
                if (useractive?.userValue?.activate) {

                }
                else {
                    toast.error("User banned by the admin")
                    return false;
                }
                if (Wallet_Details.WalletConnected === "true" || Wallet_Details.UserAccountAddr != null) {
                    var chainid = item.tokenowners_current?.[0].SelectedNetwork || item?.tokenowners_current?.SelectedNetwork
                    var accountDetailsss = ''
                    //console.log("dfbhsfdbn",item,Wallet_Details.networkConfiguration.Chainid,item.tokenowners_current[0].SelectedNetwork);
                    if (Wallet_Details.networkConfiguration.Chainid == item.tokenowners_current[0].SelectedNetwork) {
                        accountDetailsss = true
                    }
                    else {
                        //console.log("item.tokenowners_current[0]",item.tokenowners_current[0].SelectedNetwork);
                        if (item?.tokenowners_current[0]?.SelectedNetwork) {
                            chainid = item.tokenowners_current[0].SelectedNetwork
                        }
                        //console.log("sbhgsdrbhg",chainid);
                        var accountDetailsss = await switchNetwork(chainid)
                    }
                    // setTimeout(async() => {
                    if (chainid === Wallet_Details.networkConfiguration.Chainid) {
                        var web3 = new Web3(Wallet_Details.providerss)
                        var data = {
                            Singleaddress: Wallet_Details.networkConfiguration.singleContract,
                            multipleContract: Wallet_Details.networkConfiguration.multipleContract
                        }
                        let CoursetroContract = new web3.eth.Contract(
                            Market,
                            Wallet_Details.networkConfiguration.TradeContract,
                        )

                        var balance = await checkOtherPlatformDetais1155(item, itemOwner, item.type, web3, data, CoursetroContract);
                        //console.log('sdfvbalvfsdancsdfe>sdgv>>>sfdv>s>>vs>',balance)
                        if (balance === 0) {
                            toast.warning("You won't buy at this moment please refresh you data", toasterOption);
                            // setTimeout(() => {
                            //     history.push("/")
                            // }, 1000);
                            return false;
                        }
                        else {
                            props.Set_HitItem(item);
                            Set_Item(item)
                            Set_TokenPrice(0);
                            Set_Owners(Owner)
                            Set_Item_Owner(itemOwner)
                            window.$('#cancel_order_modal').modal('show');
                        }
                    }
                    else {
                        if (Wallet_Details.UserAccountAddr != "") {
                            window.$('#connect-wallet').modal('show');

                        }
                    }
                }
                // }, 1000);
            }
        }),
    )

    async function switchNetwork(configdata) {
        var type = ""
        var networkConfiguration = {}
        if (configdata) {
            if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

                type = "MetaMask"
            }
            else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
                type = "WalletConnect"
            }

            //console.log("connecttype............", type)
            var accountDetails = await connectWallet(type, configdata)
            //console.log("accountDetailsin create page......................", accountDetails)
            //  const id=toast.loading("Wallet Connecting...")
            var web3 = new Web3(window.ethereum);
            //console.log("dfghrtfh",web3);
            if (window.ethereum.isMetaMask == true) {
                const chainId = await web3.eth.getChainId();
                //console.log("fghdtgj",chainId);
                if (chainId === network.ETH.Chainid) {
                    networkConfiguration = network.ETH
                }
                else if (chainId === network.BSC.Chainid) {
                    networkConfiguration = network.BSC
                }
            }
            //console.log("networkConfiguration",networkConfiguration);
            // setPriceoption()
            if (accountDetails != '') {
                //console.log("Wallet Connecting...increate", accountDetails.web3._provider);
                dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                        UserAccountAddr: accountDetails.accountAddress,
                        UserAccountBal: accountDetails.coinBalance,
                        WalletConnected: "true",
                        Wen_Bln: accountDetails.tokenBalance,
                        Accounts: accountDetails.accountAddress,
                        providerss: accountDetails.web3._provider,
                        networkConfiguration: networkConfiguration
                    }
                })
            }
        }
        //   var ReqData = {
        //     addr: String(accountDetails.accountAddress).toLowerCase()
        // }
        // var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
        // if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
        //     return Resp.data.data.User
        // } else {
        //     return null
        // }
        return accountDetails
    }

    const SettoInitalValueFuc = () => {
        Set_FormSubmitLoading('start');
      }

    return (
        <div className="modal fade primary_modal" id="cancel_order_modal" tabIndex="-1" role="dialog" aria-labelledby="cancel_order_modalCenteredLabel" aria-hidden="true" data-bs-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h5 className="modal-title" id="cancel_order_modalLabel">Cancel Order</h5>
                        <div className="change_price_img_div">
                        {
                                Items && Items.image && 
                                (String(Items.image).split('.').pop() === "mp4"||
                                (String(Items.image).split('.').pop() === "webm")||
                                (String(Items.image).split('.').pop() === "WEBM")||
                                (String(Items.image).split('.').pop() === "ogv")||
                                (String(Items.image).split('.').pop() === "OGV")
                              ) &&
                                <video
                                    id="my-video"
                                    className="img-fluid"
                                    autoPlay controls playsInline loop muted
                                    preload="auto"
                                    // poster={Items.ipfsimage != "" ? `${config.IPFS_IMG}/${Items.ipfsimage}` : `${config.Back_URL}/nftImg/${Items.tokenCreator}/${Items.image}`}
                                    // data-setup="{}"
                                >
                                    <source src={Items.additionalImage?(Items.additionalImage === "" ? `${config.IPFS_IMG}/${Items.ipfsimage}` : `${config.Back_URL}/nftImg/${Items.tokenCreator}/${Items.additionalImage}`):`${config.IPFS_IMG}/${Items.ipfsimage}`} type="video/mp4" />
                                </video>}
                                {
                                Items 
                                && Items.image 
                                && ((String(Items.image).split('.').pop() === "mp3"
                                ||String(Items.image).split('.').pop() === "aac"
                                ||String(Items.image).split('.').pop() === "AAC"
                                ||String(Items.image).split('.').pop() === "FLAC"
                                ||String(Items.image).split('.').pop() === "flac") ) &&
                               <>
                                 <img src={config.AudioImg}   className="img-fluid" alt='test'/>
                         
                               <audio
                                   className=""
                                    // autoPlay
                                    muted
                                    controls
                                    playsInline
                                    loop
                                >
                                    <source src={Items.additionalImage?(Items.additionalImage === "" ? `${config.IPFS_IMG}/${Items.ipfsimage}` : `${config.Back_URL}/nftImg/${Items.tokenCreator}/${Items.additionalImage}`): `${config.IPFS_IMG}/${Items.ipfsimage}`} type="audio/mp3" />
                                </audio>
                                </>
                                }
                          {Items 
                            && Items.image 
                            &&((String(Items.image).split('.').pop() === "webp"
                            ||String(Items.image).split('.').pop() === "WEBP"
                            ||String(Items.image).split('.').pop() === "gif"
                            ||String(Items.image).split('.').pop() === "jpg"
                            ||String(Items.image).split('.').pop() === "GIF"
                            ||String(Items.image).split('.').pop() === "JPG"
                            ||String(Items.image).split('.').pop() === "JPEG"
                            ||String(Items.image).split('.').pop() === "jpeg"
                            ||String(Items.image).split('.').pop() === "png"
                            ||String(Items.image).split('.').pop() === "PNG"))&&
                                <img src={Items.additionalImage?(Items.additionalImage === "" ? `${config.IPFS_IMG}/${Items.ipfsimage}` : `${config.Back_URL}/nftImg/${Items.tokenCreator}/${Items.additionalImage}`):`${config.IPFS_IMG}/${Items.ipfsimage}`} alt="Collections" className="img-fluid" />
                            }
                            {Items 
                            && Items.image 
                            &&(String(Items.image).split('.').pop() == "pdf")
                                &&
                                <img src={Items.thumb?`${config.Back_URL}/Thumb_compressedImage/${Items?.tokenCreator}/${Items.thumb}`: config.AudioImg} alt="Collections" className="img-fluid" />
                            }
                        </div>
                        <p className="text-gray font_we_600 font_12">You are about to delete Instant Sale for 
                        <span className="buy_desc_sm_bold pl-1 bold_red owner_break">{Items.tokenName} </span>
                            for
                            <span className="buy_desc_sm_bold pl-1 bold_red owner_break" styel={{ fontSize: 10 }}>
                              

                            {
                                    !isEmpty(Owners)
                                    &&Owners.name!==""
                                    ? <span className="word_brak_text_inline_new" title={"Owner : "+Owners.name}>{Owners.name}</span>
                                    : <span className="word_brak_text_inline_new" title={"Owner : "+Item_Owner&&Item_Owner.tokenOwner}>{String(Item_Owner&&Item_Owner.tokenOwner).slice(0,10).concat("...")}</span>

                                }
                            </span>
                         </p>             
     

                         <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close"   disabled={(FormSubmitLoading==='processing'||FormSubmitLoading==='done')} onClick={()=>SettoInitalValueFuc()}>

                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body px-0 pt-0">
                        <form className="px-4 bid_form" >
                           
                         <label htmlFor="bid">
                    
                         <Convert
                         item={Number(Item_Owner.tokenPrice)}
                         
                         coinName={Item_Owner.CoinName}
                         convertVal={1}/>
                      {Item_Owner.CoinName}
                      {/* $( {Item_Owner.CoinName == "BLKZ" ? Wallet_Details.tokenAddress.BiddingToken_USD * TokenPrice:Wallet_Details.tokenAddress.XDC_Currency_USD * Item_Owner.tokenPrice }) */}
                            </label>
                                  
                            <div className="text-center mt-3">
                                <Button
                                    type="button"
                                    className="primary_btn btn-block"
                                    onClick={(FormSubmitLoading==='start'||FormSubmitLoading==='try')&&FormSubmit}
                                    disabled={(FormSubmitLoading==='processing'||FormSubmitLoading==='done')}
                                >
                                    {FormSubmitLoading === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                    {FormSubmitLoading === 'processing' && 'In-Progress'}
                                    {FormSubmitLoading === 'done' && 'Done'}
                                    {FormSubmitLoading === 'start' && 'Start'}
                                    {FormSubmitLoading === 'try' && 'Try-Again'}
                                </Button>
                                <Button className="btn  btn-block cancel" 
                             

                                 data-bs-dismiss="modal" aria-label="Close"  disabled={(FormSubmitLoading==='processing')} onClick={()=>SettoInitalValueFuc()}>

                                    Cancel</Button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
})

