import React, { useState, useEffect, useRef, useContext, } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect, useHistory, Link, useLocation } from "react-router-dom";
import './index.css';
import './styles.css';
import Mail from 'assets/images/registermail.png'
import Password from 'assets/images/registerpass.png'
import { useDispatch } from "react-redux";
import { Account_Connect } from "actions/redux/action";
import { Modal, Button } from "react-bootstrap";
import { Cookies, useCookies } from 'react-cookie';

// pages for this product
import Home from "views/Home.js";
import Create from "views/Create.js";
import CreateSingle from "views/Create-single.js";
import About from "views/About.js";
import EditProfile from "views/edit-profile.js";
import SupportTicket from "views/SupportTicket";
import Myitems from "views/my-items.js";
import Following from "views/following.js";
import Privacypolicy from "views/Privacypolicy.js";
import Terms from "views/Termsofservice.js";
import Register from "views/register.js";
import Explore from "views/explore.js";
import Art from "views/art.js";
import Art1 from "views/art1";
import Artdetail from "views/artdetail";
import Info from "views/info.js";
import community from "views/communty.js"
import Whatisnft from './views/whatisnft.js';
import Typesofnft from './views/typesofnft.js';
import Globalrush from './views/globalrush.js';
import Nftusecase from './views/nftusecase.js';
import Investnft from './views/investnft.js';
import Probabilitynft from './views/probabilitynft.js';
import Terminology from './views/terminology.js';
import Nftmarketplaces from './views/nftmarketplaces.js';
import Transaction from './views/transaction.js';
import Howtocreate from './views/howtocreate.js';
import Howtobuy from './views/howtobuy.js';
import Howtosell from './views/howtosell.js';
import Edafacepartnership from './views/edafacepartnership.js';
import Conclusion from './views/conclusion.js';

import collectionlist from "views/collectionList.js"

import AddCollection from "../src/views/addcollection"
import UserCollection from "../src/views/usercollections"

import Activity from "views/activity.js";
import Search from "views/search.js";
import comingsoon from "views/comingsoon.js";
import Faq from "views/faq.js";
import List from "views/List";
import OtpInput from 'react-otp-input';

// added by me
import '@metamask/legacy-web3'
import 'react-toastify/dist/ReactToastify.css';
import config from './lib/config';
import { toast } from 'react-toastify';
import Icon1 from "assets/images/icon1.jpg";
import Icon2 from "assets/images/wallet_03.png";
import { submitdata, generatepass, Forgotpassword, Changepassword, verifyOTP, loginTFA } from './actions/v1/user'
import Reset from "./views/resetpassword"
// import { async } from "@firebase/util";
import { data } from "jquery";
import nfttc from "../src/views/nfttc";
import Bulkmint from "../src/views/bulkmint"
import { create } from "nouislider";
import Resources from "views/resources";
import BookView from "views/PdfReaders/BookView";
import PDFReader from "views/PdfReaders/PDFReader";

toast.configure();
let toasterOption = config.toasterOption;



export default function Indexs(props) {



    const [showOTP, setShowOTP] = useState(false);

    const handleCloseOTP = () => setShowOTP(false);
    const handleShowOTP = () => setShowOTP(true);

    const [showFA, setShowFA] = useState(false);

    const handleCloseFA = () => setShowFA(false);
    const handleShowFA = () => setShowFA(true);

    const [otp, setOtp] = useState('');
    const [twofa, setTwofa] = useState('');

    const closeLoginModal = () => {
        document.getElementById('login_modalclosing').click();
    }


    let history = useHistory();
    const location = useLocation()
    //console.log('locaaaaaa',location)
    var [changepassword, setChangePassword] = useState(location);
    const [username, set_username] = useState('')
    const [password, set_password] = useState('')
    const [confirmpassword, set_confirmPassword] = useState('')
    const [checkpass, setcheckpass] = useState()
    const [checkemail, Set_checkemail] = useState('')
    const [checkconpass, setcheckconpass] = useState('')
    const [pass, setPass] = useState(true);
    const [conpass, setConpass] = useState(true);
    const [login, setLogin] = useState(false);
    var [reslog, setReslog] = useState('login')
    const [forgotpassword, setforgotPassword] = useState(false);
    const [forgetpopup, setforgetpopup] = useState(false);
    var [message, setMessage] = useState({});
    var dispatch = useDispatch();

    // timer-count
    const [counter, setCounter] = useState(300)
    const [Seconds, setseconds] = useState(0)
    const [Minutes, setminutes] = useState(0)
    const [Enablebtn, setEnablebtn] = useState(false)
    const cookies = new Cookies();


    const handleOpenLogin = () => {
        setLogin(true);
    }
    const handleCloseLogin = () => {
        setLogin(false);
    }
    const handleOpenPassword = () => {
        //console.log("Pujuku");
        setforgotPassword(true);
    }
    const handleClosePassword = () => {
        setforgotPassword(false);
    }
    const [validation, setvalidate] = useState()
    const [redirect, setredirect] = useState()
    const onInputchange = (e) => {
        const { id, value } = e.target
        //console.log("id,value",id,value);
        Set_checkemail('')
        setcheckconpass('')
        setcheckpass('')
        if (id == "Email") {
            if (checkemail !== '') Set_checkemail('');
            set_username(value)
        }
        if (id == "password") {
            if (checkpass === false) setcheckpass();
            set_password(value)
        }
        if (id == 'confirmpassword') {
            set_confirmPassword(value)
        }
    }

    useEffect(() => {
        if(showOTP){
            if (counter == 0) {
                setEnablebtn(true);
            }
            const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
            const seconds = String(counter % 60).padStart(2, 0);
            setseconds(seconds)
            const minutes = String(Math.floor(counter / 60)).padStart(2, 0);
            setminutes(minutes)
            return () =>
                clearInterval(timer);
        }
        
    }, [counter,showOTP]);

    async function Submit() {
        // //console.log("dsfbghsfgbh",username,password);
        var data = { username, password }
        localStorage.getItem('logverify') ? data.LoginOTP = localStorage.getItem('logverify') : data.LoginOTP = null;
        data.from = "login";
        // //console.log('fhgfdshfdhsf',data);
        var emailcheck = await submitdata(data)
        console.log("dfgadgaedr",emailcheck,emailcheck.message.data.message)
        setcheckpass(emailcheck.message.data)

        if (emailcheck?.message?.data?.status === false) {
            if (localStorage.getItem("logverify") !== null) localStorage.removeItem("logverify")
            if(emailcheck.message.data.message == 'emailEnable'){
                handleShowOTP()
                setCounter(300)
                closeLoginModal()
            toast.success(emailcheck?.message?.data?.data)
            }
            if(emailcheck.message.data.message == '2faEnabled'){
                handleShowFA()
                closeLoginModal()
            toast.success(emailcheck?.message?.data?.data)

            }
        }
        else if (emailcheck?.message?.data === "invalid email") {
            // toast.warning(emailcheck?.message?.data)
            Set_checkemail(emailcheck.message.data)
        }
        else if (emailcheck.message.data == "Please Verify Your Email Address") {
            setReslog('verify')
        }
        else if(emailcheck.message?.data?.error){
            closeLoginModal();
            return toast.error(emailcheck?.message?.data?.msg)
        }
    }


    const redirectfun = async () => {
        // history.push("/Register")
        window.location.reload()
    }


    const ResetPassword = async () => {
        var data = { username }
        var emailcheck = await Forgotpassword(data)
        //console.log('fhhgjhg',emailcheck)
        if (emailcheck?.message?.data == 'invalid email') {
            Set_checkemail(emailcheck?.message?.data)
        }
        else {

            // window.$("#login").modal("hide");
            // document.getElementById('logclose').click()
            setMessage({ resetpassword: "We have e-mailed your password reset link!" })
            // toast.success("Password Change Link Has been Sent to your Email")
        }
        // var sendmail = await resetpassword()


    }

    useEffect(() => {
        ChangePasswordCheck();
    }, [changepassword])

    var ChangePasswordCheck = () => {
        //console.log('changepassswo',forgetpopup,reslog,changepassword.pathname.split('/')[1])
        if (location.pathname.split('/')[1] == 'resetpassword') {
            setforgetpopup(true)
            setReslog(false)
        }
        else if (location.pathname.split('/')[1] == 'verify') {
            setReslog('login')
            setforgetpopup(false);
        }
        else {
            setforgetpopup(false)
        }
    }

    const Validate = (data) => {
        var emailcheck = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        var passcheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        var i = 0;
        //console.log('fgfhhgs',data)
        if (data.username === '') {
            Set_checkemail('Enter Email')
            ++i;
        }
        else if (!(emailcheck).test(data.username)) {
            Set_checkemail("Enter Valid EmailId")
            ++i;
        }
        if (data.password == '') {
            setcheckpass('Enter Password')
            ++i;
        }
        else if (passcheck.test(data.password) == false) {
            setcheckpass('Must have at least one small letter, a special character, a capital letter, and a digit')
            ++i;
        }
        if (data.password !== data.confirmpassword) {
            setcheckconpass("Password And Confirm Password Must be Same")
            ++i;
        }
        return i;
    }

    var ChangePassword = async () => {
        var data = { username, password, confirmpassword };
        data.key = location.pathname.split('/')[2];
        var validation = Validate(data);
        if (validation == 0) {
            //console.log('fgfhhgs111',location.pathname.split('/')[2])
            data.from = "changepassword"
            var resp = await Changepassword(data)
            //console.log('fgfvfvgf',resp.message.data)
            if (resp.message.data == 'success') {
                toast.success("Password Changed successfully")
                localStorage.setItem("registedEmail", username)
                // window.location = "/"
                history.push('/');
            }
            else {
                //console.log('jdshjsdhgjhsd',resp)
                if (resp.message.data == 'Invalid Password') {
                    toast.error('Invalid Password')
                }
                else if (resp.message.data == 'invalid email') {
                    toast.error('Invalid Email')
                }
                else if (resp.message.data == 'Please Enter New Password') {
                    toast.error(resp.message.data);
                    setcheckpass("Can't set Old password as New passsword")
                }
            }
        }
        else {
            toast.error("Validation Failed")
        }
    }

    var Resendemail = async () => {
        setMessage('')
        var data = { username };
        if (data?.username) {
            data.from = "resend";
            var resp = await Changepassword(data)
            //console.log('gfhfhs',resp)
            if (resp.message.data) {
                // toast.success("Password Changed successfully")
                setTimeout(() => {
                    setMessage({ verifymail: "A fresh verification link has been sent to your email address." })
                }, 1000)
            }
        }
    }

    const validOTP = async ()=> {
        try {
            if(otp != '' && otp != null && otp != undefined){
                console.log("validOTP_validOTP",{otp: otp, email: username})
                const valid = await verifyOTP({otp: otp, email: username});
                console.log("valid_verifyOTP",valid.data)
                if(valid.data.status == false){
                    toast.error(valid.data.message)
                }else{
                    localStorage.setItem("registedEmail", username)
                    dispatch({
                        type: Account_Connect,
                        Account_Detail: {
                            Regname: valid.data.Regname,
                            Profile: valid.data.Profile,
                            Customurl: valid.data.Customurl,
                            _id: valid.data._id,
                        }
                    })
                    window.location = "/"
                    // history.push('/');
                    // history.push('/');
                }
            }
        } catch (e) {
          console.log("validOTP",e)  
        }
    }

    const resendOTP = async () => {
        try {
            setCounter(300);
            setEnablebtn(false)
            var data = { username, password }
            localStorage.getItem('logverify') ? data.LoginOTP = localStorage.getItem('logverify') : data.LoginOTP = null;
            data.from = "login";
            var emailcheck = await submitdata(data);
            console.log("emailcheckemailcheckemailcheck", emailcheck)
        } catch (error) {
            console.log("Errrr", error)
        }
    }
    const tfaEnable = async ()=> {
        try {
            var payload = {tfaCode: twofa, email:username}
            const check = await loginTFA(payload);
            console.log("c_checkcheck",check);
            if(check.data.status == false){
                toast.error(check.data.message)
            }else{
                toast.success(`Welcome to Edaface NFT Market`)
                localStorage.setItem("registedEmail", username)
                dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                        Regname: check.data.Regname,
                        Profile: check.data.Profile,
                        Customurl: check.data.Customurl,
                        _id: check.data._id,
                    }
                })
                // window.location = "/"
                history.push('/');
            }
        } catch (e) {
            console.log("tfaEnable", e)
        }
    }

    // function getCookie(cookieName) {
    //     let name = cookieName + '=';
    //     let ca = document.cookie.split(';');
    //     for (let i = 0; i < ca.length; i++) {
    //       let c = ca[i];
    //       while (c.charAt(0) === ' ') {
    //         c = c.substring(1);
    //       }
    //       if (c.indexOf(name) === 0) {
    //         return c.substring(name.length, c.length);
    //       }
    //     }
    //     return '';
    //   }

    // function checkCookie(cookieName) {
    //     let cookie = getCookie(cookieName);
    //     if (cookie !== '') {
    //       console.log(cookie + 'exist');
    //     //   SetTokendata(cookie)
    //     } else {
    //       console.log('cookie doesnot exist set the cookie again');
    //     }
    //   }
    // const cookieChangeListener = (name, value) => {
    //     checkCookie(config.cookiename)
    //     console.log('The cookie ', name, ' changed to ', value)
    //     // SetTokendata(value)
    //   }
    
    // cookies.addChangeListener(cookieChangeListener);

    return (
        <>
            <BrowserRouter basename="/" >
                <Switch>
                    <Route path="/bulkmint" component={Bulkmint} />
                    <Route path="/bulkmint/:type" component={Bulkmint} />
                    <Route path="/literature/:type" component={Bulkmint} />

                    <Route path="/my-items/:activity?" component={Myitems} />
                    <Route path="/create-single" component={CreateSingle} />
                    <Route path="/create-multiple" component={CreateSingle} />
                    <Route path="/faq" component={Faq} />
                    <Route path="/edit-profile" component={EditProfile} />
                    <Route path="/addCollections" component={AddCollection} />
                    <Route path="/addCollectionss/:name/:form" component={AddCollection} />
                    <Route path="/search" component={Search} />
                    <Route path="/my-items" component={Myitems} />
                    <Route path="/Register" component={Register} />
                    <Route path="/Following" component={Following} />
                    <Route path='/list/:address/:id' component={List} />
                    <Route path="/create/" component={Create} />
                    <Route path={"/create-polymultiple"} component={Create} />
                    <Route path={"/create-mintliterature"} component={Create} />
                    <Route path="/privacy-policy" component={Privacypolicy} />
                    <Route path="/about" component={About} />
                    <Route path="/community" component={community} />
                    <Route path="/terms-and-conditions" component={Terms} />
                    <Route path="/activity" component={Activity} />
                    {/* <Route path={["/explore","/explore/:Param_category"]} component={Explore} /> */}
                    {/* <Route path="/explore/:Param_category/:Param_subcategory" component={Explore} /> */}
                    {/* <Route path="/explore/:Param_category" component={Explore} /> */}
                    <Route path="/explore/:category" component={Art1} />
                    <Route path="/art" component={Art} />
                    <Route path="/artdetail" component={Artdetail} />
                    <Route path="/art1/:category" component={Art1} />
                    <Route path="/comingsoon" component={comingsoon} />
                    <Route path="/resource/:resourcelink" component={Resources} />
                    {/* <Route path="/info/" component={Info} /> */}
                    <Route path="/info/:owneraddress/:collectionaddress/:tokenidval" component={Info} />
                    {/* React Pdf viewer and Book viewer */}
                    {/* <Route path="/bookview" component={BookView} /> */}
                    <Route path="/pdfview/:owner/:id" component={PDFReader} />
                    <Route path="/user/:paramAddress" component={Myitems} />
                    <Route path="/whatisnft" component={Whatisnft} />
                    <Route path="/typesofnft" component={Typesofnft} />
                    <Route path="/globalrushof_nft" component={Globalrush} />
                    <Route path="/nftusecase" component={Nftusecase} />
                    <Route path="/investnft" component={Investnft} />
                    <Route path="/supportticket" component={SupportTicket} />
                    <Route path="/probabilitynft" component={Probabilitynft} />
                    <Route path="/terminology" component={Terminology} />
                    <Route path="/nftmarketplaces" component={Nftmarketplaces} />
                    <Route path="/transaction" component={Transaction} />
                    <Route path="/howtocreate" component={Howtocreate} />
                    <Route path="/howtobuy" component={Howtobuy} />
                    <Route path="/howtosell" component={Howtosell} />
                    <Route path="/edafacepartnership" component={Edafacepartnership} />
                    <Route path="/conclusion" component={Conclusion} />
                    <Route path="/collectiondetail/:param/:customurl/:collAddr" component={Artdetail} />
                    <Route path="/collection/:param/:customurl/:collAddr" component={UserCollection} />
                    <Route path={"/resetpassword/:key"} component={Register} />
                    <Route path={"/verify/:verifykey"} component={Register} />
                    <Route path={"/login"} component={Register} />
                    <Route path={"/nfttc"} component={nfttc} />
                    <Route path="/:paramUsername" component={Myitems} />
                    
                    <Route path="/" component={Home} />
                    <Route exact path="/*" component={Home}>
                        <Redirect to="/" />
                    </Route>


                </Switch>
            </BrowserRouter>




            <div className={login == true ? "modal fade primary_modal show" : "modal fade primary_modal"} id="login" role="dialog" aria-labelledby="connect_modalCenteredLabel"  >
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        {reslog == 'login' &&
                            <>
                                <div className="modal-header text-center">
                                    <h5 className="modal-title" id="connect_modalLabel">Login</h5>
                                    <button type="button" className="close" data-dismiss="modal" id="login_modalclosing" aria-label="Close">


                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <ul className="connect_ul">
                                        {


                                            <li>
                                                <div className=" connect_card" >
                                                    <div className="card-body-mod">
                                                        <div className="">


                                                            <div className="">
                                                                <div className="w-100">
                                                                    <div className="registeruserinput mt-0">

                                                                        <input type="text" id="Email" placeholder="Email" onChange={(e) => onInputchange(e)} />
                                                                    </div>

                                                                </div>
                                                                {validation === false && <span className="text-danger"> email is required</span>}
                                                                {checkemail !== '' && <span className="text-danger"> {checkemail}</span>}


                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </li>


                                        }



                                        {


                                            <li>
                                                <div className=" connect_card" >
                                                    <div className="card-body-mod">
                                                        <div className="">


                                                            <div className="">
                                                                <div className="w-100">
                                                                    <div className="registeruserinput passowrdd mt-0">

                                                                        <input type={pass ? "password" : "text"} placeholder="Password" id="password" onChange={(e) => onInputchange(e)} />
                                                                        <span className="faeyee"><i className={pass ? "fas fa-eye-slash" : "fas fa-eye"} type="button" id="eye" onClick={() => setPass(!pass)}></i></span>
                                                                    </div>

                                                                </div>
                                                                {checkpass === false && <span className="text-danger">password invalid</span>}
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </li>

                                        }


                                    </ul>
                                    <a onClick={() => { Submit(); }} className="reglogintbn">Login</a>


                                    <div className="alreadycacc">
                                        <h3 className="tect-center">Don't have an account ?
                                            <a className="Login_det" onClick={() => redirectfun()}>
                                                Register
                                            </a>
                                        </h3>

                                    </div>
                                    <a
                                        onClick={() => { setReslog('reset') }}  //Forgetpassword();
                                        className="Login_detforgto"
                                    >
                                        Forgot Password
                                    </a>
                                </div>
                            </>}

                        {reslog == 'reset' &&
                            <><div className="modal-header text-center">
                                <h5 className="modal-title" id="connect_modalLabel">Reset Password</h5>
                                <button type="button" id="logclose" className="close" data-dismiss="modal" aria-label="Close">


                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                                <div className="modal-body">
                                    <ul className="connect_ul">
                                        {


                                            <li>
                                                <div className=" connect_card" >
                                                    <div className="card-body-mod">
                                                        <div className="">


                                                            <div className="">
                                                                <div className="w-100">
                                                                    {message?.resetpassword && <span className="text-danger forgotpsss"> {message?.resetpassword}</span>}
                                                                    <div className="registeruserinput mt-0">

                                                                        <input type="text" id="Email" placeholder="Email" onChange={(e) => onInputchange(e)} />
                                                                    </div>

                                                                </div>




                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </li>


                                        }



                                    </ul>
                                    <a onClick={() => ResetPassword()} className="reglogintbn">Send Reset Link</a>

                                    <div className="alreadycacc">
                                        <h3 className="tect-center">Don't have an account ?
                                            <a className="Login_det" onClick={() => redirectfun()}>
                                                Register
                                            </a>

                                        </h3>

                                    </div>
                                    <a
                                        onClick={() => { setReslog('login') }}  //Forgetpassword();
                                        className="Login_detforgto"
                                    
                                    >
                                        Login
                                    </a>
                                </div></>}

                        {
                            reslog == 'verify' &&
                            <><div className="modal-header text-center mt-3">
                                {message?.verifymail && <span className="text-danger forgotpsss">{message?.verifymail}</span>}
                                <h5 className="modal-title " id="connect_modalLabel">Please verify your email address.</h5>

                                <button type="button" id="logclose" className="close" data-dismiss="modal" aria-label="Close">


                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                                <div className="modal-body">
                                    <ul className="connect_ul">
                                        {


                                            <li>
                                                <div className=" connect_card" >
                                                    <div className="card-body-mod">
                                                        <div className="">


                                                            <div className="">
                                                                <div className="w-100">
                                                                    <div className="registeruserinput mt-0">

                                                                        <p> Before proceeding, please check your email for a verification link. If you did not receive the email, click the button to resend.</p>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </li>


                                        }



                                    </ul>
                                    <a onClick={() => Resendemail()} className="reglogintbn">Resend Email</a>
                                    <a
                                        onClick={() => { setReslog('login') }}  //Forgetpassword();
                                        className="Login_detforgto"
                                    >
                                        Login
                                    </a>
                                </div></>
                        }

                        {forgetpopup &&
                            <><div className="modal-header text-center">
                                <h5 className="modal-title" id="connect_modalLabel">Change Password</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">


                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                                <div className="modal-body">

                                    <ul className="connect_ul">
                                        {


                                            <li>
                                                <div className=" connect_card" >
                                                    <div className="card-body-mod">
                                                        <div className="">


                                                            <div className="">
                                                                <div className="w-100">
                                                                    <div className="registeruserinput mt-0">

                                                                        <input type="text" id="Email" placeholder="Email" onChange={(e) => onInputchange(e)} />
                                                                    </div>

                                                                </div>

                                                                {checkemail !== '' && <span className="text-danger"> {checkemail}</span>}


                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </li>


                                        }
                                        {


                                            <li>
                                                <div className=" connect_card" >
                                                    <div className="card-body-mod">
                                                        <div className="">


                                                            <div className="">
                                                                <div className="w-100">
                                                                    <div className="registeruserinput passowrdd mt-0">

                                                                        <input type={pass ? "password" : "text"} placeholder="New Password" id="password" onChange={(e) => onInputchange(e)} />
                                                                        <span className="faeyee"><i className={pass ? "fas fa-eye-slash" : "fas fa-eye"} type="button" id="eye" onClick={() => setPass(!pass)}></i></span>
                                                                    </div>

                                                                </div>
                                                                {checkpass !== '' && <span className="text-danger">{checkpass}</span>}
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </li>

                                        }
                                        {


                                            <li>
                                                <div className=" connect_card" >
                                                    <div className="card-body-mod">
                                                        <div className="">


                                                            <div className="">
                                                                <div className="w-100">
                                                                    <div className="registeruserinput passowrdd mt-0">

                                                                        <input type={conpass ? "password" : "text"} placeholder="Confirm New Password" id="confirmpassword" onChange={(e) => onInputchange(e)} />
                                                                        <span className="faeyee"><i className={conpass ? "fas fa-eye-slash" : "fas fa-eye"} type="button" id="eye" onClick={() => setConpass(!conpass)}></i></span>
                                                                    </div>

                                                                </div>
                                                                {checkconpass !== '' && <span className="text-danger">{checkconpass}</span>}
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </li>

                                        }


                                    </ul>
                                    <a onClick={() => ChangePassword()} className="reglogintbn">Change Password</a>

                                    <div className="alreadycacc">
                                        <h3 className="tect-center">Don't have an account ?
                                            <a className="Login_det" onClick={() => redirectfun()}>
                                                Register
                                            </a>
                                        </h3>

                                    </div>
                                    <a
                                        onClick={() => { setReslog('login'); history.push('/Register'); setforgetpopup(false) }}  //Forgetpassword();
                                        className="Login_detforgto"
                                    //  onClick={() => {setforgetpopup(true)
                                    // setLogin(false) }} 
                                    >
                                        Login
                                    </a>
                                </div></>
                        }
                    </div>

                </div>

            </div>




            {/* OTP verification Modal */}
            <Modal
                show={showOTP}
                onHide={handleCloseOTP}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="verification_mdlttl">OTP Verification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="otpinp_whole">
                        <p>Enter your OTP Code</p>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={4}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input {...props} />}
                        />
                        <div className=" mt-3 text-center">
                            <p className="pr-4"> Otp will expires in <b> {Minutes}:{Seconds}</b></p></div>
                    </div>
                    {Enablebtn &&  <button className="reglogintbn" onClick={() => {resendOTP()}}>Resend</button>}
                    {!Enablebtn &&  <button className="reglogintbn" onClick={() => {validOTP()}}>Verify</button>}

                </Modal.Body>

            </Modal>

            {/* end of OTP verification Modal */}





            {/* 2FA Modal */}

            <Modal
                show={showFA}
                onHide={handleCloseFA}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="verification_mdlttl">2FA Verification</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="otpinp_whole mt-4">
                        <p> Enter your 2FA Code</p>
                        <OtpInput
                            value={twofa}
                            onChange={setTwofa}
                            numInputs={6}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input {...props} />}
                        />
                    </div>

                    <button className="reglogintbn" onClick={()=> {tfaEnable()}}>Verify</button>

                </Modal.Body>

            </Modal>
            {/* end of 2FA Modal */}
        </>
    )
}



