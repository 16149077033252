import React,{useState} from 'react'
import LazyLoad from 'react-lazyload';
import { useHistory } from 'react-router-dom';
import LazyLoader from 'views/lazyloader';
import FooterLogo from "../../assets/images/footer_logo.png";

function PreviewCard(props) {

  const history = useHistory()

    const [starRate, setstarRate] = useState(0);
    console.log('PreviewCard',props)
    const {previewFile,previewThumb,pathname,NFTName} = props;
  return (
    <>
    <div class="card previewy_card">
    {
      previewFile?.type?.includes('pdf') ? 
      <a href={URL.createObjectURL(previewFile)} target='blank'>
    <LazyLoad
              height={200}
              placeholder={<LazyLoader />}
              offset={[-200, 0]}
              debounce={500}
            >
    {previewFile && (previewFile?.type?.includes('audio') ?
        <>
          <img src={typeof(previewThumb) == 'string' ? previewThumb : (previewThumb ? URL.createObjectURL(previewThumb) : FooterLogo) } class="card-img-top previewy_cardban" loading="lazy"></img>
          <audio
            src={URL.createObjectURL(previewFile)}
            controls
            controlsList="nodownload"
            // className="audio_align"
            alt="audio"
            playsInline
            loop
            muted
            type="audio/*"
            autostart="off"
          ></audio></>
          : (previewFile?.type?.includes('video') ?
            <>
              {/* <img src={URL.createObjectURL(previewThumb)} className="img-fluid" loading="lazy"></img> */}
              <video
                id="my-video"
                className="img-fluid"
                autoPlay
                playsInline
                loop
                muted
                preload="auto"
                alt="video"
              >
                <source
                  src={URL.createObjectURL(previewFile)}
                  type="video/mp4"
                />
              </video>
            </>
            : 
            (
              previewFile?.type?.includes('pdf') ?
            <>
              {/* <img src={URL.createObjectURL(previewThumb)} className="img-fluid" loading="lazy"></img> */}
              <iframe
              src={URL.createObjectURL(previewFile)} className="img-fluid" loading="lazy"
              ></iframe>
            </>
            :
            <img src={ typeof(previewFile) == 'string' ? previewFile : URL.createObjectURL(previewFile)} class="card-img-top previewy_cardban"/>)))}
            
            
            </LazyLoad>
            </a>
            :
    <LazyLoad
              height={200}
              placeholder={<LazyLoader />}
              offset={[-200, 0]}
              debounce={500}
            >
    {previewFile && (previewFile?.type?.includes('audio') ?
        <>
          <img src={typeof(previewThumb) == 'string' ? previewThumb : (previewThumb ? URL.createObjectURL(previewThumb) : FooterLogo) } class="card-img-top previewy_cardban" loading="lazy"></img>
          <audio
            src={URL.createObjectURL(previewFile)}
            controls
            controlsList="nodownload"
            // className="audio_align"
            alt="audio"
            playsInline
            loop
            muted
            type="audio/*"
            autostart="off"
          ></audio></>
          : (previewFile?.type?.includes('video') ?
            <>
              {/* <img src={URL.createObjectURL(previewThumb)} className="img-fluid" loading="lazy"></img> */}
              <video
                id="my-video"
                className="img-fluid"
                autoPlay
                playsInline
                loop
                muted
                preload="auto"
                alt="video"
              >
                <source
                  src={URL.createObjectURL(previewFile)}
                  type="video/mp4"
                />
              </video>
            </>
            : 
            (
              previewFile?.type?.includes('pdf') ?
            <>
            
              {/* <img src={URL.createObjectURL(previewThumb)} className="img-fluid" loading="lazy"></img> */}
              <iframe
              src={URL.createObjectURL(previewFile)} className="img-fluid" loading="lazy"
              ></iframe>
            </>
            :
            <img src={ typeof(previewFile) == 'string' ? previewFile : URL.createObjectURL(previewFile)} class="card-img-top previewy_cardban"/>)))}
            
            
            </LazyLoad>
    }
    
  {/* <img src={require('../../assets/images/bannerheade.png')} class="card-img-top" alt="bannercard"/> */}
  <div class="card-body previewy_card_body">
    <div class="previewy_card_ttl">
        <h3 className='previewy_name'>{NFTName ?? "Card Name"}</h3>

        <div className={"star-rating star" + starRate}>
              <input id="star-5" type="radio" name="rating" value="star-5" />
              <label for="star-5" title="5 stars" className="five">
                <i className="active fa fa-star" aria-hidden="true"></i>
              </label>
              <input id="star-4" type="radio" name="rating" value="star-4" />
              <label for="star-4" title="4 stars" className="four">
                <i className="active fa fa-star" aria-hidden="true"></i>
              </label>
              <input id="star-3" type="radio" name="rating" value="star-3" />
              <label for="star-3" title="3 stars" className="three">
                <i className="active fa fa-star" aria-hidden="true"></i>
              </label>
              <input id="star-2" type="radio" name="rating" value="star-2" />
              <label for="star-2" title="2 stars" className="two">
                <i className="active fa fa-star" aria-hidden="true"></i>
              </label>
              <input id="star-1" type="radio" name="rating" value="star-1" />
              <label for="star-1" title="1 star" className="one">
                <i className="active fa fa-star" aria-hidden="true"></i>
              </label>
            </div>

    </div>
    <div className='previewy_card_ttl'>
    <p className='previewy_price'>Token Price</p>
    <img className='previewy_nbn' src={require('../../assets/images/bnbdesign.png')}/>
    </div>

    <div className='previewy_bot'>
        <div className='noimg_address'>
            <img className='noimage_previewy' src={require('../../assets/images/noimage.png')}/>
            <img className='noimage_previewy' src={require('../../assets/images/noimage.png')}/>
            <p className='previewy_addre'>@Preview</p>
        </div>
        <div className="hearting">
        <i className="far fa-heart"/>
        </div>
    </div>
    
  </div>
</div>

{/* <div className='tokens'>
    <div class="baner_image">
        <div class="ethactios">

        </div>
    </div>
</div> */}
    </>
  )
}

export default PreviewCard