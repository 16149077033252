import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Select from 'react-select'
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Profile from "assets/images/no_profile2.png";
import "@metamask/legacy-web3";
import isEmpty from "../lib/isEmpty";
import config from "../lib/config";
import { toast } from "react-toastify";
import AddIcon from '@mui/icons-material/Add';
import { editprofile, generateTFA, getprofile, enableTFA } from "../actions/v1/user";
import delete_png from '../assets/images/del.png'
import { generate } from "generate-password";
toast.configure();
let toasterOption = config.toasterOption;
const dashboardRoutes = [];
const useStyles = makeStyles(styles);
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const initialFormValue = {
  name: "",
  customurl: "",
  bio: "",
  twitter: "",
  photo: "",
  email: "",
  facebook: "",
  youtube: "",
  instagram: "",
};

const mapdata = [
  { value: 'Discord', label: 'Discord', classname: "fab fa-discord" },
  { value: 'Instagram', label: 'Instagram', classname: "fab fa-instagram" },
  { value: 'Linkedin', label: 'Linkedin', classname: 'fab fa-linkedin' },
  { value: 'Whatsapp', label: 'Whatsapp', classname: "fab fa-whatsapp" },
  { value: 'Twitter', label: 'Twitter', classname: "fab fa-twitter" },
  { value: 'Slack', label: 'Slack', classname: "fab fa-slack" },
  { value: 'Telegram', label: 'Telegram', classname: "fab fa-telegram" },
  { value: 'Github', label: 'Github', classname: "fab fa-github" },
  { value: 'Facebook', label: 'Facebook', classname: "fab fa-facebook-f" },
  { value: 'Others', label: 'Others', classname: "fas fa-hashtag" },
  // Add more data items here as needed
];

export default function EditProfile(props) {
  const classes = useStyles();
  const history = useHistory();
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  useEffect(() => {
    getProfiledata();
  }, [Wallet_Details.UserAccountAddr]);

  useEffect(() => {
    TFACode()
  }, [])
  const { ...rest } = props;
  const [disablebtn, setDisablebtn] = useState(0);
  const [ImageVal, setImageVal1] = useState("");
  const [imageVal, setImageVal] = useState("");
  const [onchangeimg, setOnchangeimg] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [twittercheck, settwittercheck] = useState(false);
  const [youtubecheck, setyoutubecheck] = useState(false);
  const [facebookcheck, setfacebookcheck] = useState(false);
  const [instagramcheck, setinstagramcheck] = useState(false);
  const [socialLinks, SetSocialLinks] = useState([]);
  const [Ids, setIds] = useState("");
  const [filterRows, setFilterRows] = useState([]);

  // 2fa
  const [tfaIMage, setTfaIMage] = useState("");
  const [tfaEnable, setTfaEnable] = useState(false);
  const [tfaCode, setTfaCode] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const [user, setUser] = useState();
  const [loginEnable, setLoginEnable] = useState(true);

  const TFACode = async ()=> {
    const getData = await generateTFA();
    
    const twoFactorImage = "https://chart.googleapis.com/chart?chs=130x130&chld=L|0&cht=qr&chl=" + getData?.data?.generateTotpUri;
    setTfaIMage(twoFactorImage)
  }

  const TFAEnable = async (type) => {
    let error = {}
    let payload = {}
    if (type == 'email') {
      const enable = await enableTFA({ email: user?.Regemail, type: "email" });
      getProfiledata();
      setTfaEnable(false)
    }
    if (type == '2fa') {
      if (tfaCode == "" || tfaCode == null || tfaCode == undefined) {
        error.tfaCode = "Google 2FA code is required"
      }
      if (password == "" || password == null || password == undefined) {
        error.password = "password is required"
      }
      if (tfaCode != "" && password != "") {
        payload.email = user?.Regemail
        payload.tfaCode = tfaCode
        payload.type = 'google'
        payload.password = password;
        const enable = await enableTFA(payload);
        if(enable.data?.status){
          toast.success(enable.data?.message)
          getProfiledata();
          setLoginEnable(false)
        }
        else{
          toast.error(enable.data?.message)
          setError(enable?.data?.error)
          // console.log('ljsfjkskjflsf',enable?.data)
        }
        
      }
      else{
        setError(error)
      }
      
    }
  }

  const numberValidation = async (e) => {
    let regex = /^[0-9]+$/;
    if (e == '' || regex.test(e)) {
      setTfaCode(e);
    }
  }

  const {
    name,
    customurl,
    bio,
    twitter,
    photo,
    instagram,
    email,
    facebook,
    youtube,
  } = formValue;
  const mappedData = mapdata.map(item => ({
    value: item.value,
    label: item.label,
    classname: item.classname,
  }));

  async function getProfiledata() {
    setDisablebtn(0);

    const currAddr = Wallet_Details.UserAccountAddr;
    let reqData = {
      currAddr,
    };
    // ////////console.log("reqData",reqData)
    var data = await getprofile(reqData);
    if(data?.userValue?.LoginType == 'google'){
      setLoginEnable(false)
      setTfaEnable(true)
    }
    if(data?.userValue?.LoginType == 'email'){
      setTfaEnable(false)
      setLoginEnable(true)
    }
    setUser(data.userValue)
    if (data && data.userValue !== undefined) {
      let formdata = {};
      if (data?.userValue?.image !== "") {
        var profileimage =
          config.Back_URL +
          "/images/" +
          data?.userValue?._id +
          "/" +
          data?.userValue?.image;
        // setImageVal(data.userValue.image)
        setOnchangeimg(profileimage);
        setIds(data?.userValue?._id);
      } else {
        // ////////console.log('else profile');
      }
      if (data?.userValue) {
        formdata["photo"] = data?.userValue?.image;
        formdata["bio"] = data.userValue?.bio;
        formdata["curraddress"] = data.userValue?.curraddress;
        formdata["customurl"] = data.userValue?.customurl;
        formdata["twitter"] = data.userValue?.twitter;
        formdata["name"] = data.userValue?.name;
        formdata["email"] = data.userValue?.email;
        formdata["youtube"] = data.userValue?.youtube;
        formdata["instagram"] = data.userValue?.instagram;
        formdata["facebook"] = data.userValue?.facebook;
        setFormValue(formdata);
        setinstagramcheck(data.userValue?.instagramcheck);
        setfacebookcheck(data.userValue?.facebookcheck);
        settwittercheck(data.userValue?.twittercheck);
        setyoutubecheck(data.userValue?.youtubecheck);
        SetSocialLinks(data?.userValue?.SocialLinks?.length > 0 ? data?.userValue?.SocialLinks : []);
        let defarr = [];
        if (data?.userValue?.SocialLinks?.length > 0) {
          data.userValue.SocialLinks.map((val) => {
            defarr.push({ filters: "" })
          })
        }
        setFilterRows(defarr)
        ////console.log("woqpowpoqwpqw",data.userValue)
      }
    } else {
      // ////////console.log('else part @getProfiledata');
    }
  }

  const handleFile = (event) => {
    setDisablebtn(0);
    event.preventDefault();
    var reader = new FileReader();
    const { id, files } = event.target;
    setDisablebtn(0);
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      setImageVal(file);
      var url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        if (reader.result) {
          setOnchangeimg(reader.result);
          setImageVal1(file.name);
        }
      };
    }
    let formData = { ...formValue, ...{ [id]: files[0] } };
    setFormValue(formData);
  };

  const onChange = (e) => {
    settwittercheck(false)
    setfacebookcheck(false)
    setyoutubecheck(false)
    setinstagramcheck(false)
    const { id, value } = e.target;
    setDisablebtn(0);
    e.preventDefault();
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);

  };

  const twittercheckonchange = async () => {
    if (twittercheck === true) {
      settwittercheck(false);
      // alert(1)
    } else {
      var check = twitter.split("/")
      //console.log("sdfghndgh",twitter,check&&check.length>0&&check[2]==="twitter.com" &&String(twitter).includes("http://"));

      if (check && check.length > 0 && check[2] === "twitter.com" && String(twitter).includes("http://")) {
        settwittercheck(true);
      }
      else {
        toast.warning("please enter twitter account")
      }
      // alert(2)
    }
  };

  const youtubecheckonchange = async () => {
    if (youtubecheck === true) {
      setyoutubecheck(false);
    } else {
      var check = youtube.split("/")
      if (check && check.length > 0 && check[2] === "youtube.com" && String(youtube).includes("http://")) {
        setyoutubecheck(true);
      }
      else {
        toast.warning("please enter youtube account")
      }
    }
  };

  const facebookcheckonchange = async () => {
    if (facebookcheck === true) {
      setfacebookcheck(false);
    } else {
      var check = facebook.split("/")
      if (check && check.length > 0 && check[2] === "facebook.com" && String(facebook).includes("http://")) {
        setfacebookcheck(true);
      }
      else {
        toast.warning("please enter facebook account")
      }
    }
  };

  const instagramcheckonchange = async () => {
    if (instagramcheck === true) {
      setinstagramcheck(false);
    } else {
      var check = instagram.split("/")
      if (check && check.length > 0 && check[2] === "instagram.com" && String(instagram).includes("http://")) {
        setinstagramcheck(true);
      }
      else {
        toast.warning("please enter instagram account")
      }
    }
  };

  const handleFormSubmit = async (e) => {
    // e.preventDefault();
    //console.log("ewriuiewruewr", Wallet_Details.UserAccountAddr);
    const currAddr = Wallet_Details.UserAccountAddr;
    let reqData = {
      imageVal,
      name,
      customurl,
      bio,
      twitter,
      photo,
      email,
      youtube,
      instagram,
      facebook,
      currAddr,
      facebookcheck,
      instagramcheck,
      youtubecheck,
      twittercheck,
      socialLinks,
    };
    const custUrl = String(customurl).replace(/\s+/g, "").trim().toLowerCase();

    let reqData1 = {
      imageVal,
      name,
      customurl: custUrl,
      bio,
      twitter,
      photo,
      email,
      youtube,
      instagram,
      facebook,
      currAddr,
      facebookcheck,
      instagramcheck,
      youtubecheck,
      twittercheck,
      SocialLinks:JSON.stringify(socialLinks),
    };
    //console.log("weiqoieqwoeqw", reqData);
    //editprofileUI(reqData)
    var errorUI = await editprofileUI(reqData);
    //console.log("kjnhvgyvftc", errorUI);
    if (isEmpty(errorUI)) {
      //console.log("inside edit", reqData1);
      var errors = await editprofile(reqData1);
      if (isEmpty(errors.error) || !isEmpty(errors.userValue)) {
        setDisablebtn(3);
        setDisablebtn(0);
        setValidateError("");
        toast.success("Profile has been updated", toasterOption);
        setTimeout(() => history.push("/my-items"), 3000);
        setValidateError({});
      } else {
        //console.log("errre12", errors);
        setDisablebtn(1);
        setValidateError(errors);
      }
    } else {
      //console.log("errre13", errorUI);
      setDisablebtn(1);
      setValidateError(errorUI);
    }
  };

  const editprofileUI = async (data) => {
    var validateError = {};
    let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;
    let imageSize = 5000000;
    let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG|webp|WEBP|gif|GIF)$/;
    let urlRegex = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/;

    ////console.log("ioioioiooioioio",data)

    if (onchangeimg !== "") {
      ////console.log("epwopeopwope",data.imageVal,onchangeimg,onchangeimg.split('.').pop())
      if (data.imageVal !== "") {
        if (imageSize < data.imageVal.size) {
          validateError.image = "File size must be below 5mb";
        }
        if (
          !/\.(jpg|JPG|jpeg|JPEG|png|PNG|webp|WEBP|gif|GIF)$/i.test(
            data.imageVal.name
          )
        ) {
          validateError.image =
            "file is invalid. only allowed JPG,PNG,WEBP,gif";
        }
      }
    }
    if (data.name === "") {
      validateError.names = "Name is Required";
    }
    if (data.name !== "") {
      if (config.nameFormat.test(data.name)) {
        validateError.names = "Cannot allowed smiley";
      }
    }
    // if ((data.customurl) == "") {
    //   validateError.customurl = "customurl is Required"
    // }
    if (data.customurl !== "") {
      if (config.nameFormat.test(data.customurl)) {
        validateError.customurl = "Cannot allowed smiley";
      }
    }
    if (data.customurl !== "") {
      if (urlRegex.test(data.customurl)) {
        validateError.customurl = "invalid custom url";
      }
    }
    if (data.email === "") {
      validateError.email = "email is Required";
    }
    if (data.email !== "") {
      if (!emailRegex.test(data.email)) {
        validateError.email = "email Format is Required";
      }
    }

    if (data.youtubecheck === true) {
      if (data.youtube === "") {
        validateError.youtube = "Youtube link is Required";
      }
    }
    if (data.instagramcheck === true) {
      if (data.instagram === "") {
        validateError.instagram = "Instagram link is Required";
      }
    }
    if (data.facebookcheck === true) {
      if (data.facebook === "") {
        validateError.facebook = "Facebook link is Required";
      }
    }
    if (data.twittercheck === true) {
      if (data.twitter === "") {
        validateError.twitter = "Twitter link is Required";
      }
    }
    if (socialLinks?.length > 0) {
      socialLinks.map((val, ind) => {
        if (isEmpty(val.url)) {
          validateError['url' + ind] = "Url cannot be Empty";
        }
        if (isEmpty(val.urlfor)) {
          validateError['urlfor' + ind] = "Url cannot be Empty";
        }
      })
    }
    //console.log("validate error", validateError);
    setValidateError(validateError);
    return validateError;
  };
  const addFilters = () => {
    if (filterRows.length < 20) {
      setFilterRows([...filterRows, { filters: "" }])
      SetSocialLinks([...socialLinks, { url: "" }])
    }
    else {
      toast.error('Already added 20 links', 1000)
    }
  };
  const removeFilters = (index) => {
    const list = [...filterRows]
    let currlink = socialLinks;
    list.splice(index, 1)
    currlink.splice(index, 1)
    setFilterRows(list)
    SetSocialLinks([...currlink])
  };

  const OnChange = (e, index) => {
    let currlinks = socialLinks;
    if (e?.target?.id) {
      setDisablebtn(0);
      const { id, value } = e.target;

      filterRows.map((val, ind) => {
        if (ind == index) {
          currlinks[ind][id] = value
        }

      })
    }
    else if (e?.value) {
      setDisablebtn(0);
      const { classname, label, value } = e;

      filterRows.map((val, ind) => {
        if (ind == index) {
          currlinks[ind].urlfor = value;
          currlinks[ind].classname = classname;
        }

      })
    }
    SetSocialLinks([...currlinks])
  }

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <>
            <div className="light_logo">
              <a href="https://edaface.com/" target="_blank">
                <img
                  src={require("../assets/images/logo.png")}
                  alt="logo"
                  className="img-fluid"
                />
              </a>
            </div>
            <div className="dark_logo">
              <Link to="/">
                <img
                  src={require("../assets/images/dark-theme-logo.png")}
                  alt="logo"
                  className="img-fluid"
                />
              </Link>
            </div>
          </>
        }
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <h3 className="section-head mb-0">Edit Profile</h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container mt-5">
          <GridContainer>
            <GridItem xs={12} sm={3} md={3}>
              <div className="holder">
                <div className="profile_edit_panel">
                  {onchangeimg === "" && (
                    <img
                      src={Profile}
                      alt="logo"
                      id="imgPreview"
                      className="img-fluid"
                    />
                  )}
                  {onchangeimg !== "" && (
                    <img
                      src={onchangeimg ? onchangeimg : null}
                      alt={onchangeimg ? onchangeimg.name : null}
                      id="imgPreview"
                      className="img-fluid"
                    />
                  )}

                  <div className="profile_edit_icon">
                    <i className="fas fa-pencil-alt"></i>
                  </div>
                  <input
                    type="file"
                    name="photo"
                    id="photo"
                    required="true"
                    className="photo"
                    onChange={(e) => handleFile(e)}
                  />
                  {validateError.image && (
                    <span className="text-danger">{validateError.image}</span>
                  )}
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} sm={9} md={9}>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">
                      Display Name
                    </label>
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="name"
                      placeholder="Enter your display name"
                      onChange={onChange}
                      value={name}
                    />

                    {validateError.names && (
                      <span className="text-danger">{validateError.names}</span>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">
                      Custom Name
                    </label>
                    <div className="input-group input_grp_style_1">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text pl-0 min_h_45_px min_h_35"
                          id="basic-addon2"
                        >
                          {config.Front_URL}/
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control primary_inp"
                        placeholder="Enter unique name only... EX.Nila,Ronaldo..."
                        id="customurl"
                        onChange={onChange}
                        value={customurl}
                        defaultValue=""
                      />
                    </div>

                    {validateError.customurl && (
                      <span className="text-danger">
                        {validateError.customurl}
                      </span>
                    )}
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="email"
                      name="email"
                      value={email}
                      onChange={onChange}
                      placeholder="Your email for marketplace notifications"
                    />
                    {validateError.email && (
                      <span className="text-danger">{validateError.email}</span>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">
                      Bio
                    </label>
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="bio"
                      onChange={onChange}
                      value={bio}
                      placeholder="Tell about yourself in a few words"
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">
                      Social Media Links
                    </label>

                  </div>

                </div>
                <div className="social_add_sec">
                  <p className="text_title">Interactive media links, e.g. Facebook, Telegram, Twitter, Instagram, etc.</p>

                  <div className="googled_authenti">
                    <div className="d-flex justify-content-start align-items-center my-3 me-sm-5">
                      <div>
                      <span className="primary_label me-3">Google authentication</span>
                      <label class="switch toggle_model toggle_model_editPr">
                        <input type="checkbox" onChange={() => { setTfaEnable(!tfaEnable); setLoginEnable(false) }} checked={tfaEnable}/>
                        <span class="slider round"></span>
                      </label>
                    </div>
                    </div>
                    <div>
                    <div className="d-flex justify-content-start align-items-center my-3">
                      <span className="primary_label me-3">Login with OTP</span>
                      <label class="switch toggle_model toggle_model_editPr">
                        <input type="checkbox" onChange={() => { setTfaEnable(false) ; TFAEnable('email') }} checked={loginEnable}/>
                        <span class="slider round"></span>
                      </label>
                    </div>
                    </div>
                  </div>

                  <div className="add_social">
                    <div className="btn_sec">
                      <Button type="button" className="btn btn-add-social" onClick={addFilters}> <AddIcon /></Button><span className="add_txt">Click to Add</span>
                    </div>

                  </div>
                  {filterRows.map((val, index) => (
                    <div className="url_card my-3" key={index}>
                      <label className="primary_label" htmlFor="name">
                        URL For
                      </label>
                      <div className="row">
                        <div className="col-lg-3 mb-4 mb-lg-0">
                          <Select
                            id="urfor"
                            options={mappedData}
                            classNamePrefix="react-select"
                            className="yes1 typecontrol3 form-control primary_inp typecontrol"
                            onChange={(e) => OnChange(e, index)}
                            value={
                              socialLinks[index]?.urlfor ?
                                {
                                  label: socialLinks[index].urlfor,
                                  value: socialLinks[index].urlfor,
                                }
                                : ''
                            }
                          />
                          {validateError['urlfor' + index] && (
                            <span className="text-danger">{validateError['urlfor' + index]}</span>
                          )}
                        </div>
                        <div className="col-lg-9 mb-4 mb-lg-0">
                          <div className="row">
                            <div className="col-10">
                              <form>
                                <div class="form-group">
                                  <input type="text" class="form-control" id="url" aria-describedby="emailHelp" placeholder="Enter url" onChange={(e) => OnChange(e, index)} value={socialLinks[index]?.url || ''} />
                                  {validateError['url' + index] && (
                                    <span className="text-danger">{validateError['url' + index]}</span>
                                  )}
                                </div>
                              </form>
                            </div>
                            <div className="col-2"><img src={delete_png} onClick={() => removeFilters(index)} alt="delete" className="img-fluid delete_png" width={30} /></div>
                          </div>

                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-3">
                  {disablebtn === 0 && (
                    <Button className="create_btn" onClick={handleFormSubmit}>
                      Update Profile
                    </Button>
                  )}
                  {disablebtn === 1 && (
                    <Button className="create_btn" disabled="true">
                      Form Error
                    </Button>
                  )}
                  {disablebtn === 2 && (
                    <Button className="create_btn" onClick={handleFormSubmit}>
                      Try Again
                    </Button>
                  )}
                  {disablebtn === 2 && (
                    <Button className="create_btn" disabled={true}>
                      Try Again
                    </Button>
                  )}
                </div>
              </form>


            </GridItem>



          </GridContainer>
          {
            tfaEnable && user && user.Google == "disable" &&
            <GridContainer >
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}></GridItem>
            <GridItem xs={12} sm={12} md={9} lg={9} xl={9}>
              <GridContainer className="editprfl_dashed_whole">
                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12} >
                  <GridContainer className="align-items-center">
                  <GridItem xs={12} sm={12} md={8} lg={8} xl={8} >
                  <div className="editprfl_dashedimg">
                  <label className="primary_label lineheight" htmlFor="name">
                        Scan This Qr Code in Authenticator Extension for Desktop or Authenticator Apps (such as Google Authenticator,Twilio Authenticator,etc...) for Mobile or Tablets
                          </label>
                                             
                  </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4} >
                  <div className="editprfl_dashedimg">
                  
                          <div className="text-left"> <img src={tfaIMage} /></div>
                   
                  </div>
                    </GridItem>
                    </GridContainer>
                  </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12} >
                  <div className="edit_prlf_dblinputs">
                    <div className="form-group w-100">
                      <label className="primary_label" htmlFor="name">
                        Enter 6 digit 2FA code
                          </label>
                          <input
                            type="text"
                            className="form-control primary_inp "
                            placeholder="Enter your code"
                            value={tfaCode}
                            onChange={(e) => { numberValidation(e.target.value) }}
                            maxLength='6' />
                              <span className="text-danger">{error?.tfaCode}</span>
                        </div>
                        <div className="form-group  w-100">
                      <label className="primary_label" htmlFor="name">
                        Enter Password
                      </label>

                      <input
                        type="text"
                        className="form-control primary_inp mt-3"
                        placeholder="Enter your Password"
                        onChange={(e)=> {setPassword(e.target.value)}}
                      />
                      <span className="text-danger">{error?.password}</span>
                    </div>
                    <Button className="create_btn mt-5" onClick={()=>{TFAEnable('2fa')}}>
                      Enable 2FA
                    </Button>
                  </div>


                </GridItem>
              </GridContainer>

            </GridItem>

          </GridContainer>
          }
        
        </div>
      </div>
      <Footer />
    </div>
  );
}
