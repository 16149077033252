import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
// import Modal from '@mui/material/Modal';
// import Fade from '@mui/material/Fade';
// import Button from '@mui/material/Button';
// import Grid from '@mui/material/Grid';
// import CloseIcon from '@mui/icons-material/Close';
// import Typography from '@mui/material/Typography';
// import Backdrop from '@mui/material/Backdrop';
// import Box from '@mui/material/Box';
import Icon2 from "assets/images/wallet_03.png";
import Icon1 from "assets/images/icon1.jpg";
import { connectWallet, WalletConnect } from '../hooks/useWallet';
import { Account_Connect, Account_disConnect } from "actions/redux/action";
//redux
import { useDispatch, useSelector } from 'react-redux';
import { AddressUserDetails_GetOrSave_Action } from "../../actions/v1/user";
import Config from "../../lib/config";
// import { Account_Section } from '../../redux/reducers'
//import {Account_Section} from '../../redux/constants'
//import useAxiosFile from '../../actions/useaxios';
import { toast } from 'react-toastify';
import Icon4 from "../..//assets/images/wallet_05.png";
export const Connect = forwardRef((props, ref) => {


    const dispatch = useDispatch();
    // const {payload , isAdmin , token} = useSelector(state => state.LoginReducer.User);
    //const AxiosFile = useAxiosFile()

    useImperativeHandle(
        ref,
        () => ({
            async walletConnectfunction() {
                // setOpen3(true)
                //    $('#connect_modal').modal('show');
                window.$('#connect_modal').modal('show');
            }
        })
    )

    const initialConnectWallet = async (type) => {
        window.$('#connect_modal').modal('hide');
        //console.log("sdfbnhdahnasfgtjhnSFR", type, Config.Chainid)
        var accountDetails = await connectWallet(type, Config.Chainid)
        //console.log("accountDetails......................", accountDetails)
        //  const id=toast.loading("Wallet Connecting...")

        if (accountDetails != '') {
            //console.log("Wallet Connecting...", accountDetails?.web3?._provider);
            dispatch({
                type: Account_Connect,
                Account_Detail: {
                    UserAccountAddr: accountDetails?.accountAddress,
                    UserAccountBal: accountDetails?.coinBalance,
                    WalletConnected: "true",
                    Wen_Bln: accountDetails?.tokenBalance,
                    Accounts: accountDetails?.accountAddress,
                    providerss: accountDetails?.web3?._provider
                }
            })
        }


        // async function AddressUserDetails_GetOrSave_Call(currAddr) {
        var ReqData = {
            addr: String(accountDetails?.accountAddress).toLowerCase()
        }
        console.log('ljdfklsjfklsfsf11111',ReqData)
        var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
        console.log('ljdfklsjfklsfsf',Resp)
        if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
            return Resp.data.data.User
        } else {
            return null
        }
        // }





        //     if(accountDetails){
        //           var resp = await isRegister('WalletConnect',accountDetails.accountAddress)
        //           //console.log("resp",resp)
        //      toast.update(id,{render : resp.msg , type:resp.success,autoClose:1000, isLoading: false,})
        //          if(resp?.success == 'success'){
        //             //console.log("all ok come pls")
        //                   dispatch({
        //                       type: Account_Section,
        //                       Account_Section: {AccountDetails:accountDetails}
        //                   })
        //             setOpen3(false)
        //   }   
        // }
        // else



        // toast.update(id,{render : "Connect Wallet" , type:'error',autoClose:1000, isLoading: false,})
    }












    return (






        <div className="modal fade primary_modal" id="connect_modal" role="dialog" aria-labelledby="connect_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false" >
            <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h5 className="modal-title" id="connect_modalLabel">Connect Your Wallet</h5>
                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <ul className="connect_ul">
                            {


                                <li>
                                    <div className=" connect_card" >
                                        <div className="card-body-mod">
                                            <div className="media follow_media">


                                                <div className="media-body flex_body">
                                                    <div className="w-100">
                                                        <div className="wallet-lists"
                                                            onClick={() => initialConnectWallet("MetaMask")} >
                                                            <p className="my-0 media_text"> <img src={Icon1} />Metamask</p>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </li>


                            }



                            {


                                <li>
                                    <div className=" connect_card" >
                                        <div className="card-body-mod">
                                            <div className="media follow_media">


                                                <div className="media-body flex_body">
                                                    <div className="w-100">
                                                        <div className="wallet-lists"
                                                            onClick={() => initialConnectWallet("WalletConnect")}
                                                        >
                                                            <p className="my-0 media_text"> <img src={Icon2} />Wallet Connect</p>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </li>

                            }

{


<li>
    <div className=" connect_card" >
        <div className="card-body-mod">
            <div className="media follow_media">


                <div className="media-body flex_body">
                    <div className="w-100">
                        <div className="wallet-lists"
                            onClick={() => initialConnectWallet("WalletConnect")}
                        >
                            <p className="my-0 media_text"> <img src={Icon4} />Trust wallet</p>

                        </div>

                    </div>

                </div>

            </div>

        </div>
    </div>
</li>

}

                        </ul>
                    </div>
                </div>
            </div>
        </div>



    )
})