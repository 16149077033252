import axios from "axios";
import config from '../../lib/config';
import Web3 from 'web3';

import { toast } from 'react-toastify';
import { isEmpty, DecryptData } from "actions/commonfunction";
import { EncryptData } from "actions/commonfunction";
// import { async } from "@firebase/util";
toast.configure();
let toasterOption = config.toasterOption;


const axiosFunc    =   async   (data)  =>  {
        
  try{
    let Resp    =   {};
    // if(false){
      if(data?.params){
        data.params = { data: EncryptData(data.params)  }
      }
      else if(data?.data){
        if(!(data?.data instanceof FormData)){
          data.data = { data: EncryptData(data.data)  }
        }
      }
    // }
      Resp    =   await axios(data)
      if(data?.url?.includes('/getUserCollection')){
        console.log('mdshfshfsfsfafa11111',Resp,DecryptData(Resp.data),DecryptData(Resp.data) == '',DecryptData(Resp.data) == '' ? Resp.data : DecryptData(Resp.data))
      }
      if(Resp?.data){
        Resp.data = ( typeof(DecryptData(Resp.data)) == 'string' && DecryptData(Resp.data) == '') ? Resp.data : DecryptData(Resp.data)
      }
      if(data?.url?.includes('/getUserCollection')){
        console.log('mdshfshfsfsfafa',Resp,DecryptData(Resp.data))
      }

      return Resp
  }
  catch (e) {
      
      return { success: 'error', msg: null }
  }
}

const AppenData = (data) => {
  var formdata = new FormData()
  var SendDta = Object.entries(data).map((item) => {
      if (Array.isArray(item[1])) {
          var come = item[1].map(data => {
              if (data?.type && data?.size) {
                  //file
                  formdata.append(item[0], data)
              }
              else {
                  formdata.append(item[0], EncryptData(data))
              }
              return formdata
          })
          return come

      }
      else {
          if (item[1]?.type && item[1]?.size) {
              //file type
              formdata.append(item[0], item[1])
          }
          else {
              formdata.append(item[0], EncryptData(item[1]))

          }
          return formdata
      }
  })
  console.log('dkshfsfsfkjshf',SendDta,formdata)
  return [formdata]
}

export const TestApi = async(data) => {
  // var encdata = EncryptData(JSON.stringify(data))
  var senddata    =   {
      method  :   'post',
      url     :   `${config.vUrl}/user/test`,
      data  :   data,
      }
  let Resp    =   await axiosFunc(senddata)
  return Resp.data
}


export const getCurAddr = async () => {
  var currAddr = '';
  if (window.ethereum) {
    var web3 = new Web3(window.ethereum);
    if(
      web3&&
      window.web3
      && window.web3.eth
      && window.web3.eth.defaultAccount
    ) {
      // var accVal = await web3.eth.getAccounts();
      // if(accVal[0]) {
      //   currAddr = accVal[0];
      // }
      currAddr = window.web3.eth.defaultAccount;
      currAddr = currAddr.toString();
    }
  }
  return currAddr;
}

export const ParamAccountAddr_Detail_Get = async (Payload) => {
  ////////console....log('Payload',Payload);
  try {

    var senddata = {
      'method': 'get',
      'url': `${config.vUrl}/user/address/details/getAddress`,
      'params': Payload
    };
    var Resp = await axiosFunc(senddata);
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const getfaq = async (payload) => {
  try {
    var senddata = {
      'method': 'get',
      'url': `${config.vUrl}/user/getfaq`,
    };
    var Resp = await axiosFunc(senddata);
    ////////console....log("faq_list:",resp.data.data)
    return {
      result: Resp.data.data
    }
  }
  catch (err) {
  }
}
export const FollowChange_Action = async (Payload) => {
  ////////console....log('Payload',Payload);
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.vUrl}/user/follow/change`,
      'data': Payload
    };
    var Resp = await axiosFunc(senddata);
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const PutOnSale_Action = async (Payload) => {
}

export const ToastShow = async (data) => {
  ////////console....log('datadatadatadata',data)
  if (data.toast && data.toast.type && data.toast.msg) {
    if(data.toast.type === 'success') {
      toast.success(data.toast.msg, toasterOption)
    } else {
      toast.error(data.toast.msg, toasterOption)
    }
  }
}

export const UserProfile_Update_Action = async (Payload) => {
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.vUrl}/user/profile/update`,
      'data': Payload
    };
    var Resp = await axiosFunc(senddata);
    ToastShow(Resp.data);
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const User_FollowList_Get_Action = async (Payload) => {
  ////////console....log('Payload',Payload);
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.vUrl}/user/follow/list/`,
      'data': Payload
    };
    var Resp = await axiosFunc(senddata);
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const Collectibles_Get_Action = async (Payload) => {
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.vUrl}/user/collectibles`,
      'data': Payload
    };
    var Resp = await axiosFunc(senddata);
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const changeReceiptStatus_Action = async (Payload) => {
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.vUrl}/user/changereceiptstatus`,
      'data': Payload
    };
    var Resp = await axiosFunc(senddata);
    return {
      data: Resp
    }
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const AddressUserDetails_GetOrSave_Action = async (Payload) => {
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.vUrl}/user/address/details/getorsave`,
      'data': Payload
    };
    var Resp = await axiosFunc(senddata);
    return {
      data: Resp
    }
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}


export const editprofile = async (data) => {
  try {
    var formdata = AppenData(data);
      // const bodyFormData = new FormData();
      // bodyFormData.append("name", data.name);
      // bodyFormData.append("customurl", data.customurl);
      // bodyFormData.append("bio", data.bio);
      // bodyFormData.append("twitter", data.twitter);
      // bodyFormData.append("photo", data.photo);
      // bodyFormData.append("currAddr", data.currAddr);
      //  bodyFormData.append("youtube", data.youtube);
      // bodyFormData.append("instagram", data.instagram);
      // bodyFormData.append("email", data.email);
      
      // bodyFormData.append("facebook", data.facebook);

      // bodyFormData.append("facebookcheck", data.facebookcheck);
      // bodyFormData.append("instagramcheck", data.instagramcheck);
      // bodyFormData.append("youtubecheck", data.youtubecheck);
      // bodyFormData.append("twittercheck", data.twittercheck);
      // bodyFormData.append("SocialLinks", JSON.stringify(data.socialLinks));

      var senddata = {
        'method': 'post',
        'url': `${config.vUrl}/user/editprofile/`,
        'headers': {
            'Authorization': localStorage.user_token
        },
        data: formdata[0]
      };
      var respData = await axiosFunc(senddata);
      //////console.log("eweuiwueiuwueiw",respData.data)
      return {
          loading: false,
          userValue: respData.data
      }

  }
  catch (err) {
      return {
          loading: false,
          error: returnErr(err)
      }
  }
}
function returnErr(err) {
  if(err.response && err.response.data && err.response.data.errors) {
      return err.response.data.errors;
  }
  else {
      return '';
  }
}

export const getprofile = async (data, dispatch) => {
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.vUrl}/user/getprofile`,
      'headers': {
          'Authorization': localStorage.user_token
      },
      data
    };
    var respData = await axiosFunc(senddata);

      return {
          loading: false,
          userValue: respData.data.userValue
      }
  }
  catch (err) {
      return {
          loading: false,
          error: returnErr(err)
      }
  }
}

export const getSearchList = async(data) => {
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.vUrl}/token/getSearchList`,
      'headers': {
          'Authorization': localStorage.user_token
      },
      data
    };
    var respData = await axiosFunc(senddata);
    return {
        loading: false,
        searchlist: respData.data
    }
}
catch (err) {
    return {
        loading: false,
        error: returnErr(err)
    }
}
}
export async function coverimagevalidations(data) {
  ////////console....log("profileAsSasaSadd" + JSON.stringify(data))
  var formdata = AppenData(data);
  // var formData = new FormData();
  // // ////////console....log('image',data.file)
  // formData.append('currAddr', data.currAddr);
  // formData.append('image',data.file)
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.vUrl}/user/test/coverimagevalidation`,
      'data': formdata[0]
    };
    var respData = await axiosFunc(senddata);

    ////////console....log("SADASDasdasd" + JSON.stringify(respData))
    return {
      loading: false,
      error: respData.data
    }

  } catch (err) {
          return {
                  loading: false,
                  error: err.response.data
          }
  }
}

export const coverImage = async (data) => {
  ////////console....log("coverImage", data)
  try {
    var formdata = AppenData(data);
      // const bodyFormData = new FormData();
      // bodyFormData.append("coverimage", data.file);
      // bodyFormData.append("accounts", data.currAddr);

      var senddata = {
        'method': 'post',
        'url': `${config.vUrl}/user/test/coverImage/`,
        'headers': {
            'Authorization': localStorage.user_token
        },
        data: formdata[0]
      };
      var respData = await axiosFunc(senddata);

      return {
          loading: false,
          userValue: respData.data
      }

  }
  catch (err) {

      return {
          loading: false,
          error: err.response.data.errors
      }
  }
}

export const pics1 = async (data) => {
  ////////console....log("coverImage", data)
  try {

    var senddata = {
      'method': 'post',
      'url': `${config.vUrl}/user/test/pics1/`,
      'headers': {
        'Authorization': localStorage.user_token
      },
      data: data
    };
    var respData = await axiosFunc(senddata);
    return {
      // loading: false,
      userValue: respData.data
    }

  }
  catch (err) {

      return {
          loading: false,
          error: err.response.data.errors
      }
  }
}
export const checkFollower = async (data) => {
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.vUrl}/user/checkFollower`,
      'headers': {
        'Authorization': localStorage.user_token
      },
      data
    };
    var respData = await axiosFunc(senddata);
    return {
      loading: false,
      follower: respData.data
    }
  }
  catch (err) {
    return {
      loading: false,
      error: returnErr(err)
    }
  }
  }
export const getFollowers = async (data) => {
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.vUrl}/user/getFollowers`,
      'headers': {
        'Authorization': localStorage.user_token
      },
      data
    };
    var respData = await axiosFunc(senddata);
    return {
      loading: false,
      follower: respData.data
    }
  }
  catch (err) {
    return {
      loading: false,
      error: returnErr(err)
    }
  }
  }
  export const followUnfollow = async (data) => {
    try {
      var senddata = {
        'method': 'post',
        'url': `${config.vUrl}/user/followUnfollow`,
        'headers': {
            'Authorization': localStorage.user_token
        },
        data
      };
      var respData = await axiosFunc(senddata);
      return {
          loading: false,
          follower: respData.data
      }
  }
  catch (err) {
      return {
          loading: false,
          error: returnErr(err)
      }
  }
  }
export const getMyActivity = async (addr) => {
  ////////console....log("getMyActivity checking"+addr)
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.vUrl}/user/activity/header`,
      data: addr
    };
    var resp = await axiosFunc(senddata);
    ////////console....log("faq_list:",JSON.stringify(resp))
    return {
      result: resp.data.list
    }
  }
  catch (err) {
  }
}

export const burnToken = async (addr) => {
  ////////console....log("getMyActivity checking"+addr)
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.vUrl}/token/burns/burnToken`,
      data: addr
    };
    var resp = await axiosFunc(senddata);
    ////////console....log("burnToken:",JSON.stringify(resp))
    return {
      result: resp.data
    }
  }
  catch (err) {
  }
}


export const toFixedNumber = (x,l,m)=> {
  
  if (Math.abs(x) < 1.0) {
      let e = parseInt(x.toString().split('e-')[1]);
      if (e) {
          x *= Math.pow(10, e - 1);
          x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
      }
  } else {
      var e = parseInt(x.toString().split('+')[1]);
      if (e > 20) {
          e -= 20;
          x /= Math.pow(10, e);
          x += (new Array(e + 1)).join('0');
      }
  }
    var j;
    var q = String(x)
    if(Number(q) < 0.0001)
    {
    for (var i = 0; i <= q.length; i++){
      if((q.charAt(i) !== '.') && (q.charAt(i) !== '0'))
      {
        j = q.slice(0,i+3)
        i = q.length
      }
    }
  }
  else{
    j = q
  }
  return j;
}

export const CollectionValidationAction = async (payload) => {
  // //console.log('payloaddthbgth', payload);
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.vUrl}/user/Collectibleval`,
      data: payload
    };
    var respData = await axiosFunc(senddata);
    console.log("dfgbhgfdbhgrherror",respData.data);
    return {
      data: respData.data
    }
  }
  catch (err) {
  }
}

// export const addUserCollection = async (payload) => {
//   //console.log("gmjiofngijfdn",payload);
//   try{
//       const bodyFormData = new FormData();
//       bodyFormData.append("collectionAddress", payload.collectionAddress);
//       bodyFormData.append("collectionType", payload.collectionType);
//       bodyFormData.append("collectionName", payload.collectionName);
//       bodyFormData.append("customurl", payload.customurl);
//       bodyFormData.append("userAddress", payload.userAddress);
//       bodyFormData.append("Image", payload.imageVal);
//       bodyFormData.append("coverImage", payload.coverval);
//     let respData = await axios({
//       'method': 'post',
//       'url': `${config.v1Url}/user/AddUserCollection`,
//       'data' : bodyFormData,
//   });
//   return {
//       message: respData
//   }
// }
// catch (err) {
//   return { error: err }
// }
// }


export const addUserCollection = async (payload) => {
  // //console.log("gmjiofngijfdn",payload.from);
  try{
      

var formdata = AppenData(payload);
    // const bodyFormData = new FormData();
    //   if (payload.from != "FindOwner" && payload.filepf != undefined || payload.filecov != undefined)////edit collection
    //   {
    //     bodyFormData.append("Image", payload.filepf);
    //     bodyFormData.append("coverImage", payload.filecov);
    //     bodyFormData.append("collectionName", payload.collectionName);
    //     bodyFormData.append("collectionAddress", payload.collectionAddress);
    //     bodyFormData.append("userAddress", payload.userAddress);
    //     bodyFormData.append("CategoryId", payload.CategoryId);
    //     bodyFormData.append("Category", payload.Category);
    //     bodyFormData.append("SubCategory", payload.SubCategory);
    //     bodyFormData.append("SocialLinks", payload.SocialLinks);
    //     bodyFormData.append("Description", payload.Description);
    //   }
  


    //  else if(payload.from !="FindOwner")
    //   {
    //     //console.log("collection")
    //   bodyFormData.append("collectionType", payload.formValue.collectionType);
    //   bodyFormData.append("collectionAddress", payload.formValue.collectionAddress);
    //   bodyFormData.append("collectionName", payload.formValue.collectionName);
    //   bodyFormData.append("customurl", payload.formValue.customurl);
    //   bodyFormData.append("userAddress", payload.userAddress);
    //   bodyFormData.append("Image", payload.formValue.profileImg);
    //   bodyFormData.append("coverImage", payload.formValue.coverImg);
    //   bodyFormData.append("Single", payload.Single);
    //   bodyFormData.append("Multiple", payload.Multiple);
    //   bodyFormData.append("CategoryId", payload.CategoryId);
    //   bodyFormData.append("Category", payload.Category);
    //   bodyFormData.append("SubCategory", payload.SubCategory);
    //   bodyFormData.append("SocialLinks", payload.SocialLinks);
    //   bodyFormData.append("Description", payload.Description);
    //   }
    //   else if (payload.from == "FindOwner")///find owner
    //   {
  
    //     bodyFormData.append("from", payload.from);
    //     bodyFormData.append("colladdr", payload.colladdr);
    //     bodyFormData.append("type", payload.type);
    //     bodyFormData.append("counts", payload.counts);
    //     bodyFormData.append("myaddress", payload.myAdd);
  
    //   }
 //console.log("sAHRgsrtjh",bodyFormData)


    var senddata = {
      'method': 'post',
      'url': `${config.v1Url}/user/AddUserCollection`,
      'data': formdata[0],
    };
    var respData = await axiosFunc(senddata);
    return {
      message: respData
    }
}
catch (err) {
  return { error: err }
}
}




export const RegisterpassCheck = async (payload) => {
  //console.log("fnvhfduhivgbf",payload);
 try{
  var senddata = {
    'method': 'post',
    'url': `${config.v1Url}/user/registerpassCheck`,
    'data' : payload,
};
  var respData = await axiosFunc(senddata);
 return {
     message: respData
 }
}
catch (err) {
 return { error: err }
}
}






export const getUserCollection = async (payload) => {
  // //console.log("fnvhfduhivgbf",payload);
  try{
    var senddata = {
      'method': 'post',
      'url': `${config.v1Url}/user/getUserCollection`,
      'data' : payload,
    };
    var respData = await axiosFunc(senddata);
    console.log('dhfkhsfhsgsg',respData)
  return {
      message: respData
  }
}
catch (err) {
  return { error: err }
}
}

//submitdata


export const submitdata = async (payload) => {
  // //console.log("fnvhfduhivgbf",payload);
  try{
    var senddata = {
      'method': 'get',
      'url': `${config.v1Url}/user/submitdata`,
      'params' : payload,
    };
    var respData = await axiosFunc(senddata);
  return {
      message: respData
  }
}
catch (err) {
  return { error: err }
}
}

export const generatepass = async(payload)=>
{
  try{
    let respData = await axios({
      'method': 'post',
      'url': `${config.v1Url}/user/generatepass`,
      'data' : payload,
  });
  return {
      message: respData
  }
}
catch (err) {
  return { error: err }
}
}


export const getAllNFTFromContract = async (payload) => {
  try {
    //console.log("Hmghujfhghj",payload);
    var senddata = {
      'method': 'get',
      'url': `${config.vUrl}/user/getAllNFTFromContract`,
      params: payload
    };
    var resp = await axiosFunc(senddata);
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}
export const userget = async (payload) => {
  try {
    var senddata = {
      'method': 'get',
      'url': `${config.vUrl}/user/userget`,
       params: payload
    };
    var resp = await axiosFunc(senddata);
    return {
      data: resp
    }
  }
  catch (err) {
  }
}

export const Forgotpassword = async(payload) =>{
  try{
    var senddata = {
      'method': 'get',
      'url': `${config.v1Url}/user/forgotpassword`,
      'params' : payload,
    };
    var respData = await axiosFunc(senddata);
  return {
      message: respData
  }
}
catch (err) {
  return { error: err }
}
}

export const Changepassword = async(payload) =>{
  try{
    var senddata = {
      'method': 'post',
      'url': `${config.v1Url}/user/changepassword`,
      'data' : payload,
    };
    var respData = await axiosFunc(senddata);
  return {
      message: respData
  }
}
catch (err) {
  return { error: err }
}
}


export const getnewpromotion = async () => {
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.v1Url}/admin/panel/getnewpromotion`,
    };
    var respData = await axiosFunc(senddata);
      return {
          data : respData.data
      }
  }catch(error) {
      return {
          error : error
      }
  }
}

export const generateTFA = async () => {
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.v1Url}/user/getTFA`,
      'data': {email: localStorage.getItem("registedEmail")}
    };
    var respData = await axiosFunc(senddata);
  return {
      data : respData.data
  }
  } catch (err) {
    console.log("generateTFA",err)
  }
}

export const enableTFA = async (data) => {
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.v1Url}/user/verifyTFA`,
      'data': data
    };
    var respData = await axiosFunc(senddata);
  return {
      data : respData.data
  }
  } catch (err) {
    console.log("enableTFA",err)
  }
}

export const verifyOTP = async (data) => {
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.v1Url}/user/verifyOTP`,
      'data': data
    };
    var respData = await axiosFunc(senddata);
  return {
      data : respData.data
  }
  } catch (err) {
    console.log("enableTFA",err)
  }
}

export const loginTFA = async (data) => {
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.v1Url}/user/verifyLoginTfa`,
      'data': data
    };
    var respData = await axiosFunc(senddata);
  return {
      data : respData.data
  }
  } catch (err) {
    console.log("enableTFA",err)
  }
}

export const createSupportTicket = async (data) => {
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.v1Url}/user/supportTicket`,
      'data': data
    };
    var respData = await axiosFunc(senddata);
  return {
      data : respData.data
  }
  } catch (err) {
    console.log("enableTFA",err)
  }
}



export const getSupportTicket = async (data) => {
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.v1Url}/user/getSupportTicket`,
      'data': data
    };
    var respData = await axiosFunc(senddata);
  return {
      data : respData.data
  }
  } catch (err) {
    console.log("enableTFA",err)
  }
}


export const closeSupportTicket = async (data) => {
  try {
    var senddata = {
      'method': 'post',
      'url': `${config.v1Url}/user/closeSupportTicket`,
      'data': data
    };
    var respData = await axiosFunc(senddata);
  return {
      data : respData.data
  }
  } catch (err) {
    console.log("enableTFA",err)
  }
}


export const getaccouncementurldata = async (data, dispatch) => {
  //////console.log("**********", data)
  try {
      console.log("getaccouncementurldata", data, config.Back_URL)
      var senddata = {
        'method': 'post',
        'url': `${config.v1Url}/user/geturldata`,
        // 'headers': {
        //     'Authorization': localStorage.user_token
        // },
        data
      };
      var respData = await axiosFunc(senddata);

      return {
          loading: false,
          data: respData.data
      }
  }
  catch (err) {
      return {
          loading: false,
          error: err.response.data.errors
      }
  }
}

export const UserCheck = async (data) => {
  try{
    var senddata = {
      'method': 'post',
      'url': `${config.v1Url}/user/getprofile`,
      // 'headers': {
      //     'Authorization': localStorage.user_token
      // },
      data
    };
    var respData = await axiosFunc(senddata);

      return respData.data;
  }
  catch(err){
      console.log('ldsjfdjalkfdaf',err)
  }
}
