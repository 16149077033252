import isEmpty from "lib/isEmpty";
import { ServiceFeeaction } from "./v1/report";
import config from '../lib/config';

export const GetGasFees = async (data) => {
    try{
        var {web3, encoded,contractaddress,value, accountAddress } = data;
      var gasPrice = await web3.eth.getGasPrice();
      // console.log('skjfffffffssssss@124',chain,TradeAddress);
      var gasdata;
        if(value){
          gasdata = await web3.eth.estimateGas({
            from: accountAddress,
            to: contractaddress,
            value:value,
            data: encoded,
          });
        }
        else{
          gasdata = await web3.eth.estimateGas({
            from: accountAddress,
            to: contractaddress,
            data: encoded,
          });
        }
        
        console.log('hdgdgkdggd',gasPrice,gasdata)
        return({gasdata:gasdata,gasPrice:gasPrice});
    }
    catch(err){
      console.log('errorrr',err);
      return undefined;
    }
}

export const NewGetGasFees = async (data) => {
    try{
        var {web3, contractcall, value, accountAddress } = data;
      var gasPrice = await web3.eth.getGasPrice();
      console.log('skjfffffffssssss@124',data);
      var gasdata;
        if(value){
          console.log('skjfffffffssssss@124222',data,value);
          gasdata = await contractcall.estimateGas({
            from: accountAddress,
            value:value,
          });
        }
        else{
          gasdata = await contractcall.estimateGas({
            from: accountAddress,
          });
        }
        
        console.log('hdgdgkdggd',gasPrice,gasdata)
        return({gasdata:gasdata,gasPrice:gasPrice});
    }
    catch(err){
      console.log('NewGetGasFees errorrr',err,data);
      return ({ gasdata: 29094, gasPrice:5000000000, });
    }
}

export const GetServiceFee = async(type,Contract) => {
    let Resp = await ServiceFeeaction({ type: "get" });
    console.log('ldskjkslkfsklfjskljfs111111',Resp,type)
    let currfees = Resp?.data?.filter((val)=>val.FeeName==type).pop();
    if(isEmpty(currfees?.key)&&currfees?.key!=0){
        currfees = {};
        currfees.key = config?.defaultservicekey;
    }
    let fees = await Contract.methods.FeeType(currfees?.key).call();
    console.log('ldskjkslkfsklfjskljfs',fees)
    return {
        key: currfees.key,
        Service_Fee_buyer: fees?.buyerFees,
        Service_Fee_seller: fees?.sellerFees,
        Minting_fee: fees?.mintFees,
        Listing_fee: fees?.listFees,
        Withdraw_fee: fees?.withdrawFees,
    }
}
