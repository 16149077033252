import { combineReducers } from 'redux';
export const Account_Connect = 'Account_Connect';
export const Account_disConnect = 'Account_disConnect';

const initial_wallet_connect ={
        modelpopup:false,
        UserAccountAddr:'',
        UserAccountBal:0,
        providerss:null,
        Service_Fee_buyer:0,
        Service_Fee_seller:0,
        Minting_fee:0,
        Listing_fee:0,
        Withdraw_fee:0,
        baseRoyalty:0,
        Wen_Bln:0,
        Accounts:'',
        Regname:'',
        Profile:'',
        Customurl:"",
        _id:"",
        WalletConnected:'',
        AddressUserDetails:null,
        tokenAddress:{},
        tokenAddressETH:{},
        tokenAddressBSC:{},
        networkoption:{},

        swapFee:0,
        currency_convertion:0,
        networkConfiguration:
        {
          currencySymbol: '',
          tokenSymbol: '',
          tokenABI: { },
          tokenAddr: { },
          singleContract: "",
          multipleContract: "",
          trade: "",
          BNBPROVIDER: "",
          networkVersion: '',
          chainId: '',
          Chainid: 0,
        },
        currency_price:{},
}
//console.log("cnnjdbvhcfbihvbdfsj",initial_wallet_connect);
function wallet_connect_context(state=initial_wallet_connect, action) {
        //console.log("vathutan",action.type)
        switch (action.type) {  
        case Account_Connect:{
          //console.log("chech come here")
        
      return {
        ...state,
       ...action.Account_Detail
}
        };
case Account_disConnect:
        return {
          ...state,
          ...initial_wallet_connect
  };
    default:{
      //console.log("chech come here not ",action)
      return state;

    }
  }
}
const birdApp = combineReducers({
        wallet_connect_context
      });
      
      export default birdApp;