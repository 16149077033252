import React, {
    forwardRef,
    useImperativeHandle,
    useEffect,useState
} from 'react';

import { Button } from '@material-ui/core';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import isEmpty  from 'lib/isEmpty';
import randomInteger from 'random-int';

import config from '../../lib/config';
import SINGLE from '../../ABI/SINGLE.json'
import MULTIPLE from '../../ABI/MULTIPLE.json'
import Convert from '../separate/Convert'
import {
    GetCategoryAction,
    TokenPriceChange_update_Action,
    checkOtherPlatformDetais1155,
    getUserCollectiondata
} from '../../actions/v1/token';
import { getReceipt } from 'actions/v1/getReceiptFunc';
import Trade from '../../ABI/Trade.json'
import Convert1 from '../../views/separate/Convert1'
import AddIcon from '@mui/icons-material/Add';
import  delete_png from '../../assets/images/del.png'


import { connectWallet, WalletConnect } from '../../views/hooks/useWallet';
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import { useSelector,useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Select from 'react-select'
import { network } from "../../views/network"
import { toast } from 'react-toastify';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Settings } from '@material-ui/icons';
import Market from "../../ABI/market"
import ERC721 from '../../ABI/SINGLE'
import ERC1155 from '../../ABI/MULTIPLE'
import { CollectionValidationAction } from 'actions/v1/user';
import { addUserCollection } from 'actions/v1/user';
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import cvr_img from '../../assets/images/cover_img.png'
import editpng from '../../assets/images/edit_pencil.png'
import Profile from '../../assets/images/no_profile2.png'
import { UserCheck } from 'actions/v1/user';

toast.configure();
let toasterOption = config.toasterOption;

const initialFormValue = {
    'userAddress': "",
    'collectionAddress': "",
    'collectionName': "",
    'customurl': "",
    'collectionType': '',
    'Image': '',
    'coverImage': '',
    'Description': '',
  }

  const mapdata = [
    { value: 'Discord', label: 'Discord', classname:"fab fa-discord" },
    { value: 'Instagram', label: 'Instagram', classname:"fab fa-instagram" },
    { value: 'Linkedin', label: 'Linkedin', classname:'fab fa-linkedin' },
    { value: 'Whatsapp', label: 'Whatsapp', classname:"fab fa-whatsapp" },
    { value: 'Twitter', label: 'Twitter', classname:"fab fa-twitter" },
    { value: 'Slack', label: 'Slack', classname:"fab fa-slack" },
    { value: 'Telegram', label: 'Telegram', classname:"fab fa-telegram" },
    { value: 'Github', label: 'Github', classname:"fab fa-github" },
    { value: 'Facebook', label: 'Facebook', classname:"fab fa-facebook-f" },
    { value: 'Others', label: 'Others', classname:"fas fa-hashtag" },
    // Add more data items here as needed
  ];

export const AddCollectionRef = forwardRef((props, ref) => {


    const useStyles = makeStyles(styles);
    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();

    const Wallet_Details = useSelector(state=>state.wallet_connect_context);


    var { name, form } = useParams();
  

    const location = useLocation();
    const inststate = location?.state?.state

    const [state,SetState] = useState(inststate)
    const [FormSubmitLoading, Set_FormSubmitLoading] = React.useState('start');
    const [TokenPrice, Set_TokenPrice] = React.useState(0);
    const [Owners, Set_Owners] = React.useState({});
    const [Item_Owner, Set_Item_Owner] = React.useState({});
    const [Items, Set_Item] = React.useState({}); 

    const [validateError, setValidateError] = useState({});
  const [imageVal, setImageVal] = useState('')
  const [onchangeimg, setOnchangeimg] = useState('')
  const [coverval, setcoverval] = useState('')
  const [coverimg, setcoverimg] = useState('')
  const [formValue, setFormValue] = useState(initialFormValue);
  const [disablebtn, setDisablebtn] = useState(0)
  const [CollectionOption, setCollectionOption] = useState([{ value: 'SINGLE', label: 'single folder' }, { value: 'multiple', label: 'polymultiple folder' }, { value: 'MULTIPLE', label: 'unimultiple folder' }, { value: "LITERATURE", label: "literature folder"} ]);
  const [NFTType, setNFTType] = useState('')
  const [fullcategory, setFullcategory] = useState({});
  const [CategoryOption, setCategoryOption] = useState([]);
  const [CategorywithSubcategory,setCategorywithSubcategory] = useState({})
  const [category,setCategory] = React.useState(false);

  const [socialLinks, SetSocialLinks] = useState([]);
  const [filterRows, setFilterRows] = useState([]);

  const mappedData = mapdata.map(item => ({
    value: item.value,
    label: item.label,
    classname: item.classname,
  }));


  useEffect(()=>{
    if(location?.pathname){
      setFormValue({
        ...formValue,
        ...{collectionType:(location.pathname=='/create-single'?'SINGLE':(location.pathname=='/create-multiple'?'MULTIPLE':((location.pathname.includes('list') && String(state?.type)) == '721' ? 'SINGLE' : ((location.pathname.includes('list') && String(state?.type)) == '1155' ? 'MULTIPLE' : 'SINGLE'))))},
        ...(location.pathname=='/create-single'?
        {value: 'SINGLE', label: 'single folder'}:
        ((location.pathname=='/create-multiple')?
        { value: 'MULTIPLE', label: 'unimultiple folder' }:
        ((location.pathname.includes('list') && String(state?.type)) == '721' ? {value: 'SINGLE', label: 'single folder'} : 
        ( String(state?.type)) == '1155' ? { value: 'MULTIPLE', label: 'unimultiple folder' } :
        { value: 'single', label: 'polymultiple folder' })))
      })
    }
    if(props?.hideliteratue){
      setCollectionOption([
        { value: 'SINGLE', label: 'single folder' }, 
        { value: 'multiple', label: 'polymultiple folder' }, 
        { value: 'MULTIPLE', label: 'unimultiple folder' },
      ])
    }
  },[location.pathname])

  const {
    collectionName,
    customurl,
    collectionType,
    Image,
    coverImage
  } = formValue


  useEffect(() => {

    CollectionProfile();

  }, [Wallet_Details.UserAccountAddr])

  async function CollectionProfile() {



    // //console.log("fsdbghdf")
    let formData = {};
    var payload = {
      collectionName: name,
      from: "addCollection"
    }
    // //console.log("zdgzdhg", payload)
    var collectionInfo = await getUserCollectiondata(payload);
    if (collectionInfo && collectionInfo.data && collectionInfo.data.data && collectionInfo.data.data.collections?.length > 0) {
      var collectionData = collectionInfo.data.data.collections
      collectionData.map((item) => {
        formData['collectionName'] = item.collectionName;
        formData['customurl'] = item.customurl;
        formData['collectionType'] = item.collectionType;
        //formData['Image'] = item.imageName;
        //formData['coverImage'] = item.coverimage; 
        formData['userAddress'] = Wallet_Details.UserAccountAddr;


        // setcoverimg(item.coverimage)
        setNFTType(item.collectionType)
        //console.log("zdfbsdfbdsz", item.imageName === null, item.coverimage === '');
        //console.log("dgfnsfgnjsgf",item.imageName,item.imageName != '' || item.imageName !== undefined);
        if (item.imageName != '' || item.imageName !== undefined) {
          var profileimage = config.Back_URL + '/collectionLogo/' + item.userAddress + '/' + item.imageName;
          setOnchangeimg(profileimage)
        }
        if (item.coverimage != '' || item.coverimage != undefined) {

          var coverimage = config.Back_URL + '/coverphoto/' + item.userAddress + '/' + item.coverimage;
          setcoverimg(coverimage)
        }

      })
      setFormValue({...formValue,...formData})
      // //console.log("fdsbhsfgnh", formData.collectionName)
    }
    
  }

  const handleFile1 = (event) => {
    event.preventDefault();
    var reader = new FileReader()
    const { id, files } = event.target;
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      setcoverval(file)
      var url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        if (reader.result) {
          setcoverimg(reader.result);
        }
      }
    }
    //console.log("Dfbhrsfrtgjh11", id, files[0])
    let formData = { ...formValue, ...{ [id]: files[0] } };
    setFormValue(formData);
    //console.log("Dfbhrsfrtgjh22", formData)
  }


  const handleFile = (event) => {
    setDisablebtn(0)
    event.preventDefault();
    var reader = new FileReader()
    const { id, files } = event.target;
    setDisablebtn(0)
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      setImageVal(file)
      var url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        if (reader.result) {
          setOnchangeimg(reader.result);
          // setImageVal1(file.name)
        }
      }
    }
    let formData = { ...formValue, ...{ [id]: files[0] } };
    setFormValue(formData);
  }

  const onChange = (e) => {
    setDisablebtn(0)
    const { id,value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } }
    setFormValue(formData)
  }

  const selectChange = (e) => {

    if (e && e.label && e.value) {
      const { id, value } = e
      let formData = { ...formValue, ...{ ['collectionType']: value, label: e.label, value: e.value } }
      //console.log("dhtsdrth", e)
      setFormValue(formData)


    }
  }


  const handleFormSubmit = async (e) => {
    if (Wallet_Details.UserAccountAddr === "") {
      toast.warn("please connect your wallet")
    }
    else {
      let useractive = await UserCheck({ currAddr: Wallet_Details?.UserAccountAddr });
      if (useractive?.userValue?.activate) {

      }
      else {
        toast.error("User banned by the admin")
        return false;
      }
      try {
        const userAddress = Wallet_Details.UserAccountAddr
        const collectionType = NFTType
        let reqdata = {
          formValue,
          userAddress,
          Single: Wallet_Details.networkConfiguration.singleContract,
          Multiple: Wallet_Details.networkConfiguration.multipleContract,
          CategoryId: CategorywithSubcategory.id,
          Category: CategorywithSubcategory.category,
          SubCategory: CategorywithSubcategory.subcategory,
          SocialLinks: JSON.stringify(socialLinks),
          Literature: Wallet_Details?.networkConfiguration?.literatureContract,
        }
        reqdata.formValue.collectionType = (reqdata.formValue.collectionType).toLocaleUpperCase()
        var validate = await Validate(reqdata)

        console.log('kjsjdjlfkjshfd', reqdata, validate, form)

        if (!isEmpty(validate)) {
          setDisablebtn(1)
          toast.error("please fill the required field", toasterOption);
        }

        else if (form != "usercollection") {

          const resp = await CollectionValidationAction(reqdata)

          var errors = resp.data;
          var errorsSize = Object.keys(errors).length;
          console.log("kjsjdjlfkjshfdopennn", resp);
          if (errorsSize != 0 || errorsSize > 0) {
            setDisablebtn(1)
            setValidateError(errors);
          }

          else {
            setDisablebtn(3)
            console.log("kjsjdjlfkjshfd11111", reqdata);
            reqdata = { ...reqdata, ...reqdata.formValue, ...{ userAddress } };
            reqdata.from = "add";
            var responsedata = await addUserCollection(reqdata)
            console.log('kjsjdjlfkjshfd2222222222', responsedata,reqdata);
            if (responsedata) {
              setValidateError("");
              toast.success("Collection Added successfully", toasterOption)
              props.SetCurrCollection({ ...responsedata?.message?.data?.soci, ...{ name: 'userCollection', label: responsedata?.message?.data?.soci?.collectionName, value: responsedata?.message?.data?.soci?._id } })
              props.GetCollection()
              ClearAll();
              window.$('#Create_collection').modal('hide');
            }
          }

        }
        else if (form == "usercollection") {
          setDisablebtn(3)
          reqdata = { ...reqdata, ...reqdata.formValue, ...{ userAddress } }
          var responsedata = await addUserCollection(reqdata)
          console.log('ldsjfslkjffs',responsedata)
          if (responsedata) {
            setValidateError("");
            toast.success("collection Updated successfully", toasterOption)
            window.$('#Create_collection').modal('hide');
          }
        }
      }
      catch (err) {
        //console.log("gfnjfghnfghm",err);
      }
    }
  }

  const Validate = async (data) => {
    try{
    var validateError = {};

    let imageSize = 5000000;

    // let urlRegex = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/;


    if (onchangeimg != "") {
      //console.log("epwopeopwope", data.formValue.coverImage, onchangeimg, onchangeimg.split('.').pop())
      if ((data?.formValue?.Image != "" && data?.formValue?.Image != undefined)) {
        if (imageSize < (data?.formValue?.Image)?.size) {
          validateError.image = "File size must be below 5mb"
        }
        if (!(/\.(jpg|JPG|jpeg|JPEG|png|PNG|webp|WEBP|gif|GIF)$/i).test((data?.formValue?.Image)?.name)) {
          validateError.image = "file is invalid. only allowed JPG,PNG,WEBP,gif";
        }
      }

    }

    if (coverimg != "") {
      //console.log("epwopeoerhtergpwope", data.formValue.coverImage, coverimg, coverimg.split('.').pop())
      if (data?.formValue?.coverImage != "" && data?.formValue?.coverImage != undefined) {
        if (imageSize < (data?.formValue?.coverImage)?.size) {
          validateError.image1 = "File size must be below 5mb"
        }
        if (!(/\.(jpg|JPG|jpeg|JPEG|png|PNG|webp|WEBP|gif|GIF)$/i).test((data.formValue.coverImage).name)) {
          validateError.image1 = "file is invalid. only allowed JPG,PNG,WEBP,gif";
        }
      }

    }

    if ((data.formValue.collectionName) === '') {
      validateError.names = "Collection Name is Required"
    }
    if ((data?.formValue?.collectionName) === '') {
      validateError.names = "Collection Name is Required"
    }
    if (data?.formValue?.customurl === '') {
      validateError.customurl = "Custom url is Required"
    }

    if (data?.formValue?.collectionType === '') {
      validateError.Collection = "Collection Type is Required"
    }
    if (isEmpty(CategorywithSubcategory)) {
      validateError.Category = '"Category" is required';
    }
    if(socialLinks?.length > 0){
      socialLinks.map((val,ind)=>{
        if(isEmpty(val.url)){
          validateError['url'+ind] = "Url cannot be Empty";
        }
        if(isEmpty(val.urlfor)){
          validateError['urlfor'+ind] = "Url cannot be Empty";
        }
      })
    }
    if(data?.formValue?.collectionAddress)
    {
      try{
const web3 = new Web3(Wallet_Details.networkConfiguration.BNBPROVIDER)
    var ConnectContract = await new web3.eth.Contract(
      Number(data?.formValue?.collectionType)=="SINGLE" ? ERC721 : ERC1155,data?.formValue?.collectionAddress
  );

    //console.log('coooonnnnn',ConnectContract)
    var contractowner = await ConnectContract.methods.owner().call()



    //console.log("ghmfghmfhmfhmj",contractowner.toLowerCase() , Wallet_Details.UserAccountAddr.toLowerCase(),contractowner.toLowerCase() !== Wallet_Details.UserAccountAddr.toLowerCase());
    if(String(contractowner.toLowerCase()) !== String(Wallet_Details.UserAccountAddr.toLowerCase())){
      validateError.CollectionContractAddress = "Owner Address is InValid"
    }
  }catch(err)
  {
    console.log("fgbnhdfgnhbdf",err);
  }
  }
    setValidateError(validateError)
    return validateError;
}
catch(error){
  console.log("fgbnhdfgnhbdferr",error);
}
  }


  useEffect(()=>{
    GetCategoryCall();
  }, [Wallet_Details.UserAccountAddr]);

  async function GetCategoryCall() {
    var resp = await GetCategoryAction({cathide:props?.hideliteratue?config.literaturcategory:''})
    //console.log("fgjndghkgh",resp);
    if (resp && resp.data && isEmpty(resp.data.list) === false) {
      var CategoryOption = [];
      var ind = null
      if(resp&&resp.data&&isEmpty(resp.data.list) === false)
      {
      resp.data.list.map((item, index) => {
        CategoryOption.push({
          name: 'TokenCategory',
          value: item._id,
          label: item.name
        })
      })
    }
      setCategoryOption(CategoryOption)
      setFullcategory(resp.data.list)
    }
  }

  var SettokenCategory =(cat,subcat)=>{
    setCategorywithSubcategory({category:cat.name,subcategory:subcat,id:cat._id,display:cat.name+'-'+subcat})
    setCategory(!category)
  }
 
  useImperativeHandle(
    ref,
    () => ({
        async AddCollection_Click() {
            window.$('#Create_collection').modal('show');
        }
    }),
)


  const ClearAll = () => {
    setValidateError({})
    setFormValue(initialFormValue)
    setOnchangeimg("")
    setcoverimg("")
    setCategorywithSubcategory({})
    SetSocialLinks([])
    setFilterRows([])
  }


  const addFilters = () => {
    if(filterRows.length < 10){
      setFilterRows([...filterRows, { filters: "" }])
      SetSocialLinks([...socialLinks, { url: "" }])
    }
    else{
      toast.error('Already added 10 links',1000)
    }
  };

  const removeFilters = (index) => {
    const list = [...filterRows]
    let currlink = socialLinks;
    list.splice(index, 1)
    currlink.splice(index,1)
    setFilterRows(list)
    SetSocialLinks([...currlink])
  };

  const OnChange = (e,index) => {
    let currlinks = socialLinks;
    setValidateError({})
    if(e?.target?.id){
      
      const {id,value} = e.target;
      
      filterRows.map((val,ind)=>{
        if(ind == index){
          currlinks[ind][id] = value
        }
        
      })
    }
    else if(e?.value){
      const {classname,label,value} = e;
      
      filterRows.map((val,ind)=>{
        if(ind == index){
          currlinks[ind].urlfor = value;
          currlinks[ind].classname = classname;
        }
        
      })
    }
    SetSocialLinks([...currlinks])
  }

    return (
        <div className="modal modal-lg fade primary_modal" id="Create_collection" tabIndex="-1" role="dialog" aria-labelledby="cancel_order_modalCenteredLabel" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered " role="document">
                <div className="modal-content">
                <div className="modal-header text-center">
                    <h5 className="modal-title" id="buy_modalLabel">Add Collections</h5>
                    <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close" id="close9" onClick={()=>ClearAll()}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    
                </div>
                
                <div className={classes.pageHeader + " inner_pageheade"}>
        <div className="container mt-5">
          <GridContainer>
            <GridItem>

            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                  <div className="banner_sec_collection">
                    <div className="cover_coll_sec">
                    {coverimg === '' &&
                    <img src={cvr_img} alt="logo" id="imgPreview" className="img-fluid cover_image" />
                  }
                  {coverimg != '' &&
                    <img src={coverimg ? coverimg : null} alt={coverimg ? coverimg.name : null} id="imgPreview" className="img-fluid cover_image" />
                  }
                   <div className="cover_edit_banner">
                  <img src={editpng} alt="edit" className="img-fluid" width={30}/>
                  <input
                    type="file"
                    name="photo"
                    id="coverImage"
                    className="photo"
                    onChange={(e) => handleFile1(e)}
                  
                  />
                   {validateError.image1 && <span className="text-danger">{validateError.image1}</span>}
                  </div>
                    </div>
                    <div className="banner_pf_sec">
                    {onchangeimg === '' &&
                    <img src={Profile} alt="logo" id="imgPreview" className="img-fluid profile_img" />
                  }
                  {onchangeimg != '' &&
                    <img src={onchangeimg ? onchangeimg : null} alt={onchangeimg ? onchangeimg.name : null} id="imgPreview" className="img-fluid profile_img" />
                  }
                   <div className="pf_edit_banner">
                  <img src={editpng} alt="edit" className="img-fluid" width={30}/>
                  <input
                    type="file"
                    name="photo"
                    id="Image"
                    required="true"
                    className="photo"
                    onChange={(e) => handleFile(e)}
                  />
                {validateError.image && <span className="text-danger">{validateError.image}</span>}
                  </div>

                    </div>




                  </div>








             
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <form>
                <div className="form-row mt-5">
                {
                    (form != "usercollection") &&
                 
                    <div className="form-group col-md-5 mb-4">
                      <label className="primary_label" htmlFor="name">Collection Name </label>
                      <p className='col_ex_name'>
                        Name with type of nft(e.x. captain (audio))
                      </p>
                      <input type="text"
                       
                        className="form-control primary_inp"
                        id="collectionName"
                        placeholder="Enter your Collection Name"
                        onChange={onChange}
                        value={formValue?.collectionName || ''}
                        />
                   {validateError.names && <span className="text-danger">{validateError.names}</span>}
                      {validateError.collectionName && <span className="text-danger">{validateError.collectionName}</span>}
                    </div>
                  
                }
                 {  (form != "usercollection") &&
                    <div className="form-group offset-md-2 col-md-5 mb-4">

                      <label className="primary_label" htmlFor="collectionType">Type</label>
                      <Select
                        // is
                        className="form-control primary_inp typecontrol"
                        onChange={selectChange}
                        name="collectionType"
                        options={CollectionOption}
                        maxMenuHeight={220}
                        value={
                          formValue
                        }
                        label="Select or type name"
                        placeholder="Select or type name"
                        
                      />
                  {validateError.Collection && <span className="text-danger"><br />{validateError.Collection}</span>}

                    </div>
                  
                 }


                 {
 (form != "usercollection") &&
<div className="form-group col-md-5 mb-4">
<label className="primary_label" htmlFor="collectionAddress">Collection Address   (optional)</label>
<input type="text"
  className="form-control primary_inp"
  id="collectionAddress"
  placeholder="Enter your Collection Address"
  onChange={onChange} 
  value={formValue?.collectionAddress || ''}
  />
{validateError.CollectionContractAddress && <span className="text-danger">{validateError.CollectionContractAddress}</span>}

</div>
    }
          
{  (form != "usercollection") &&
                    <div className="form-group  offset-md-2  col-md-5">
                      <label className="primary_label" htmlFor="customurl">Custom Url</label>
                      <input type="text"
                        className="form-control primary_inp"
                        id="customurl"
                        onChange={onChange}
                        placeholder="Enter your Custom Url"
                        value={formValue?.customurl || ''}
                     />
                       {validateError.customurl && <span className="text-danger">{validateError.customurl}</span>}

                      
                    </div>
                  
}


                    <div className="form-group col-md-5 mb-4 category">
                      <label className="primary_label" htmlFor="category">Category</label>
                    <input type="text" placeholder="Select Category" className="form-control" 
                    value={CategorywithSubcategory?.display ? CategorywithSubcategory?.display : ""}
                    onClick={() => setCategory(!category)}/>
                    {category == true ?
                    (
                    <><div className="dropdown">
                      
                      <ul className="dropdown-menu1 p-3">
                      {fullcategory?.length > 0  &&
                      fullcategory.map((val)=>{
                        return(
                        <li className="dropdown-sub">
                          {val.name}
                          <ul className="sub-dropdown">
                            {val.subcategory.map((sub)=>{
                              return(
                              <li onClick={()=>SettokenCategory(val,sub)}>{sub}</li>
                              )
                            })}
                          </ul>
                        </li>)
                      })
                      }
                      </ul>
                    </div></>):(<></>)}  

                  {validateError.Category && <span className="text-danger"><br />{validateError.Category}</span>}

                    </div>

                    <div className="form-group offset-md-2  col-md-5">
                      <label className="primary_label" htmlFor="category">Description</label>
                    <input type="text" id='Description' placeholder="Description" className="form-control" value={formValue?.Description} onChange={onChange}/>
                    </div>

<div className="form-row w-100">
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="name">
                      Social Media Links
                    </label>
                  </div>
                </div>
                <div className="social_add_sec">
                  <p className="text_title">Interactive media links, e.g. Facebook, Telegram, Twitter, Instagram, etc.</p>
                      
                      <div className="add_social">
                        <div className="btn_sec">
                      <Button type="button" className="btn btn-add-social" onClick={addFilters}> <AddIcon/></Button><span className="add_txt">Click to Add</span>
                      </div>
              
                      </div>
                      {filterRows.map((val,index) => (
                    <div className="url_card my-3" key={index}>
                     <label className="primary_label" htmlFor="name">
                      URL For
                    </label>
                      <div className="row first_row">
                        <div className="col-lg-3 mb-4 mb-lg-0">
                        <Select 
                          id = "urfor"
                          options={mappedData}
                          classNamePrefix="react-select"
                          className="yes1 typecontrol3 form-control primary_inp typecontrol" 
                          onChange= {(e)=>OnChange(e,index)}
                          value={
                            socialLinks[index]?.urlfor?
                            {
                              label:socialLinks[index].urlfor,
                              value:socialLinks[index].urlfor,
                            }
                            :''
                          }
                        />
                        {validateError['urlfor'+index] && (
                      <span className="text-danger">{validateError['urlfor'+index]}</span>
                    )}
                        </div>
                        <div className="col-lg-9 mb-4 mb-lg-0">
                          <div className="row">
                            <div className="col-10">
                        <form>
  <div class="form-group">
    <input type="text" class="form-control" id="url" aria-describedby="emailHelp" placeholder="Enter url" onChange={(e)=>OnChange(e,index)} value={socialLinks[index]?.url||''} />
    {validateError['url'+index] && (
                      <span className="text-danger">{validateError['url'+index]}</span>
                    )}
  </div>
  </form>
  </div>
  <div className="col-2"><img src={delete_png} onClick={()=>removeFilters(index)} alt="delete" className="img-fluid delete_png" width={30}/></div>
  </div>
 
                        </div>
                        </div>
                    </div>
                      ))}
                </div>

                  <GridItem xs={12} sm={3} md={3}>
                  </GridItem>
                </div>
                <div className="text-end mt-3">
         
                    {/* <Button className="create_btn" >Add Collections</Button> */}
                    {
                    (form != "usercollection") &&
                    <Button className="create_btn" onClick={handleFormSubmit}>Add Collections</Button>
                  }
                  {
                    (form == "usercollection") &&
                    <Button className="create_btn" onClick={handleFormSubmit}>Update 
                    </Button>
                  }
               
          
                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
                </div>
            </div>
        </div>
    )
})

