import React, { useEffect, useState, useRef } from "react";
import Artcards from "../../assets/images/artimage/category.png";
import { Link,useLocation } from "react-router-dom";
import config from '../../lib/config'

export default function Artcard(props) {

    console.log('kdjkjdgds',props)
    const data = props?.data
    return (

        <div>
            <Link to={`/explore/${data?.name}`}>
            <div class="card">
                <img class="card-img-top" src={data?.image ? `${config.Back_URL}/category/${data?._id}/${data?.image}` : Artcards} alt="Card image cap"/>
                    <div class="card-body">
                        <h5 class="card-title mt-0 mb-0 text-center">{data?.name}</h5>
                    </div>
            </div>
            </Link>
        </div>

    );
}
