import React, { useState, useEffect } from "react";
// import pdfFile from "../../assets/pdf/sample.pdf";
import Loader from "./Loader";
import { Document, Page, pdfjs } from "react-pdf";
import ControlPanel from "./ControlPanel";
import { useParams, useHistory } from "react-router-dom";
import { GetNFTOwnerInfo } from "actions/v1/token";
import { useSelector } from "react-redux";
import { isEmpty } from "actions/commonfunction";
import config from "../../lib/config";
import { toast } from "react-toastify";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFReader = () => {
  const handleResize = () => {
    console.log("resized to: ", window.innerWidth, "x", window.innerHeight);
    setWinwith(window.innerWidth);
  };
  window.addEventListener("resize", handleResize);

  const windowwidth = window.innerWidth;

  const Wallet_Details = useSelector((state) => state.wallet_connect_context);

  var params = useParams();
  var history = useHistory();

  var { owner, id } = params;

  const [winwidth, setWinwith] = useState(window.innerWidth);

  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextPageNumber, setNextPageNumber] = useState(pageNumber + 1);
  const [isLoading, setIsLoading] = useState(true);
  const [Detail, SetDetail] = useState({});

  useEffect(() => {
    if (owner && id) {
      GetNFTInfo();
    } else {
      history.push("/");
    }
  }, []);

  const GetNFTInfo = async () => {
    let Resp = await GetNFTOwnerInfo({ owner: owner, id: id });

    console.log("ksfgsjkgfsjkfs", Resp);
    if (Resp?.data?.owner && Resp?.data?.token) {
      SetDetail({
        ...{ userAddress: localStorage.getItem("userAddress") },
        ...Resp?.data,
      });
    } else {
      if (isEmpty(Resp?.data?.owner)) {
        toast.error("Invalid Owner", 1000);
      } else {
        toast.error("Invalid token", 1000);
      }
      history.push("/");
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setIsLoading(false);
  }

  const DisplayNFT = () => {
    if (Detail?.token?.Preview) {
      if (
        ((Array.isArray(Detail?.owner)
          ? Detail?.owner?.[0]
          : Detail?.owner
        )?.WhiteListUser?.includes(Detail?.userAddress) ||
          (Array.isArray(Detail?.owner) ? Detail?.owner?.[0] : Detail?.owner)
            ?.tokenOwner == Detail?.userAddress) &&
        !isEmpty(Detail?.userAddress)
      ) {
        return isEmpty(Detail?.token?.image)
          ? `${config.Back_URL}/nftImg/${Detail?.token?.tokenCreator}/${Detail?.token?.additionalImage}`
          : `${config.Back_URL}/compressedImage/${Detail?.token?.tokenCreator}/${Detail?.token?.image}`;
      } else {
        return !isEmpty(Detail?.token?.Preview)
          ? `${config.Back_URL}/preview/${Detail?.token?.tokenCreator}/${Detail?.token?.Preview}`
          : `${config.IPFS_IMG}/${Detail?.token?.ipfsimage}`;
      }
    } else {
      return Detail?.token?.image &&
        Detail?.token?.image &&
        Detail?.token?.image == ""
        ? `${config.Back_URL}/nftImg/${Detail?.token?.tokenCreator}/${Detail?.token?.additionalImage}`
        : `${config.Back_URL}/compressedImage/${Detail?.token?.tokenCreator}/${Detail?.token?.image}`;
    }
  };

  return (
    <>
      {Detail?.token && (
        <>
          <section id="pdf-section" className="d-flex d-md-none flex-column ">
            <div className="mx-auto">
              <ControlPanel
                scale={scale}
                setScale={setScale}
                numPages={numPages}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                nextPageNumber={nextPageNumber}
                setNextPageNumber={setNextPageNumber}
                file={
                  DisplayNFT()
                  // "https://backend-edaface-2.maticz.in/compressedImage/0x373e672788401611444785e99c4987acbeba3117/16994466339561.pdf"
                }
                pdfFile={
                  DisplayNFT()
                  // "https://backend-edaface-2.maticz.in/compressedImage/0x373e672788401611444785e99c4987acbeba3117/16994466339561.pdf"
                }
              />
            </div>
            <div className="d-flex align-items-start container pdf_container">
              <Document
                file={
                  DisplayNFT()
                  // "https://backend-edaface-2.maticz.in/compressedImage/0x373e672788401611444785e99c4987acbeba3117/16994466339561.pdf"
                }
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  pageNumber={pageNumber}
                  scale={scale}
                  width={winwidth - 40}
                  pageLayout="singlePage"
                />
                {/* <Page /> */}
              </Document>
            </div>
          </section>

          {/* <Loader isLoading={isLoading} /> */}
          <section
            id="pdf-section"
            className="d-md-flex flex-column align-items-center w-100 d-none"
          >
            <div>
              <ControlPanel
                scale={scale}
                setScale={setScale}
                numPages={numPages}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                nextPageNumber={nextPageNumber}
                setNextPageNumber={setNextPageNumber}
                file={
                  DisplayNFT()
                  // "https://backend-edaface-2.maticz.in/compressedImage/0x373e672788401611444785e99c4987acbeba3117/16994466339561.pdf"
                }
                pdfFile={
                  DisplayNFT()
                  // "https://backend-edaface-2.maticz.in/compressedImage/0x373e672788401611444785e99c4987acbeba3117/16994466339561.pdf"
                }
              />
            </div>
            <div className="d-flex align-items-start  pdf_container">
              <Document
                file={
                  DisplayNFT()
                  // "https://backend-edaface-2.maticz.in/compressedImage/0x373e672788401611444785e99c4987acbeba3117/16994466339561.pdf"
                }
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  pageNumber={pageNumber}
                  scale={scale}
                  width={winwidth / 2 - 50}
                  pageLayout="twoPageLeft"
                />
                {/* <Page /> */}
              </Document>
              <Document
                file={
                  DisplayNFT()
                  // "https://backend-edaface-2.maticz.in/compressedImage/0x373e672788401611444785e99c4987acbeba3117/16994466339561.pdf"
                }
                onLoadSuccess={onDocumentLoadSuccess}
                className="ms-3"
              >
                <Page
                  pageNumber={nextPageNumber}
                  scale={scale}
                  width={winwidth / 2 - 50}
                  pageLayout="twoPageLeft"
                />
              </Document>
            </div>
            {/* <div>
          <ControlPanel
            scale={scale}
            setScale={setScale}
            numPages={numPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            nextPageNumber={nextPageNumber}
            setNextPageNumber={setNextPageNumber}
            file={
              "https://backend-edaface-2.maticz.in/compressedImage/0x373e672788401611444785e99c4987acbeba3117/16994466339561.pdf"
            }
            pdfFile={
              "https://backend-edaface-2.maticz.in/compressedImage/0x373e672788401611444785e99c4987acbeba3117/16994466339561.pdf"
            }
          />
        </div> */}
          </section>
        </>
      )}
    </>
  );
};

export default PDFReader;
