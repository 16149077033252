import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from 'react-router-dom';
import SINGLE from 'ABI/SINGLE.json';
// import BNB from '../../assets/images/bnb.png'
import Xdc3 from "xdc3";

import Web3 from 'web3';
import WalletConnectProvider from "@walletconnect/web3-provider";
import Icon1 from "assets/images/icon1.jpg";
import Icon3 from "assets/images/wallet_03.png";
import Icon4 from "assets/images/wallet1.png";
import Coin from "assets/images/coin.png";
import axios from 'axios';
import config from '../../lib/config';
// import LoderImg from '../../assets/dev/images/loader.gif'
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import Modal from 'react-modal';
// https://data-seed-prebsc-1-s1.binance.org:8545/
import {
  WenlamboConvert
} from '../../actions/v1/token';
import {
  AddressUserDetails_GetOrSave_Action,
} from '../../actions/v1/user';
import DETH_ABI from 'ABI/DETH_ABI.json';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';



import { WalletLinkConnector } from '@web3-react/walletlink-connector'
toast.configure();
let toasterOption = config.toasterOption;
const useStyles = makeStyles(styles);

export default function ConnectWallet(props) {
  const [modalopen, setmodalopen] = useState(true)
  const [accounts, setaccounts] = useState('')
  // const [Service_Fee, set_Service_Fee] = useState(0);
  const classes = useStyles();
  const { ...rest } = props;

  const {
    WalletConnected,
  } = props;

  const timerRef = useRef(null);

  

  // useEffect(() => {
  //   // console.clear(); 
  //   getServi();
  //   if (localStorage.walletConnectType && localStorage.walletConnectType !== null && localStorage.walletConnectType === 'mt') {
  //     getInit('mt');
  //     //////console.log("meta check coming",localStorage.walletConnectType)

  //   }
  //   else if (localStorage.walletConnectType === 'wc' && localStorage.walletconnect != null) {
  //     //////console.log("wall check coming",localStorage.walletconnect)
  //     getInit('wc')
  //   }
  //   (async () => {
  //     var convertion = await convertionValue();
  //     if (convertion && convertion.data && convertion.data.USD) {
  //       props.setConvertVal(convertion.data.USD)
  //     }


  //     // var contractCall = new web3.eth.Contract(EXCHANGE,config.exchangeAddress)
  //     // var swapFee = await contractCall.methods.getTransValue().call();
  //     // config.swapFee=swapFee
  //     // props.set_swap_fee(swapFee);


  //   })()

  // }, [localStorage.walletConnectType, WalletConnected]);


  async function getServi() {
    //////console.log("service fee calling")

    var web3sw = new Web3(config.BNBPROVIDER)
    // //////console.log("service fee calling",web3)

    if (web3sw) {
      var CoursetroContracti = new web3sw.eth.Contract(
        SINGLE,
        config.singleContract
      );
      // //////console.log("service fee calling",CoursetroContract)

      var Singlefee1 = await CoursetroContracti
        .methods
        .getServiceFee()
        .call()
      // //////console.log("service fee calling",Singlefee1)
      props.set_Service_Fee(Number(Singlefee1[0]) + Number(Singlefee1[1]))
      config.buyerfee = Singlefee1[0];
      config.sellerfee = Singlefee1[1];
      var wenlamboconvertion = await WenlamboConvert();
      if (wenlamboconvertion && wenlamboconvertion.data && wenlamboconvertion.data.data && wenlamboconvertion.data.data.value) {
        // //console.log("sdadadsadas",wenlamboconvertion.data.data.value)
        config.WenlamboConvertVal = Number(wenlamboconvertion.data.data.value)
      }
    }
  }



  var { paramUsername, paramAddress } = useParams();
  // //////console.log("ewqewqeqwewqewqeqw",paramUsername,paramAddress)
  var handle = null;
  var currAddr = "";
  var provider = null;


  async function getInit(type) {
    // if(provider==null){
    //alert(1)
    provider = await connect_Wallet(type);

    if (provider) {
      ////console.log("ndjksbvkfd", provider);

      try {
        await provider.enable()
          .then(async function () {
            var xdc3 = new Xdc3(provider)
            //alert(1)
            var netid = await xdc3.eth.net.getId()
            //console.log("connect",netid,xdc3)
            if ((netid == config.networkVersion)
              // || (web3.currentProvider.chainId === config.networkVersion)
            ) {
              localStorage.setItem('walletConnectType', type)
              ////console.log("connect",web3.currentProvider)
              var balance = 0, setacc = '';
              if (localStorage.walletConnectType === "wc") {
                var result = JSON.parse(localStorage.walletconnect).accounts
                setacc = result[0];
                var val = await xdc3.eth.getBalance(setacc)
                ////console.log("provider check",provider,currAddr,val)
                balance = val / 1000000000000000000;
                props.Set_UserAccountBal(balance);
                currAddr = String(setacc).toLowerCase();
                props.set_providers(provider)
                config.providercon = provider;
                props.Set_UserAccountBal(balance);
                props.Set_Accounts(setacc);
                setaccounts(setacc)
                props.Set_UserAccountAddr(currAddr);
                config.currAdrress = currAddr
                props.Set_WalletConnected("true");
                await AddressUserDetails_GetOrSave_Call(currAddr);
                props.AfterWalletConnected();
                var bidvalue = new xdc3.eth.Contract(
                  DETH_ABI, config.tokenAddr[config.tokenSymbol]
                );
                var bidbln = await bidvalue
                  .methods
                  .balanceOf(currAddr)
                  .call()
                var bidbln1 = (bidbln / config.decimalvalues).toFixed(config.toFixed)
                // //console.log("bidbln1",bidbln1)
                props.set_Wen_Bln(bidbln1)
                var _CHARITY = await bidvalue
                  .methods
                  ._CHARITY_FEE()
                  .call();
                var _BURN = await bidvalue
                  .methods
                  ._BURN_FEE()
                  .call();
                var _TAX = await bidvalue
                  .methods
                  ._TAX_FEE()
                  .call();
                config.tokenFee = ((Number(_CHARITY) + Number(_BURN) + Number(_TAX)) / 100) * config.decimalvalues
              }
              else if (localStorage.walletConnectType === "mt") {
                //console.log("provider check1", await xdc3.eth.getAccounts())
                var result = await xdc3.eth.getAccounts()
                setacc = result[0];
                //console.log("provider check",xdc3,result,)
                await xdc3.eth.getBalance(setacc)
                  .then(async (val) => {

                    var val = await xdc3.eth.getBalance(setacc)
                    balance = val / 1000000000000000000;
                  })
                //console.log("dfjdnhb",balance);
                currAddr = String(setacc).toLowerCase();
                props.set_providers(provider)
                config.providercon = provider;
                props.Set_UserAccountBal(balance);
                props.Set_Accounts(setacc);
                setaccounts(setacc)
                props.Set_UserAccountAddr(currAddr);
                config.currAdrress = currAddr
                props.Set_WalletConnected("true");
                await AddressUserDetails_GetOrSave_Call(currAddr);
                props.AfterWalletConnected();
                // //console.log("bidbln1","sdf")


                // var bidvalue = new Xdc3.eth.Contract(
                //   DETH_ABI, config.tokenAddr[config.tokenSymbol]
                // );

                // // //console.log("bidbln1","sdf",bidvalue.methods,currAddr)
                // var bidbln = await bidvalue
                //   .methods
                //   .balanceOf(currAddr)
                //   .call();
                // var bidbln1 = (bidbln / config.decimalvalues).toFixed(config.toFixed)
                // // //console.log("bidbln1",bidvalue,bidbln1)
                // props.set_Wen_Bln(bidbln1)
                // var _CHARITY = await bidvalue
                //   .methods
                //   ._CHARITY_FEE()
                //   .call();
                // var _BURN = await bidvalue
                //   .methods
                //   ._BURN_FEE()
                //   .call();
                // var _TAX = await bidvalue
                //   .methods
                //   ._TAX_FEE()
                //   .call();
                // config.tokenFee = ((Number(_CHARITY) + Number(_BURN) + Number(_TAX)) / 100) * config.decimalvalues

              }

            }
            else {
              var a = 0;
              props.Set_WalletConnected("false");
              if (a === 0) {
                a = a + 1
                //console.log("dsdhjash",a)
                toast.warning("Please Connect to Binance Network", toasterOption);
              }
            }
          })
          .catch((e) => {
          })
      } catch (err) {
        //console.log("errororoor",err)
        props.Set_WalletConnected("false");
      }
    }
    else {
      var n = 0

      props.Set_WalletConnected("false");
      if (n === 0) {
        n = n + 1
        toast.warning("Please Add Wallet", toasterOption);
      }
    }
  }

  async function clr() {

    if (currAddr !== "") {
      clearInterval(handle)
    }
  }
  async function connect_Wallet(type) {
    // window.$('#connect_modal').modal('hide');
    if (type === 'wc') {
      var provider = new WalletConnectProvider({
        rpc: {
          56: "https://bsc-dataseed1.binance.org",
          // 97:"https://data-seed-prebsc-1-s1.binance.org:8545/"
        },
        network: 'binance',
        chainId: 56,
        // chainId: 97,
      }
      );
      //////console.log('Connnnnnn>>>>');
      return provider;
    }
    

    else if (type === 'mt') {
      var provider = window.ethereum;

      return provider;
      

    }
    else if (type === "CoinBase"){
      ////console.log("fndkjbnhfksdfvds");
      var provider= new WalletLinkConnector({
        url: config.BNBPROVIDER,
        appName: 'web3-react example',
        supportedChainIds: [1, 3, 4, 5, 42, 10, 97,56,137, 69, 420, 80001]
      })
       //console.log("vfmhdikjvfhdmjvnkjdf ",provider);
      return provider
    }


  }


  async function AfterWalletConnected() {
    // //console.log("comecome here here")
    await AddressUserDetails_GetOrSave_Call();
    props.AfterWalletConnected();

  }

  async function AddressUserDetails_GetOrSave_Call(currAddr) {
    var ReqData = {
      addr: String(currAddr).toLowerCase()
    }
    console.log('hjafkdsfsdfsd11111',ReqData)
    var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
    console.log('hjafkdsfsdfsd',ReqData)
    if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
      props.Set_AddressUserDetails(Resp.data.data.User);
    } else {
      props.Set_AddressUserDetails({});
    }
    return true;
  }

  window.addEventListener('load', async (event) => {
    if (localStorage.getItem('walletConnectType') === 'mt') {
      if (window.ethereum) {
        // ////console.log('addEventListener',window.ethereum)
        window.ethereum.on('accountsChanged', function (accounts) {
          if (timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(() => {
            getInit('mt');
          }, 1000);
        })

        window.ethereum.on('chainChanged', async function (networkId) {
          ////console.log("varala poda",networkId,config.chainId)
          if (networkId === config.chainId) {
            if (timerRef.current) {
              clearTimeout(timerRef.current);
            }
            timerRef.current = setTimeout(() => {
              getInit('mt');
            }, 1000);
            props.Set_WalletConnected("true");
          }
          else {
            props.set_providers(null)
            props.Set_WalletConnected("false");
          }
        })
      }
    }
    else if (localStorage.walletConnectType === "wc") {
      var provider3 = null
      ////console.log("localsorage ",provider)
      if (provider3 == null) {
        provider3 = await connect_Wallet("wc");

      }
      else if (provider3 != null) {
        (provider3).on("connect", () => {

          getInit('wc')
        });
        (provider3).on("disconnect", () => {

          localStorage.removeItem('walletConnectType')
        });
      }
    }
  })


  const customStyles = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      boxShadow: '0 27px 24px 0 rgb(0 0 0 / 20%), 0 40px 77px 0 rgb(0 0 0 / 22%)',
      borderRadius: '30px',
      border: 'none !important'
    },
  };

  let subtitle;
  const [WalletConnectNotifyPopup, Set_WalletConnectNotifyPopup] = React.useState(false);


  function closeModal() {
    Set_WalletConnectNotifyPopup(false);
  }
  function closeModal1() {
    setmodalopen(false)
  }
  var WalletConnectNotifyPopup_test = '';

  if (WalletConnected) {
    WalletConnectNotifyPopup_test = false;
  }
  else {
    WalletConnectNotifyPopup_test = true;
  }

  var pathVal = '';

  const location = useLocation();
  if (location.pathname) {
    if (location.pathname.split('/').length >= 2) {
      pathVal = location.pathname.split('/')[1];
    }
  }

  const [location_pathname, Set_location_pathname] = useState(pathVal);



  return (<>
    <div>


      {(
        (WalletConnected === "false" || WalletConnected === 'false')
        &&
        (
          location_pathname === 'my-items'
          || location_pathname === 'following'
          || location_pathname === 'activity'
          || location_pathname === 'edit-profile'
          // || paramUsername
          //  || paramAddress
        )
      ) &&
        <Modal
          isOpen={modalopen}
          // onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >

          <h5 className="modal-title react_modal_title" id="wallet_connect_modalLabel_1">Network</h5>


          <div className="modal-body">
            <div className="text-center icon_coin_net">
              {/* <img src={BNB} alt="logo" className="img-fluid" /> */}
            </div>
            <div className="update_cover_div_1" id="update_cover_div_1">
              <p className="mt-0 approve_desc text-center mb-0">Connect to Binance network</p>

            </div>

          </div>

        </Modal>
      }




    </div>
    {/* <Dialog
        disableEnforceFocus
      > */}
    <div className="modal fade primary_modal" id="connect_modalOLD" role="dialog" aria-labelledby="connect_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false" >
      <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title">Connect Your Wallet</h5>

            <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close">

              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <ul className="connect_ul mb-4">
              {

                window.ethereum
                && new Web3(window.ethereum)
                && new Web3(window.web3.currentProvider)
                && (new Web3(window.web3.currentProvider.isMetaMask))
                &&

                <li>
                  <div className=" connect_card" onClick={() => {
                    // localStorage.setItem('walletConnectType','mt')
                    window.$('.modal').modal('hide')
                    getInit('mt')
                    if (localStorage.walletconnect != null) {
                      localStorage.removeItem('walletconnect')
                    }
                    props.Set_WalletConnected("true");
                  }} >
                    <div className="card-body-mod">
                      <div className="media follow_media">
                        {/* <img src={require("../../assets/images/connect_img_1.png")} alt="User" className="img-fluid mr-2" /> */}

                        <div className="media-body flex_body">
                          <div className="w-100">
                            <div className="wallet-lists"
                            >
                              <p className="my-0 media_text"> <img src={Icon1} />Metamask</p>

                            </div>

                          </div>

                        </div>

                      </div>

                    </div>
                  </div>
                </li>

              }
              {

                // window.ethereum
                // && new Web3(window.ethereum)
                // && new Web3(window.web3.currentProvider)
                // &&

                // <li>
                //   <div className=" connect_card" onClick={() => {
                //     // localStorage.setItem('walletConnectType','mt')
                //     window.$('.modal').modal('hide')
                //     getInit('mt')
                //     if (localStorage.walletconnect != null) {
                //       localStorage.removeItem('walletconnect')
                //     }
                //     props.Set_WalletConnected("true");
                //   }} >
                //     <div className="card-body-mod">
                //       <div className="media follow_media">
                //         {/* <img src={require("../../assets/images/connect_img_1.png")} alt="User" className="img-fluid mr-2" /> */}

                //         <div className="media-body flex_body">
                //           <div className="w-100">
                //             <div className="wallet-lists"
                //             >
                //               <p className="my-0 media_text"> <img src={Coin} />CoinBase</p>

                //             </div>

                //           </div>

                //         </div>

                //       </div>

                //     </div>
                //   </div>
                // </li>
              }
              <li>
                <div className="connect_card" onClick={() => {
                  // localStorage.setItem('walletConnectType','wc')
                  window.$('.modal').modal('hide')
                  getInit('wc')
                  props.Set_WalletConnected("true");
                }}>
                  <div className="card-body-mod">
                    <div className="media follow_media">
                      {/* <img src={require("../../assets/images/connect_img_1.png")} alt="User" className="img-fluid mr-2" /> */}

                      <div className="media-body flex_body">
                        <div className="w-100">
                          <div className="wallet-lists"
                          >
                            <p className="my-0 media_text"> <img src={Icon3} />Wallet Connect</p>

                          </div>

                        </div>

                      </div>

                    </div>

                  </div>
                </div>
              </li>
              {window.ethereum
                && new Web3(window.ethereum)
                && new Web3(window.web3.currentProvider)
                && (new Web3(window.web3.currentProvider.isWalletConnect)) &&

                <li>
                  <div className="connect_card  d-xl-none"
                    onClick={() => {
                      // localStorage.setItem('walletConnectType','mt')
                      window.$('.modal').modal('hide')
                      getInit('mt')
                      if (localStorage.walletconnect != null) {
                        localStorage.removeItem('walletconnect')
                      }
                      props.Set_WalletConnected("true");
                    }}
                  >
                    <div className="card-body-mod">
                      <div className="media follow_media">

                        <div className="media-body flex_body">
                          <div className="w-100">
                            <div className="wallet-lists" >
                              <p className="my-0 media_text"> <img src={Icon4} />Trust wallet</p>

                            </div>

                          </div>

                        </div>

                      </div>

                    </div>
                  </div>
                </li>}

            </ul>
          </div>
        </div>
      </div>
    </div>
    {/* </Dialog> */}
  </>
  )
}