// import Web3 from 'web3';
// import config from '../../lib/config'


export const getReceipt = async (web3, approveCall) => {
        try {
                console.log('lksjkgljskljgdinnnnn',web3,approveCall)
                // var receipt = null
                var receipt = await web3.eth.getTransactionReceipt(approveCall)
                console.log("receip1111111111111t",receipt)
                //////console.log("receipt 1",receipt)

                return receipt
                //       })
        }
        catch (err) {
                console.log('lksjkgljskljgdgetReceipt error', err)
        }

}