import React, { useEffect, useState, useRef } from "react";
import Iconss from "../../assets/images/artimage/icons.png";
import { Link, useLocation } from "react-router-dom";
import config from '../../lib/config'
import isEmpty from "lib/isEmpty";

export default function Arttable(props) {

    const [arttablelist, setarttablelist] = useState([
        {
            "id": 1
        },
        {
            "id": 2
        },
        {
            "id": 3
        },
        {
            "id": 4
        },
        {
            "id": 5
        },
    ])

    const TrendingCollections = props.trending;
    
    return (

        <div>
            <div className="row pt-3">
                <div className="col-12 col-lg-6 col-sm-12 col-md-12 mt-4">
                <div class="table-responsive-sm">
                    <table class="table table-hover">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="col">Rank</th>
                                <th scope="col">Collection</th>
                                <th scope="col">Floor Price</th>
                                <th scope="col">Volume</th>
                            </tr>
                        </thead>
                        <tbody>
                        {   TrendingCollections?.length > 0 &&
                        TrendingCollections?.slice(0,5).map((val, i) =>
                            <tr>
                                
                                <th scope="row">{i+1}</th>
                                <td>
                                { !isEmpty(val) &&
                                <Link to={`/collectiondetail/${val.userAddress}/${val?.customurl}/${val?.collectionAddress}`}>
                                    <span>
                                        <img src={ val?.imageName ? `${config.Back_URL}/collectionLogo/${val.userAddress}/${val.imageName}` : Iconss } alt="iconss" className="img-fluid iconimg" />
                                        </span>
                                    <span>{val.collectionName}</span>
                                </Link>
                                }
                                </td>
                                <td>{ val?.floorPrice ? val?.floorPrice : 0 } ETH</td>
                                <td>{ val?.volume ? val?.volume : 0 } ETH</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-sm-12 col-md-12 mt-4">
                <div class="table-responsive-sm">
                <table class="table table-hover">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="col">Rank</th>
                                <th scope="col">Collection</th>
                                <th scope="col">Floor Price</th>
                                <th scope="col">Volume</th>
                            </tr>
                        </thead>
                        <tbody>
                        { TrendingCollections?.length > 0 &&
                         TrendingCollections?.slice(5,10).map((val, i) =>
                            <tr>
                                <th scope="row">{6+i}</th>
                                <td>
                                <Link to={`/collectiondetail/${val.userAddress}/${val?.customurl}/${val?.collectionAddress}`}>
                                    <span><img src={ val?.imageName ? `${config.Back_URL}/collectionLogo/${val.userAddress}/${val.imageName}` : Iconss } alt="iconss" className="img-fluid iconimg" /></span><span>{val.collectionName}</span>
                                </Link>    
                                </td>
                                <td>{ val?.floorPrice ? val?.floorPrice : 0 } ETH</td>
                                <td>{ val?.volume ? val?.volume : 0 } ETH</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>

    );
}
