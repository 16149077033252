import React, { useEffect, useState, useRef } from "react";
import Carouselimg from "../../assets/images/artimage/carouselimage.png";
import { Link, useLocation } from "react-router-dom";
import config from '../../lib/config'

export default function Artcarousel(props) {

    const Promotion = props?.promotion
    console.log('lkdlkdlds',props)
    return (

        <div>
            <Link to={`/collectiondetail/${Promotion.userAddress}/${Promotion?.customurl}/${Promotion?.collectionAddress}`}>
            <div class="card">
                <img class="card-img-top" src={ Promotion?.imageName ? `${config.Back_URL}/collectionLogo/${Promotion.userAddress}/${Promotion.imageName}` : Carouselimg } alt="Card image cap"/>
                    <div class="card-body">
                        <h5 class="card-title mb-0">{Promotion?.collectionName}</h5>
                        <p><span>Floor : </span><span>{"<"}{Promotion?.floorPrice} ETH</span></p>
                    </div>
            </div>
            </Link>
        </div>

    );
}
