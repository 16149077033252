import axios from "axios";
import config from '../../lib/config';
import Single from 'ABI/SINGLE.json';
import Multiple from '../../ABI/MULTIPLE.json';
import Literature from '../../ABI/Literature.json';

import { toast } from 'react-toastify';
import { data } from "jquery";
import { EncryptData, isEmpty, DecryptData } from "actions/commonfunction";
import { handleCallback } from "actions/commonfunction";

toast.configure();
let toasterOption = config.toasterOption;

export const ToastShow = async (data) => {
  ////////console..log('ToastShow data',data)
  if (data.toast && data.toast.type && data.toast.msg) {
    if (data.toast.type === 'success') {
      toast.success(data.toast.msg, toasterOption)
    } else {
      toast.error(data.toast.msg, toasterOption)
    }
  }
}

const axiosFunc    =   async   (data)  =>  {
        
  try{
    let Resp    =   {};
    // if(data?.url?.includes('/user/test')){
      // if(false){
        if(data?.params){
          data.params = { data: EncryptData(data.params)  }
        }
        else if(data?.data){
          if(!(data?.data instanceof FormData)){
            data.data = { data: EncryptData(data.data)  }
          }
        }
      // }
      
    // }
      Resp    =   await axios(data)
      
      if(Resp?.data){
          Resp.data = isEmpty(DecryptData(Resp.data)) ? Resp.data : DecryptData(Resp.data)
      }
      

      return Resp
  }
  catch (e) {
      
      return { success: 'error', msg: null }
  }
}

const AppenData = (data) => {
  var formdata = new FormData()
  var SendDta = Object.entries(data).map((item) => {
      if (Array.isArray(item[1])) {
          var come = item[1].map(data => {
              if (data?.type && data?.size) {
                  //file
                  formdata.append(item[0], data)
              }
              else {
                  formdata.append(item[0], EncryptData(data))

              }
              return formdata
          })
          return come

      }
      else {
          if (item[1]?.type && item[1]?.size) {
              //file type
              formdata.append(item[0], item[1])
          }
          else {
              formdata.append(item[0], EncryptData(item[1]))

          }
          return formdata
      }
  })
  return SendDta
}

export const CancelBid_Action = async (payload) => {
  try {
    let Resp = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/bid/cancel`,
      data: payload
    });
    ToastShow(Resp.data);
    return {
      data: Resp.data
    }
  }
  catch (err) {
  }
}

export const acceptBId_Action = async (payload) => {

  try {
    let Resp = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/bid/accept`,
      data: payload
    });
    ToastShow(Resp.data);
    return {
      data: Resp.data
    }
  }
  catch (err) {
  }
}

export const BidApply_ApproveAction = async (payload) => {
  try {
    let resp = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/bid/apply`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const TokenCounts_Get_Detail_Action = async (payload) => {
  try {
    let resp = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/tokenCounts`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const History = async (payload) => {
  try {
    let resp = await axiosFunc({
      'method': 'get',
      'url': `${config.vUrl}/token/History`,
      params: payload
    });
    // //console.log("tokencount for history", resp.data);
    return resp.data
  }
  catch (err) {
  }
}


export const subscriberAction = async (payload) => {
  try {
    let resp = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/subscriber`,
      data: payload
    });
    return {
      success: true,
      data: resp.data
    }
  } catch (error) {
    return {
      success: false,
      error: error
    }
  }
}



export const PurchaseNow_Price_Action = async (payload) => {
  try {
    let resp = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/price/addprice`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const TokenPriceChange_update_Action = async (payload) => {
  try {
    let resp = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/price/change`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}
export const TokenCount_Get_Action = async (payload) => {
  try {
    let resp = await axiosFunc({
      'method': 'get',
      'url': `${config.vUrl}/token/count/get`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const TokenAddItemAction = async (payload) => {
  // //console.log('payload', payload.Subcategory);
  try {
    // var formData = new FormData();
    // if (payload.Image) { formData.append('Image', payload.Image); }
    // if (payload.ipfsimage) { formData.append('ipfsimage', payload.ipfsimage); }
    // if (payload.Name) { formData.append('Name', payload.Name); }
    // if (payload.Count) { formData.append('Count', payload.Count); }
    // if (payload.Description) { formData.append('Description', payload.Description); }
    // if (payload.Price) { formData.append('Price', payload.Price); }
    // if (payload.Royalities) { formData.append('Royalities', payload.Royalities); }
    // if (payload.Category_label) { formData.append('Category_label', payload.Category_label); }
    // // if(payload.Bid) { 
    // formData.append('Bid', payload.Bid);
    // //  }
    // if (payload.Properties) { formData.append('Properties', payload.Properties); }
    // if (payload.Owner) { formData.append('Owner', payload.Owner); }
    // if (payload.Creator) { formData.append('Creator', payload.Creator) }
    // if (payload.CategoryId) { formData.append('CategoryId', payload.CategoryId) }
    // if (payload.Quantity) { formData.append('Quantity', payload.Quantity) }
    // if (payload.Balance) { formData.append('Balance', payload.Balance) }
    // if (payload.ContractAddress) { formData.append('ContractAddress', payload.ContractAddress) }
    // if (payload.Status) { formData.append('Status', payload.Status) }
    // if (payload.HashValue) { formData.append('HashValue', payload.HashValue) }
    // if (payload.Type) { formData.append('Type', payload.Type) }
    // if (payload.MinimumBid) { formData.append('MinimumBid', payload.MinimumBid) }
    // if (payload.EndClocktime) { formData.append('EndClocktime', payload.EndClocktime) }
    // if (payload.Clocktime) { formData.append('Clocktime', payload.Clocktime) }
    // if (payload.UnLockcontent) { formData.append('UnLockcontent', payload.UnLockcontent) }

    // if (payload.PutOnSale) { formData.append('PutOnSale', payload.PutOnSale) }
    // if (payload.PutOnSaleType) { formData.append('PutOnSaleType', payload.PutOnSaleType) }
    // if (payload.swapPrice) { formData.append('swapPrice', payload.swapPrice) }
    // if (payload.CoinName) { formData.append('CoinName', payload.CoinName) }
    // if (payload.additionalImage) { formData.append('additionalImage', payload.additionalImage) }
    // if (payload.collection) { formData.append('collection', payload.collection) }
    // if (payload.SelectedNetwork) { formData.append('SelectedNetwork', payload.SelectedNetwork) }
    // if (payload.currencyCheck) { formData.append('currencyCheck', payload.currencyCheck) }
    // if (payload.decimal) { formData.append('decimal', payload.decimal) }
    // if (payload.action) { formData.append('action',payload.action) }
    // if (payload.Subcategory) { formData.append("Subcategory",payload.Subcategory) }
    // if (payload.TermsandConditions) { formData.append("TermsandConditions",payload.TermsandConditions) }
    // if (payload.Randomname) { formData.append('Randomname', payload.Randomname) }
    // if (payload._nonce) { formData.append('_nonce', payload._nonce) }
    // if (payload.signature) { formData.append('signature', payload.signature) }
    // if (payload.SocialLinks) { formData.append('SocialLinks', payload.SocialLinks) }
    // if (payload.SelectedNetworkName) { formData.append('SelectedNetworkName', payload.SelectedNetworkName) }
    
    let formdata = AppenData(payload);

    let respData = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/add/item`,
      'headers': {
        'Content-Type': 'multipart/form-data'
      },
      data: formdata[0]
    });
    // //console.log("respData : " + JSON.stringify(respData.data))
    return { data: respData.data }
  }
  catch (err) {
    // //console.log('errrorrrrr',err)
    return { error: err }
  }
}



export const TokenAddItemAction1 = async (payload) => {
  // //console.log('payload', payload.Subcategory);
  try {
    // var formData = new FormData();
    // if (payload.Image) { formData.append('Image', payload.Image); }
    // if (payload.ipfsimage) { formData.append('ipfsimage', payload.ipfsimage); }
    // if (payload.Name) { formData.append('Name', payload.Name); }
    // if (payload.Count) { formData.append('Count', payload.Count); }
    // if (payload.Description) { formData.append('Description', payload.Description); }
    // if (payload.Price) { formData.append('Price', payload.Price); }
    // if (payload.Royalities) { formData.append('Royalities', payload.Royalities); }
    // if (payload.Category_label) { formData.append('Category_label', payload.Category_label); }
    // // if(payload.Bid) { 
    // formData.append('Bid', payload.Bid);
    // //  }
    // if (payload.Properties) { formData.append('Properties', payload.Properties); }
    // if (payload.Owner) { formData.append('Owner', payload.Owner); }
    // if (payload.Creator) { formData.append('Creator', payload.Creator) }
    // if (payload.CategoryId) { formData.append('CategoryId', payload.CategoryId) }
    // if (payload.Quantity) { formData.append('Quantity', payload.Quantity) }
    // if (payload.Balance) { formData.append('Balance', payload.Balance) }
    // if (payload.ContractAddress) { formData.append('ContractAddress', payload.ContractAddress) }
    // if (payload.Status) { formData.append('Status', payload.Status) }
    // if (payload.HashValue) { formData.append('HashValue', payload.HashValue) }
    // if (payload.Type) { formData.append('Type', payload.Type) }
    // if (payload.MinimumBid) { formData.append('MinimumBid', payload.MinimumBid) }
    // if (payload.EndClocktime) { formData.append('EndClocktime', payload.EndClocktime) }
    // if (payload.Clocktime) { formData.append('Clocktime', payload.Clocktime) }
    // if (payload.UnLockcontent) { formData.append('UnLockcontent', payload.UnLockcontent) }

    // if (payload.PutOnSale) { formData.append('PutOnSale', payload.PutOnSale) }
    // if (payload.PutOnSaleType) { formData.append('PutOnSaleType', payload.PutOnSaleType) }
    // if (payload.swapPrice) { formData.append('swapPrice', payload.swapPrice) }
    // if (payload.CoinName) { formData.append('CoinName', payload.CoinName) }
    // if (payload.additionalImage) { formData.append('additionalImage', payload.additionalImage) }
    // if (payload.collection) { formData.append('collection', payload.collection) }
    // if (payload.SelectedNetwork) { formData.append('SelectedNetwork', payload.SelectedNetwork) }
    // if (payload.currencyCheck) { formData.append('currencyCheck', payload.currencyCheck) }
    // if (payload.decimal) { formData.append('decimal', payload.decimal) }
    // if (payload.action) { formData.append('action',payload.action) }
    // if (payload.Subcategory) { formData.append("Subcategory",payload.Subcategory) }
    // if (payload.ipfsarry) { formData.append("ipfsarry",payload.ipfsarry)}
    // if (payload.TermsandConditions) { formData.append("TermsandConditions",payload.TermsandConditions)}
    // if (payload.Randomname) { formData.append('Randomname', payload.Randomname) }
    // if (payload._nonce) { formData.append('_nonce', payload._nonce) }
    // if (payload.signature) { formData.append('signature', payload.signature) }
    // if (payload.PutOnSaleQuantity) { formData.append('PutOnSaleQuantity', payload.PutOnSaleQuantity) }
    // if (payload.ListNFT) { formData.append('ListNFT', payload.ListNFT) }
    // if (payload.ListedQuantity) { formData.append('ListedQuantity', payload.ListedQuantity) }
    // if (payload.SocialLinks) { formData.append('SocialLinks', payload.SocialLinks) }
    // if (payload.SelectedNetworkName) { formData.append('SelectedNetworkName', payload.SelectedNetworkName) }

    let formdata = AppenData(payload)

    let respData = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/add/bulkitem`,
      'headers': {
        'Content-Type': 'multipart/form-data'
      },
      data: formdata[0]
    });
    // //console.log("respData : " + JSON.stringify(respData.data))
    return { data: respData.data }
  }
  catch (err) {
    // //console.log('errrorrrrr',err)
    return { error: err }
  }
}







export const TokenAddOwnerAction = async (payload) => {
  console.log('payload', payload);
  try {
    var SendData = {}
    // if (payload.Count) { SendData.Count = payload.Count; }
    // if (payload.Price) { SendData.Price = payload.Price; }
    // if (payload.Owner) { SendData.Owner = payload.Owner; }

    // if (payload.tokenCreator) { SendData.tokenCreator = payload.tokenCreator; }
    // if (payload.Balance) { SendData.Balance = payload.Balance; }
    // if (payload.Quantity) { SendData.Quantity = payload.Quantity; }
    // if (payload.ContractAddress) { SendData.ContractAddress = payload.ContractAddress; }
    // if (payload.Type) { SendData.Type = payload.Type; }
    // if (payload.HashValue) { SendData.HashValue = payload.HashValue; }
    
    // if (payload.Status) { SendData.Status = payload.Status; }
    // // if(payload.previousPrice){ SendData.previousPrice = payload.previousPrice;}
    // if (payload.CoinName) { SendData.CoinName = payload.CoinName; }
    // if (payload.MinimumBid) { SendData.MinimumBid = payload.MinimumBid; }
    // if (payload.Clocktime) { SendData.Clocktime = payload.Clocktime; }
    // if (payload.EndClocktime) { SendData.EndClocktime = payload.EndClocktime; }
    // if (payload.collection) { SendData.collection = payload.collection; }
    // if (payload.SelectedNetwork) { SendData.SelectedNetwork = payload.SelectedNetwork; }
    // if (payload.decimal) { SendData.decimal = payload.decimal; }
    // if (payload.PutOnSaleType) { SendData.PutOnSaleType = payload.PutOnSaleType; }
    // if (payload.putonsalequantity) { SendData.putonsalequantity = payload.putonsalequantity }
    // if (payload.Randomname) { SendData.Randomname = payload.Randomname }
    // if (payload._nonce) { SendData._nonce = payload._nonce }
    // if (payload.signature) { SendData.signature = payload.signature }
    if(payload.action == "list" && !payload.HashValue) { payload.HashValue = ""; }
    if (payload.ListedQuantity || payload?.ListedQuantity == 0) { payload.ListedQuantity = payload.ListedQuantity }
    if (payload.ListNFT || payload.ListNFT === false) { payload.ListNFT = payload.ListNFT }
    

//PutOnSaleType

////console.log("nvfbkvfnjhfo" ,SendData);
    let resp1Data = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/add/owner`,

      data: payload
    });
    return { data: resp1Data.data }
  }
  catch (err) {
    ////////console..log('TokenAddOwnerAction err',err)
    return {
      //errors: err.response.data
    }
  }
}







export const TokenAddOwnerAction1 = async (payload) => {
  //console.log('payload', payload);
  try {
    var SendData = {}
    // if (payload.Count) { SendData.Count = payload.Count; }
    // if (payload.Price) { SendData.Price = payload.Price; }
    // if (payload.Owner) { SendData.Owner = payload.Owner; }

    // if (payload.tokenCreator) { SendData.tokenCreator = payload.tokenCreator; }
    // if (payload.Balance) { SendData.Balance = payload.Balance; }
    // if (payload.Quantity) { SendData.Quantity = payload.Quantity; }
    // if (payload.ContractAddress) { SendData.ContractAddress = payload.ContractAddress; }
    // if (payload.Type) { SendData.Type = payload.Type; }
    // if (payload.HashValue) { SendData.HashValue = payload.HashValue; }
    if(payload.action == "list" && !payload.HashValue) { SendData.HashValue = ""; }
    // if (payload.Status) { SendData.Status = payload.Status; }
    // if (payload.CoinName) { SendData.CoinName = payload.CoinName; }
    // if (payload.MinimumBid) { SendData.MinimumBid = payload.MinimumBid; }
    // if (payload.Clocktime) { SendData.Clocktime = payload.Clocktime; }
    // if (payload.EndClocktime) { SendData.EndClocktime = payload.EndClocktime; }
    // if (payload.collection) { SendData.collection = payload.collection; }
    // if (payload.SelectedNetwork) { SendData.SelectedNetwork = payload.SelectedNetwork; }
    // if (payload.decimal) { SendData.decimal = payload.decimal; }
    // if (payload.PutOnSaleType) { SendData.PutOnSaleType = payload.PutOnSaleType; }
    // if (payload.putonsalequantity) { SendData.putonsalequantity = payload.putonsalequantity }
    // if (payload.ipfsarry) { SendData.ipfsarry = JSON.stringify(payload.ipfsarry) }



//PutOnSaleType

////console.log("nvfbkvfnjhfo" ,SendData);
    let resp1Data = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/add/bulkowner`,

      data: payload
    });
    return { data: resp1Data.data }
  }
  catch (err) {
    ////////console..log('TokenAddOwnerAction err',err)
    return {
      //errors: err.response.data
    }
  }
}













export const CreateTokenValidationAction = async (payload) => {
  ////////console..log('payload', payload);
  try {
    let respData = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/add/item/validation`,
      data: payload
    });
    return {
      data: respData.data
    }
  }
  catch (err) {
  }
}

export const GetCategoryAction = handleCallback(async (payload) => {
  // //console.log("payloadcategory",payload);
    let respData = await axiosFunc({
      'method': 'get',
      'url': `${config.vUrl}/token/category/list`,
      params: payload
    });
    console.log('lkdklsahdfkasfa',respData)
    return respData
})

export const GetLikeDataAction = async (payload) => {
  try {
    let respData = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/like/list`,
      data: payload
    });
    return respData.data
  }
  catch (err) {
  }
}

export const AddLikeAction = async (payload) => {
  try {
    let resp = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/like`,
      data: payload
    });
    return resp
  }
  catch (err) {
  }
}

export const CollectiblesList_MyItems = async (payload) => {
  try {
    let resp = await axiosFunc({
      'method': 'get',
      'url': `${config.vUrl}/token/collectibles/list/myitems`,
      params: payload
    });
    return resp.data;
  }
  catch (err) {
  }
}

export const CollectiblesList_Home = async (payload) => {
  // //console.log("dfgvkjifadscsasgfbfdfgdhbgivjgf",payload);
  try {
    let resp = await axiosFunc({
      'method': 'get',
      'url': `${config.vUrl}/token/collectibles/list/home`,
      params: payload
    });
    return resp;
  }
  catch (err) {
  }
}
export const NewCollectiblesList_Home = async (payload) => {
  try {
    let resp = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/collectibles/list/newHome`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const CollectiblesList_Follow = async (payload) => {
  try {
    let resp = await axiosFunc({
      'method': 'get',
      'url': `${config.vUrl}/token/collectibles/list/follow`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export async function activityUpdate(data) {
  try {
    let checkAddr = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/test/activityUpdate`,
      'data': data
    })
    return {
      data: checkAddr.data
    }
  }
  catch (err) {
    return {
      // error: err.response.data
    }
  }
}

// convert
export const convertionValue = async (data) => {
  // //////console..log("datra" + JSON.stringify(data))
  try {
    let respData = await axios({
      'method': 'get',
      'url': 'https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD',

    });
    return {

      data: respData.data
    }
  }
  catch (err) {
    return {
      // error: err.response.data
    }
  }
}


export const topCreatorsApi = async (data) => {
  // //////console..log("datra" + JSON.stringify(data))
  try {
    let respData = await axiosFunc({
      'method': 'get',
      'url': `${config.vUrl}/token/home/topCreatorsApi`,

    });
    return {

      data: respData.data
    }
  }
  catch (err) {
    return {
      // error: err.response.data
    }
  }
}

//buyer seller

export const getBuyerSeller = async (payload) => {
  try {
    let resp = await axiosFunc({
      'method': 'get',
      'url': `${config.vUrl}/token/getBuyerSeller`,
      params: payload
    });
    return {
      data: resp.data
    }
  } catch (err) {
    ////console.log(err);
  }
}


export const ipfsImageHashGet = async (payload) => {
  // //////console..log("datra" + JSON.stringify(data))
  // var formData = new FormData();
  // if (payload.Image) { formData.append('Image', payload.Image); }
  // if (payload.name) { formData.append('name', payload.name); }
  // if (payload.desc) { formData.append('desc', payload.desc); }
  // if (payload.Creator) { formData.append('Creator', payload.Creator); }
  // if(payload.type) { formData.append('type', payload.type); }
  // if(payload.tokenCounts) { formData.append('tokenCounts', payload.tokenCounts); }
  // if(payload.contractAddress) { formData.append('contractAddress', payload.contractAddress); }
  var formdata = AppenData(payload);
  try {
    let respData = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/create/ipfsImageHashGet`,
      data: formdata[0],

    });
    
    return {
      data: respData.data
    }
  }
  catch (err) {
    return {
      // error: err.response.data
    }
  }
}


export const ipfsImageHashGetBulk = async (payload) => {
  var formData = new FormData();
  if (payload.desc) { formData.append('desc', EncryptData(payload.desc)); }
  if (payload.Creator) { formData.append('Creator', EncryptData(payload.Creator)); }
  if (payload.name) { formData.append('nftname', EncryptData(payload.name)); }
  if(payload.TokenQuantity) { formData.append('TokenQuantity',EncryptData(payload.TokenQuantity)) }
  if(payload?.Properties) {formData.append('Properties',EncryptData(payload?.Properties))}
  

  for(var i=0;i<payload.Image.length;i++)
  {
    formData.append('Image', payload.Image[i]);
    formData.append('name', EncryptData(payload.Image[i].name))
  }
  try {
    let respData = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/create/ipfsImageHashGetBulk`,
      data: formData,

    });
    return {
      data: respData.data
    }
  }
  catch (err) {
    return {
      // error: err.response.data
    }
  }
}

export const IPFSUploadFunc = async(payload) => {
  console.log('kldjgkdjgdgs',payload)
  try{
    var formData = new FormData();
    if (payload.desc) { formData.append('desc', EncryptData(payload.desc)); }
    if (payload.Creator) { formData.append('Creator', EncryptData(payload.Creator)); }
    if (payload.name) { formData.append('nftname', EncryptData(payload.name)); }
    if (payload.from) { formData.append('from', EncryptData(payload.from)); }
    if(payload?.Properties) {formData.append('Properties',EncryptData(payload?.Properties))}

    let  FileList = [];
    for (var i = 0; i < payload.File.length; i++) {
      formData.append('File', payload?.File?.[i]);
      formData.append('Thumbfile', payload?.Thumbfile?.[i]);
      formData.append('PreviewFile', payload?.PreviewFile?.[i]);
      FileList.push({
        File: payload?.File?.[i]?.name,
        Thumbfile: payload?.Thumbfile?.[i]?.name,
        PreviewFile: payload?.PreviewFile?.[i]?.name,
      })
    }
    formData.append('FileList', EncryptData(JSON.stringify(FileList)));
    let respData = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/create/ipfsfileupload`,
      'data': formData,
    });
    return {
      data: respData.data
    }
  }
  catch(err){
    console.log('IPFSUploadFunc error',err)
  }
}

export const BurnField = async (data) => {
  // ////////console..lo(data,"dataaaaaaaaaaaaaaaaaaaa")
  try {

    let respData = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/admin/panel/BurnField`,

      data: data
    });
    return {
      loading: false,

    }

  }
  catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    }
  }
}


export const null_time_auction = async (data) => {
  // ////////console..lo(data,"dataaaaaaaaaaaaaaaaaaaa")
  try {

    let respData = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/null_time_auction`,

      data: data
    });
    return {
      loading: false,

    }

  }
  catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    }
  }
}


export const activityAdd = async (data) => {
  // ////////console..lo(data,"dataaaaaaaaaaaaaaaaaaaa")
  try {

    let respData = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/activityAdd`,

      data: data
    });
    return {
      loading: false,

    }

  }
  catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    }
  }
}

export const getActivitys = async (data) => {
  // ////////console..lo(data,"dataaaaaaaaaaaaaaaaaaaa")
  try {

    let respData = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/getActivitys`,

      data: data
    });
    return {
      loading: false,

    }

  }
  catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    }
  }
}

export const toFixedFunc = async (x) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += (new Array(e + 1)).join('0');
    }
  }
  //////////console.log("DataChecking"+x)
  return x;

}

export const ipfsmetadatafunciton = async (payload) => {

  // var formData = new FormData();
  // // if(payload.Image) { formData.append('Image', payload.Image); }
  // if (payload.name) { formData.append('name', payload.name); }
  // if (payload.image) { formData.append('image', payload.image); }

  // if (payload.desc) { formData.append('desc', payload.desc); }
  // if(payload.description) { formData.append('description', payload.description); }
  
  var formdata = AppenData(payload);

  try {
    let respData = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/ipfsmetadata`,
      data: formdata[0],

    });
    return {
      data: respData.data
    }
  }
  catch (err) {
    return {
      // error: err.response.data
    }
  }
}

export const Transfer_Complete_Action = async (payload) => {
  try {
    let resp = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/tranfer/complete`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const checkOtherPlatformDetais1155 = async (item, itemCur, type, web3, data , tradecontract) => {
  try {
    //console.log("sdvfdscheckOthersgvdPlatformDetais1155vffsdvf",item,itemCur,type,web3)
    console.log('ldsdsldsdlsds',item,itemCur)
    var currentOwner = itemCur.tokenOwner;
    var tokenCounts = itemCur.tokenCounts;
    var contractAddress = itemCur.contractAddress
    var owner = null;
    var balance = null;
    //console.log("dfbgsdfbhfegnghn", itemCur, type,type === 1155)
    if(item?.hashValue=="notminted"){
      balance= item?.tokenowners_current?.balance;
    }
    else{
      if (type === 1155) {
        try{
        //console.log("fgnbhfgngfh",Multiple, typeof contractAddress);
  
        var CoursetroContract = new web3.eth.Contract(
          Multiple,
          contractAddress
          // data.Singleaddress
        )
        // var CoursetroContract = new web3.eth.Contract( Multiple, "0x20623a5E3152F525Ff996389935244ae308Aa44D" );
        //console.log("ghndghnfghn",contractAddress,tokenCounts, currentOwner);
  
  //        old
  
        // balance = await CoursetroContract.methods
        //   .balanceOf(
        //     currentOwner,
        //     (tokenCounts)
        //   ).call();
  
        
        let listbal = await tradecontract.methods
          ._listNFT(
            currentOwner,
            contractAddress,
            (tokenCounts)
          ).call();
          balance = await CoursetroContract.methods
          .balanceOf(
            currentOwner,
            (tokenCounts)
          ).call();
          console.log("dfbsdfbdfgbhdfgnhghnhjgn111", balance,listbal,owner,currentOwner,tokenCounts,contractAddress)
          balance = Number(listbal?._qty) + Number(balance);
          console.log("dfbsdfbdfgbhdfgnhghnhjgn", balance,listbal)
          }
          catch(err)
          {
            //console.log("dfgnbdgfnfghn",err);
          }
        }
      else if (type === 721) {
        let listbal = null;
        try{
        //console.log("DFHGBsdfhf",Single,contractAddress,tokenCounts);
        var CoursetroContract = new web3.eth.Contract(
          Single,
          contractAddress
          // data.Singleaddress
        )
  //console.log("fgbrgfnrhrhrh",CoursetroContract);
  
  
  
        owner = await CoursetroContract.methods.ownerOf(tokenCounts).call();

        listbal = await tradecontract.methods
          ._listNFT(
            currentOwner,
            contractAddress,
            tokenCounts,
          ).call();
        console.log("fdnhbsfgfnn", owner,listbal,)
          }catch(err)
          {
            console.log("sdgvsdvgs",err);
          }
  
  
          //      old
  
        // if ((String(owner).toLowerCase()) === (String(currentOwner).toLowerCase())) { return true; }
        console.log('ckhfkldhflskhkfs',owner,currentOwner,contractAddress,listbal,tradecontract,currentOwner,
        tokenCounts,)
        if((String(owner).toLowerCase()) == (String(currentOwner).toLowerCase())||((listbal?.contractAddress)?.toLowerCase()==contractAddress?.toLowerCase()&&listbal?._qty==1)) {
          return 1; 
        }
        else {
          balance = 0
        }
  
      }
      else if (type === 11551) {
        try{
        //console.log("fgnbhfgngfh",Multiple, typeof contractAddress);
  
        var CoursetroContract = new web3.eth.Contract(
          Literature,
          contractAddress
        )
  
        
        let listbal = await tradecontract.methods
          ._listNFT(
            currentOwner,
            contractAddress,
            (tokenCounts)
          ).call();
          balance = await CoursetroContract.methods
          .balanceOf(
            currentOwner,
            (tokenCounts)
          ).call();
          console.log("dfbsdfbdfgbhdfgnhghnhjgn111", balance,listbal,owner,currentOwner,tokenCounts,contractAddress)
          balance = Number(listbal?._qty) + Number(balance);
          console.log("dfbsdfbdfgbhdfgnhghnhjgn", balance,listbal)
          }
          catch(err)
          {
            //console.log("dfgnbdgfnfghn",err);
          }
        }
  
      if (balance !== null && balance !== undefined) {
        let payload = {
          currentOwner,
          owner,
          tokenCounts,
          balance:Number(balance),
          contractAddress
        }
        // await findAndUpdateBalance(payload);
      }
    }
    return balance;
  }
  catch (e) {
    console.log("ewurwe",e)
    return 0
  }
}
const findAndUpdateBalance = async (payload) => {
  //console.log('>>>>>>>>payload',payload);
  var data = await findAndUpdateBalanceAction(payload);
  if (data && data.data) {
    //console.log('>>>>>data', data.data.success);
  }
}

const findAndUpdateBalanceAction = async (payload) => {
  try {
    let resp = await axiosFunc({
      method: 'post',
      url: `${config.vUrl}/token/findAndUpdataBalance`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    ////console.log(">>>>fberror",err);
    return {
      data: [],
      error: 'Error : Oops something went to wrong'
    }
  }
}
export const getNFTDetails = async (payload) => {
  try {
    let resp = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/getOtherNft`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}
export const getNftCollectionData = async (data) => {
  try {
    let respData = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/getCollections`,
      data
    });
    return {
      respData
    }
  }
  catch (err) {
    return {
      loading: false,
      error: err
    }
  }
}
export const setTokenCounts_Get_Detail_Action = async (payload) => {
  try {
    let resp = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/settokenCounts`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const PurchaseNow_Complete_Action = async (payload) => {
  try {
    let resp = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/purchase/complete`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const PurchaseNow_Complete_Action_Meta = async (payload) => {
  try {
    let resp = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/updateusermeta`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}


export const InfopageRedirection = async (payload) => {
  // //console.log("inforedirect payload",payload)
  try {
    let resp = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/InfopageRedirection`,
      data: payload
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }
}

export const getUserCollectiondata = async (payload) => {
  // //console.log("getUserCollectiondata",payload)
  try {
    let resp = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/getUerAllCollection`,
      data: payload
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}



export const gallarycollection = async () => {

  try {
    let resp = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/HomeGallary`,
     
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}


export const Hotcollections = async (Arg) => {
  // //console.log("timeeeeee",Arg)
  try {
    let resp = await axiosFunc({
      'method': 'get',
      'url': `${config.vUrl}/token/Hotcollections`,
       params: Arg
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}

export const CollectionList = async (Arg) => {
  // //console.log("timeeesdcsdeee",Arg)
  try {
    let resp = await axiosFunc({
      'method': 'get',
      'url': `${config.vUrl}/token/CollectionList`,
       params: Arg
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}



export const Explorecollection = async () => {
  try {
    let resp = await axiosFunc({
      'method': 'get',
      'url': `${config.vUrl}/token/Explorecollection`,
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}


export const getAllCounts = async (payload) => {
  try {
    let resp = await axiosFunc({
      'method': 'get',
      'url': `${config.vUrl}/token/getAllNFTCounts`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}










// export const WenlamboConvert = async (data) => {
//   // //////console..log("datra" + JSON.stringify(data))
//   try {
//     let respData = await axios({
//       'method': 'get',
//       'url': `${config.vUrl}/token/test/wenlamboConvert`,
//     });
//     ////console.log("get val",respData);
//     return {

//       data: respData
//     }
//   }
//   catch (err) {
//     return {
//       // error: err.response.data
//     }
//   }
// }






export const WenlamboConvert = async (data) => {
  //console("datra" + JSON.stringify(data))
  var url = `https://min-api.cryptocompare.com/data/price?fsym=${data}&tsyms=USD`
  // var url = `https://api.pancakeswap.info/api/v2/tokens/${data}`
  try {
    let respData = await axios({
      'method': 'get',
        //  url: `https://api.pancakeswap.info/api/v2/tokens/${data}`,
      // url: `https://min-api.cryptocompare.com/data/${data}`,
        'url': url
    });
    //console("get val",respData);
    return {

      data: respData.data
    }
  }
  catch (err) {
    return {
      // error: err.response.data
    }
  }
}







export const getListOfToken = async (chainid) => {
  //console.log("Sbhadbnrh",chainid)
  try {
    let respData = await axiosFunc({
      'method': 'get',
      'url': `${config.vUrl}/token/tokenOption`,
      params: chainid
    });
    console.log("CheckToken", respData)
    return respData.data
  }
  catch (err) {
  }
}

export const networklist = async () => {
  try {
    let respData = await axiosFunc({
      'method': 'get',
      'url': `${config.vUrl}/token/networklist`,
    });
    return {
      data: respData
    }
    // return respData
  }
  catch (err) {
  }
}


export const saverating = async (payload) => {
  try {
    let respData = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/saverating`,
      "data":payload
    });
    //console("CheckToken", respData.data)
    return {
      data: respData
    }
  }
  catch (err) {
  }
}

export const getrating = async(payload)=>
{
  try {
    let respData = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/getrating`,
      "data":payload
    });
    //console("CheckToken", respData.data)
    return {
      data: respData
    }
  }
  catch (err) {
  }
}





export const likes = async(payload)=>
{
  try {
    let respData = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/likes`,
      "data":payload
    });
    //console("CheckToken", respData.data)
    return {
      data: respData
    }
  }
  catch (err) {
  }
}


export const validateNftQuantity = async(payload)=>
{
  try {

//console.log("dbsdfbhsdfgnbdfgn",payload);
var image = payload.files
let bodyFormData = new FormData();
bodyFormData.append(`curradd`, payload.curradd)

    if (image) {
      if (image.length > 0) {
        let bulkpatharr = [];
          for (var i = 0; i < image.length; i++) {
              //console.log("djnfksdnfksdnkds",image[i].webkitRelativePath);
              bodyFormData.append(`BulkImageFiles`, image[i])
              //  bodyFormData.append(`BulkImageFilesPath`, image[i].webkitRelativePath)
              bulkpatharr.push(image[i].webkitRelativePath);
          }
          bodyFormData.append(`BulkImageFilesPath`, EncryptData(JSON.stringify(bulkpatharr)))
      }
  }

    let respData = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/validateNftQuantity`,
      "data":bodyFormData
    });
    //console("CheckToken", respData.data)
    return {
      data: respData
    }
  }
  catch (err) {
    //console.log("fbhfgnfrnfrg",err);
  }
}






export const createNfts = async(payload)=>
{
  try {

//console.log("dbsdfbhsdfgnbdfgn",payload);
var image = payload.files
let bodyFormData = new FormData();
bodyFormData.append(`curradd`, EncryptData(payload.curradd))
bodyFormData.append(`TokenQuantity`, EncryptData(payload.TokenQuantity))

// if(image.length > 0)
//     {
//       for(var i = 0 ; i < image.length ; i++)
//       {
//       //console.log("gnhgfdnhfnhjf",`folder${i}`,image[i]);
//       formData.append(`folder${i}`,JSON.stringify(image[i]))
//       }
//     }

    if (image) {
      if (image.length > 0) {
        let bulkpatharr = [];
        for (var i = 0; i < image.length; i++) {
          // bodyFormData.append('File', payload.Filestate[0]);
          //console.log("djnfksdnfksdnkds",image[i].webkitRelativePath);
          bodyFormData.append(`BulkImageFiles`, image[i])
          bulkpatharr.push(image[i].webkitRelativePath);
          // bodyFormData.append(`BulkImageFilesPath`, EncryptData(image[i].webkitRelativePath))

              // webkitRelativePath
        }
        bodyFormData.append(`BulkImageFilesPath`, EncryptData(JSON.stringify(bulkpatharr)))
      }
  }





    //console.log("cbvdfbdfbd",bodyFormData);








    let respData = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/createNfts`,
      "data":bodyFormData
    });
    //console("CheckToken", respData.data)
    return {
      data: respData
    }
  }
  catch (err) {
    //console.log("fbhfgnfrnfrg",err);
  }
}

export const ExploreNewCollection = async (payload) => {
  try {
    let resp = await axiosFunc({
      'method': 'get',
      'url': `${config.vUrl}/token/explorenewcollection`,
      params: payload
    });
    return resp.data;
  }
  catch (err) {
  }
}


//        LazyMint

export const LazyMinting = async (payload) => {
    var formData = new FormData();
    Object.keys(payload).map((val)=>{
      if(typeof(payload?.[val])!='undefined'&&typeof(payload?.[val])!='object'){
        formData.append(val, EncryptData(payload?.[val]));
      }
    })
    // if (payload.Name) { formData.append('Name', payload.Name); }
    // if (payload.desc) { formData.append('Description', payload.Description); }
    // if (payload.Creator) { formData.append('Creator', payload.Creator); }
    // if (payload.name) { formData.append('nftname', payload.name); }
    // if(payload.TokenQuantity) { formData.append('TokenQuantity',payload.TokenQuantity) }
    
  console.log('sljfsjfklsfs',payload,payload?.File)
  let  FileList = [];
    for(var i=0;i<payload?.File?.length;i++)
    {
      formData.append('File', payload.File?.[i]);
      formData.append('name', EncryptData(payload.File?.[i].name))
      formData.append('Thumbfile', payload?.Thumbfile?.[i]);
      formData.append('PreviewFile', payload?.PreviewFile?.[i]);
      FileList.push({
        File: payload?.File?.[i]?.name,
        Thumbfile: payload?.Thumbfile?.[i]?.name,
        PreviewFile: payload?.PreviewFile?.[i]?.name,
      })
    }
    formData.append('FileList', EncryptData(JSON.stringify(FileList)));
    try {
      let respData = await axiosFunc({
        'method': 'post',
        'url': `${config.vUrl}/token/create/lazyminting`,
        data: formData,
  
      });
      return {
        data: respData.data
      }
    }
    catch (err) {
      return {
        // error: err.response.data
      }
    }
}

export const IpfsUpdate = async (data) => {
  try {
    // let formData = new FormData();
    // formData.append('tokenCounts',EncryptData(data?.tokenCounts))
    let respData = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/updateipfs`,
      'data': data
    });
    return respData.data
  }
  catch (err) {
    return {
      // error: err.response.data
    }
  }
}

export const LazyBuyAccept = async (data) => {
  try {
    // let formData = new FormData();
    // formData.append('tokenCounts',data?.tokenCounts)
    let respData = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/lazybuyaccept`,
      'data': data,

    });
    return {
      data: respData.data
    }
  }
  catch (err) {
    return {
      // error: err.response.data
    }
  }
}

export const ExcelDataGet = async (data) => {
  try {
    // let formData = new FormData();
    // formData.append('tokenCounts',data?.tokenCounts)
    let formdata = AppenData(data);
    let respData = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/excelget`,
      'headers': {
        'Content-Type': 'multipart/form-data'
      },
      'data': formdata[0],

    });
    return respData.data
  }
  catch (err) {
    return {
      // error: err.response.data
    }
  }
}

export const GetNFTOwnerInfo = async (data) => {
  try {
    // let formData = new FormData();
    // formData.append('tokenCounts',data?.tokenCounts)
    let respData = await axiosFunc({
      'method': 'get',
      'url': `${config.vUrl}/token/getownerinfo`,
      'params': data,
    });
    return respData.data
  }
  catch (err) {
    return {
      // error: err.response.data
    }
  }
}

export const GetVideo = async (data) => {
  try {
    // let formData = new FormData();
    // formData.append('tokenCounts',data?.tokenCounts)
    let formdata = AppenData(data);
    let respData = await axiosFunc({
      'method': 'post',
      'url': `${config.vUrl}/token/getvideo`,
      'headers': {
        'Content-Type': 'multipart/form-data'
      },
      'data': formdata[0],

    });
    console.log('lsjdklflsfs',respData)
    return respData.data
  }
  catch (err) {
    return {
      // error: err.response.data
    }
  }
}

export const BulkNFTNameValidation = handleCallback(async (data) => {
  let respData = await axiosFunc({
    'method': 'post',
    'url': `${config.vUrl}/token/bulknamevalidate`,
    'data': data,

  });
  return respData.data;
})
