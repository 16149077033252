import React, { useState , useEffect} from "react";
import register from "assets/images/registerbanner.jpg";
import Mail from "assets/images/registermail.png";
import Password from "assets/images/registerpass.png";
import User from "assets/images/registeruser.png";
import logo from "assets/images/logo.png";
import Button from "components/CustomButtons/Button.js";
import ListItem from "@material-ui/core/ListItem";
import Wallet from "../assets/images/walletconnect.png";
import { useSelector } from "react-redux";
import { RegisterpassCheck, submitdata } from "../actions/v1/user";
import isEmpty from "lib/isEmpty";
import { toast } from "react-toastify";
import config from "../lib/config";

import { Link, useHistory, useLocation } from "react-router-dom";


const initialFormValue = {
  UserName: "",
  Email: "",
  Password: "",
  RepeatPassword: "",
  Checkbox: false
};
toast.configure();
let toasterOption = config.toasterOption;

export default function Register() {

  const firebaseConfig = {
    apiKey: "AIzaSyANpsWMYwKFKJ-t5BI7WLMv0H4A6MA4FjA",
    authDomain: "nft-demo-22088.firebaseapp.com",
    projectId: "nft-demo-22088",
    // storageBucket: "your credentials",
    // messagingSenderId: "maticzhelper@gmail.com",
    //appId: "nft-demo-22088"
  };
  var history = useHistory();



  const [pass, setPass] = useState(true);
  const [confpass, setConfpass] = useState(true);
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [formValue, setformValue] = useState(initialFormValue);
  const [validate, setvalidate] = useState({});
  const location =useLocation()
  var [changepassword,setChangePassword] = useState(location);
  var [regstatus,setRegStatus] = useState("registration")
  var [Checkbox,setCheckbox] = useState(false);
  var [verify,setVerify] = useState(false);

  const onInputchange = (e) => {
    setvalidate({});
    //console.log("bgdfbhgda");
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setformValue(formData);
    //console.log("event", formData);
  };

  const submit = async () => {
    // const actionCodeSettings = {
    //   // URL you want to redirect back to. The domain (www.example.com) for this
    //   // URL must be in the authorized domains list in the Firebase Console.
    //   url: 'http://nftdemo.bimaticz.com/EdaFace/',
    //   // This must be true.
    //   handleCodeInApp: true,
    //   iOS: {
    //     bundleId: 'com.example.ios'
    //   },
    //   android: {
    //     packageName: 'com.example.android',
    //     installApp: true,
    //     minimumVersion: '12'
    //   },
    //   dynamicLinkDomain: 'https://nftcheck.bimaticz.com/edaface'
    // };
    // //console.log("sfgnhbgfsdnhgd",auth, "soniamaticz@gmail.com", actionCodeSettings);
    // sendSignInLinkToEmail(auth, "soniamaticz@gmail.com", actionCodeSettings)
    // .then((data) => {
    //   // The link was successfully sent. Inform the user.
    //   // Save the email locally so you don't need to ask the user for it again
    //   // if they open the link on the same device.
    //   //console.log("XFgndfgndgfh",data);
    //   window.localStorage.setItem('emailForSignIn', formValue.Email);
    //   // ...
    // })
    // .catch((error) => {
    //   const errorCode = error.code;
    //   const errorMessage = error.message;
    //   //console.log("FGnfgnjdfhgnjdhgfn",errorCode,errorMessage);
    //   // ...
    // });

    //console.log("formValue", formValue);
    //data.message.data.message
    if(formValue.Checkbox === false){
      setvalidate({Checkbox:"Please Fill the Checkbox"})
    }
    else{
      var data = await RegisterpassCheck(formValue);
      if (data.error) {
        var validation = data.error.response.data;
        setvalidate(validation);
      } else if (data.message) {
        localStorage.setItem("registered", true);
        toast.success("registered successfully", toasterOption);
        setRegStatus('confirm')
      }
    }
  };

  useEffect(()=>{
    ChangePasswordCheck();
},[location])

var ChangePasswordCheck = async() =>{
  if(changepassword.pathname.split('/')[1] == 'resetpassword'){
    window.$("#login").modal("show");
  }
  else if (changepassword.pathname.split('/')[1] == 'verify'){
    localStorage.setItem("logverify",changepassword.pathname.split('/')[2])
    var data = {from:"verify",key:changepassword.pathname.split('/')[2]}
    var logverify = await submitdata(data)
    if(logverify.message.data === true){
      setRegStatus('verified')
    }
    else if(logverify.message.data.message === 'Already verified'){
      history.push('/login')
      window.location.reload()
    }
    else{
      history.push('/Register')
      window.location.reload()
    }
  }
  else if(location.pathname.split('/')[1] == 'login'){
    window.$("#login").modal("show");
  }
}

  return (
    <>
      <div className="registerpage">
        <div className="logo_ires">

        
      <Link to="/">
          {" "}
          <img src={logo} className="logo_span" />
        </Link>

        <div className="regicenter container">
       
          <div className="user signupBx">
            <div className="formBx">
            { regstatus == 'registration' ?
              <form>
                <h2>Create an account</h2>
                <input
                  type="text"
                  id="UserName"
                  name=""
                  placeholder="Username"
                  onChange={(e) => onInputchange(e)}
                />
                {validate?.UserName && (
                  <span className="text-danger">{validate.UserName}</span>
                )}
                <input
                  type="email"
                  id="Email"
                  name=""
                  placeholder="Email Address"
                  onChange={onInputchange}
                />
                {
                  <span className="text-danger">
                    {validate && validate.Email
                      ? validate.Email
                      : validate.user}
                  </span>
                }
                <div className="passowrdd">
                  <input
                    type={pass ? "password" : "text"}
                    id="Password"
                    name=""
                    autoCorrect="off"
                    placeholder="Create Password"
                    onChange={onInputchange}
                  />
                  {validate?.Password && (
                    <span className="text-danger">{validate.Password}</span>
                  )}
                  <span className="faeyee">
                    <i
                      className={pass ? "fas fa-eye-slash" : "fas fa-eye"}
                      type="button"
                      id="eye"
                      onClick={() => setPass(!pass)}
                    ></i>
                  </span>
                </div>

                <div className="passowrdd">
                  <input
                    type={confpass ? "password" : "text"}
                    id="RepeatPassword"
                    name=""
                    autoCorrect="off"
                    placeholder="Confirm Password"
                    onChange={onInputchange}
                  />
                  {validate?.RepeatPassword && (
                    <span className="text-danger">
                      {validate.RepeatPassword}
                    </span>
                  )}
                  <span className="faeyee">
                    <i
                      className={confpass ? "fas fa-eye-slash" : "fas fa-eye"}
                      type="button"
                      id="eye"
                      onClick={() =>
                        setConfpass(confpass == true ? false : true)
                      }
                    ></i>
                  </span>
                </div>
                <div className="passowrdd click_button">
                <input type="checkbox" id="aggree" required checked={formValue.Checkbox} onChange={()=>{setformValue({...formValue, ...{ "Checkbox": !formValue.value }})}}/>
                <label for="aggree">
                  I agree to the <a href="https://www.edaface.com/terms" target="_blank" className="ml-1"> Terms and Conditions</a>, <a href="https://www.edaface.com/privacy" target="_blank" className="ml-1">Privacy Policy</a>, and <a href="https://www.edaface.com/tc" target="_blank" className="ml-1">all legal requirements of EDA</a>.
                </label>
                </div>
                {validate?.Checkbox && (
                    <span className="text-danger">{validate.Checkbox}</span>
                  )}
                <button
                  type="button"
                  href="/Register"
                  className="reglogintbn"
                  onClick={() => submit()}
                >
                  Register
                </button>
                <p className="signup">
                  Already have an account ?
                  <a
                    className="Login_det"
                    data-toggle="modal"
                    data-target="#login"
                  >
                    Sign in.
                  </a>
                </p>
                <h4 className="edafacecopyrights text-center">
          © {new Date().getFullYear()} EdaFace NFT. All Rights Reserved.
        </h4>
              </form>
              :
              (regstatus == "verified" ? <form>
                <h2 className="thank_your congrrats">Congratulations!</h2>
                <p className="signup text-left">
                You've successfully verified your email address and your account is now active.
                </p>
              
                  <a
                    className="reglogintbn"
                    data-toggle="modal"
                    data-target="#login"
                  >
                    Sign in.
                  </a>
                </form>
                :
              <form>
                <h2 className="thank_your">Thank you!</h2>
                <p className="signup text-left">
                  Your sign-up process is almost done.
                </p>
                <p className="email_ids">Please check your email and verify your account.</p>

                <p className="email_ids">If you can not find your confirmation email in your normal inbox, it is worth checking in your spam or junk mail folder.</p>
              
                  <a
                    className="reglogintbn"
                    data-toggle="modal"
                    data-target="#login"
                  >
                    Sign in.
                  </a>
                </form> )
            }
            </div>
          </div>
        </div>
        </div>
       

        <div class="wave"></div>
        <div class="wave"></div>
      </div>
    </>
  );
}
