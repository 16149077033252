import React, {
    useEffect,
    forwardRef,
    useState,
    useImperativeHandle
} from 'react';
import {
    useHistory, useLocation
} from "react-router-dom";
import { Button } from '@material-ui/core';
import video from 'video.js';
// import $ from 'jquery';
import Web3 from 'web3';
import '@metamask/legacy-web3'

// import EXCHANGE from '../../ABI/EXCHANGE.json'
// import Audios from 'assets/images/audio.png'
import config from '../../lib/config';
import Select from 'react-select';
import isEmpty from 'lib/isEmpty';
import Trade from '../../ABI/Trade.json'
import {
    TokenPriceChange_update_Action,
    checkOtherPlatformDetais1155
} from '../../actions/v1/token';
import SINGLE from '../../ABI/SINGLE.json';
import MULTIPLE from '../../ABI/MULTIPLE.json'
// import {
//     getCurAddr,
// } from '../../actions/v1/user';
import { getReceipt } from 'actions/v1/getReceiptFunc';
import Convert1 from '../separate/Convert1'
// import ReactPlayer from 'react-player';
import Convert from '../separate/Convert'
import { toast } from 'react-toastify';

import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { connectWallet, WalletConnect } from '../../views/hooks/useWallet';
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import { useSelector, useDispatch } from "react-redux";
import { network } from "../../views/network"
import Market from "../../ABI/market"
import randomInteger from 'random-int';
import { NewGetGasFees } from 'actions/contractProvider';
import { GetServiceFee } from 'actions/contractProvider';
import LITERATURE from '../../ABI/Literature.json'


toast.configure();
let toasterOption = config.toasterOption;

const exchangeAddress = config.exchangeAddress;

export const WithDrawRef = forwardRef((props, ref) => {

    const history = useHistory();
    const dispatch = useDispatch();


    const Wallet_Details = useSelector(state => state.wallet_connect_context);

    var pathVal = '';
    const location = useLocation();
    if (location.pathname) {
        if (location.pathname.split('/').length >= 2) {
            pathVal = location.pathname.split('/')[1];
        }
    }
    const [location_pathname, Set_location_pathname] = useState(pathVal);
    var CollectibleType_val = (location_pathname === 'create-single') ? config.singleType : config.multipleType;
    // const [BuyerName, Set_BuyerName] = React.useState('');
    // const [blns, Set_blns] = React.useState('');
    // const [dethBln, Set_dethBln] = React.useState('');
    // const [bidProfile1, Set_bidProfile1] = React.useState([]);

    const [FormSubmitLoading, Set_FormSubmitLoading] = useState('start');
    const [CoinName, setCoinNames] = useState('');
    const [CoinName_Initial, set_CoinName_Initial] = useState('');
    const [Items, Set_Items] = useState({});

    const [YouWillGet, Set_YouWillGet] = useState(0);
    const [owner_Detail, set_owner_Detail] = useState({})
    ////////console.log('props.Items.tokenPrice', props.item);

    const [TokenPrice, Set_TokenPrice] = useState(0);
    const [TokenPrice_Initial, Set_TokenPrice_Initial] = React.useState(0);
    // console.log("TokenPrice_Initial",TokenPrice_Initial);
    const [token_onwers_info, set_token_onwers_info] = useState({})
    const [ApproveCallStatus, setApproveCallStatus] = React.useState('init');
    const [MintCallStatus, setMintCallStatus] = React.useState('init');
    // const [approvedata, setApprovedata] = React.useState(false);

    const [PutOnSale, setPutOnSale] = useState(true);
    const [PutOnSaleType, setPutOnSaleType] = useState('');

    const [TokenBid, setTokenBid] = useState(true);


    // const [UnLockcontent, Set_UnLockcontent] = useState("");
    const [Unlockoncepurchased, Set_Unlockoncepurchased] = useState(false);


    // const [TokenQuantity, Set_TokenQuantity] = useState(1);

    const [MinimumBid, Set_MinimumBid] = useState(0);
    const [Clocktime, set_Clocktime] = useState('');
    const [EndClocktime, set_EndClocktime] = useState("");

    // const [convertVal, setConvertVal] = React.useState(0);

    const [StartDate, Set_StartDate] = useState('Select Start Date');
    const [EndDate, Set_EndDate] = useState('Select End Date');

    
    const [stateforOwnerdetails, SetstateforOwnerdetails] = useState({})


    // {console.log("dfbgdfbhgdfbdgfb",ListedQuantity,stateforOwnerdetails)}
    //   const [ValidateError, setValidateError] = useState({});
    const [ValidateError, Set_ValidateError] = useState({ TokenPrice: '' });

    const [priceoption, Set_priceoption] = useState({ TokenPrice: '' });


    const [WithdrawQuantity, SetWithdrawQuantity] = useState(1);
    const [ListNFT,SetListNFT] = useState(false);
    const [ListedQuantity,SetListedQuantity] = useState(1);
    const [currentFees,SetCurrentFees] = useState({})


console.log('dsdjfkjshgjlsjs',Items,owner_Detail)

    const ItemValidation = async (data = {}) => {
        var ValidateError = {};
        
        // console.log("fsnjgfdh",CoinName_Initial,CoinName,CoinName_Initial1,isEmpty(CoinName));      
        console.log("dfcdswfvs",data,TokenPrice);
        
console.log("gdhmfjhgjk,mkgfhj,gj",PutOnSale === true && PutOnSaleType === 'TimedAuction',MinimumBid);
        if(Number(WithdrawQuantity) <= 0){
            ValidateError.WithdrawQuantity = "Quantity Must not be empty";
        }
        if(Number(WithdrawQuantity) > Number(ListedQuantity)){
            ValidateError.WithdrawQuantity = "Quantity Must be less than or equal to "+ListedQuantity;
        }
        if(WithdrawQuantity%1 !== 0){
            ValidateError.WithdrawQuantity = "Quantity must be a Whole number"
        }
// console.log("gfhbndsftjn",ValidateError);
        Set_ValidateError(ValidateError);
        return ValidateError;
    }

    async function FormSubmit() {
        // console.log("Dnjfgnfgn");
        Set_FormSubmitLoading('start');
        // console.log("dfgsdth", ValidateError);
        var errors = await ItemValidation();
            // console.log("dfgsdth",isEmpty(ValidateError),ValidateError,ValidateError!=null,ValidateError!={});
            var errorsSize = Object.keys(errors).length;
            if (errorsSize !== 0 ||isEmpty(ValidateError)===false) {
                // console.log("hjsdbfjsdh",errors);
                Set_FormSubmitLoading('error');
                toast.error("Form validation error. Fix mistakes and submit again", toasterOption);
                return false;
            }
            var web3 = new Web3(Wallet_Details.providerss)
            if (
                web3
            ) {
                var receipt = null;
                var handle = null;
                var signature = null;
                var _nonce = null;
                var Randomname = null;
                var CoursetroContract = new web3.eth.Contract(
                    Market,
                    Wallet_Details.networkConfiguration.TradeContract
                );
                console.log("CDFBNDFgnfgn", Items.tokenCounts,
                    web3.utils.toWei(String(TokenPrice)),
                    owner_Detail.contractAddress,
                    owner_Detail.type);
                try {
                    Set_FormSubmitLoading('processing');

                    console.log("qweqweqwewqeqweqwezxccvc", WithdrawQuantity,ListedQuantity,item);
                    if(item?.hashValue == 'notminted'){
                        receipt={blockHash:'testwithdraw',transactionHash:'testwithdraw'}
                    }
                    else{
                        let encoded = await CoursetroContract.methods
                        .withdrawNFT(
                            Items.tokenCounts,
                            owner_Detail.contractAddress,
                            owner_Detail.type,
                            WithdrawQuantity,
                            currentFees?.key,
                        );
                        var gasfeecalculated = await NewGetGasFees({ web3: web3, contractcall: encoded, accountAddress: owner_Detail.tokenOwner, value: (currentFees?.Withdraw_fee?currentFees?.Withdraw_fee:Wallet_Details?.Withdraw_fee) });

                        await CoursetroContract.methods
                        .withdrawNFT(
                            Items.tokenCounts,
                            owner_Detail.contractAddress,
                            owner_Detail.type,
                            WithdrawQuantity,
                            currentFees?.key,
                            // 'order'

                        )
                        .send({
                            from: Wallet_Details.UserAccountAddr,
                            value: (currentFees?.Withdraw_fee?currentFees?.Withdraw_fee:Wallet_Details?.Withdraw_fee),
                            // gasLimit: parseInt(gasfeecalculated?.gasdata * 1.5, 10),
                            // gasPrice: gasfeecalculated?.gasPrice,
                        })
                        .on('transactionHash', async (transactionHash) => {
                            handle = setInterval(async () => {
                                receipt = await getReceipt(web3, transactionHash)
                                if(receipt){
                                    clearInterval(handle);
                                }
                            }, 1000)
                        })
                    }
                }

                catch (error) {
                    console.log('withdrawNFT errorr',error)
                    Set_FormSubmitLoading('try');
                    toast.error('Order not placed', toasterOption)
                    return false;
                }

                Set_FormSubmitLoading('done');
                var postData = {
                    tokenOwner: Wallet_Details.UserAccountAddr,
                    tokenCounts: Items.tokenCounts,
                    tokenPrice: TokenPrice,
                    blockHash: receipt?.blockHash,
                    transactionHash: receipt?.transactionHash,
                    owner: owner_Detail.tokenOwner,
                    CoinName: CoinName_Initial,
                    MinimumBid: 0,
                    Clocktime: null,
                    EndClocktime: null,
                    PutOnSaleType: PutOnSaleType,
                    ListedQuantity: (Number(ListedQuantity) - Number(WithdrawQuantity)),
                    putonsalequantity: (Number(ListedQuantity) - Number(WithdrawQuantity)),
                    ListNFT: Number(WithdrawQuantity) == Number(ListedQuantity) ? false : true,
                    activity: 'withdraw',
                }
                if (WithdrawQuantity == ListedQuantity) {
                    postData.Randomname = "";
                    postData._nonce = "";
                    postData.signature = "";
                }
                // console.log("sfgdgvdfgvfd", postData);
                var Resp = await TokenPriceChange_update_Action(postData)
                // console.log("dfghjsfgj",Resp);
                if (Resp && Resp.data && Resp.data.RetType && Resp.data.RetType === 'success') {
                    toast.success("Collectible price changed successfully", toasterOption)
                    window.$('#Withdraw_modal').modal('hide');
                    setTimeout(() => {
                        props.GetUpdatas();
                        // history.push("/my-items")
                        // history.push('/');
                    }, 1000);
                }
        }
        else {


            var postData = {
                tokenOwner: Wallet_Details.UserAccountAddr,
                tokenCounts: Items.tokenCounts,
                tokenPrice: TokenPrice,
                owner: owner_Detail.tokenOwner,
                CoinName: CoinName_Initial,
                MinimumBid: MinimumBid,
                Clocktime: Clocktime,
                EndClocktime: EndClocktime,
                PutOnSaleType: PutOnSaleType,

            }


            var Resp = await TokenPriceChange_update_Action(postData)
            if (Resp && Resp.data && Resp.data.RetType && Resp.data.RetType === 'success') {
                toast.success("Collectible price changed successfully", toasterOption)
                window.$('#Withdraw_modal').modal('hide');
                Set_FormSubmitLoading('done');
                setTimeout(() => {
                    props.GetUpdatas();
                    // history.push("/my-items")
                    // history.push('/');
                }, 1000);
            }



        }
    }

    var {
        item,
        UserAccountAddr,
        UserAccountBal,
        Service_Fee
    } = props;





    useImperativeHandle(
        ref,
        () => ({
            async WithDraw_Click(item, tokenOwnerInfo, ownerdetail) {
                if (Wallet_Details.UserAccountAddr !== "" || Wallet_Details.UserAccountAddr !== null ) {

                setPutOnSaleType(ownerdetail.PutOnSaleType)

                var chainid = item.tokenowners_current[0].SelectedNetwork || item?.tokenowners_current?.SelectedNetwork

                var accountDetailsss = ''
                // console.log("dfbhsfdbn", item, Wallet_Details.networkConfiguration.Chainid, item.tokenowners_current[0].SelectedNetwork);

                if (Wallet_Details.networkConfiguration.Chainid == item.tokenowners_current[0].SelectedNetwork) {
                    accountDetailsss = true
                }
                else {
                    // console.log("item.tokenowners_current[0]", item.tokenowners_current[0].SelectedNetwork);
                    if (item?.tokenowners_current[0]?.SelectedNetwork) {
                        var chainid = item.tokenowners_current[0].SelectedNetwork || item?.tokenowners_current?.SelectedNetwork
                    }
                    // console.log("sbhgsdrbhg", chainid);
                    var accountDetailsss = await switchNetwork(chainid)
                }

                // setTimeout(async() => {

                if (chainid === Wallet_Details.networkConfiguration.Chainid) {
                    // console.log("putonsale",item,ownerdetail,tokenOwnerInfo)
                    SetstateforOwnerdetails(ownerdetail)
                        var web3 = new Web3(Wallet_Details.providerss)
                        var data = {
                            Singleaddress: Wallet_Details.networkConfiguration.singleContract,
                            multipleContract: Wallet_Details.networkConfiguration.multipleContract
                        }
                        let CoursetroContract = new web3.eth.Contract(
                            Market,
                            Wallet_Details.networkConfiguration.TradeContract,
                        )
                        var balance = await checkOtherPlatformDetais1155(item, ownerdetail, item.type, web3, data, CoursetroContract);
                        // console.log('scbalance>>zdsvx>>>>>sc>>', balance)
                        if (balance == 0) {
                            toast.warning("You won't buy at this moment please refresh you data", toasterOption);
                            setTimeout(() => {
                                // window.location.href="/"
                            }, 1000);
                            return false;
                        }
                        else {

                            // if (String(item.contractAddress).toLowerCase() === String(Wallet_Details.networkConfiguration.singleContract).toLowerCase() ||
                            //     String(item.contractAddress).toLowerCase() === String(Wallet_Details.networkConfiguration.multipleContract).toLowerCase() 
                             
                            // ) {

                                var check = await orderApprovecheck(item, ownerdetail);
                                // console.log("dfbdfsb",check,ownerdetail);
                                if (check) {
                                    //console.log("Checking status",check)
                                    window.$('#Withdraw_modal').modal('show');

                                    setApproveCallStatus('done')
                                }
                                else {
                                    window.$('#price_item_modal1').modal('show');
                                }

                            //}
                            // else {
                            //     var check = await orderApprovecheck(item, ownerdetail);
                            //     if (check) {
                            //         //console.log("Checking status",check)
                            //         setApproveCallStatus('done')
                            //     }
                            //     // console.log("Checking status",check)
                            //     window.$('#price_item_modal').modal('show');
                            // }
                            props.Set_HitItem(item);
                            Set_Items(item)
                            set_owner_Detail(ownerdetail)
                            // Set_TokenPrice(ownerdetail.tokenPrice);
                            Set_TokenPrice_Initial(ownerdetail.tokenPrice);
                            set_CoinName_Initial(ownerdetail.CoinName)
                            setCoinNames(ownerdetail.CoinName)
                            if (!isEmpty(tokenOwnerInfo)) {
                                set_token_onwers_info(tokenOwnerInfo)
                            }
                            else {
                                set_token_onwers_info({})
                            }
                            Set_ValidateError({});
                            SetListedQuantity(ownerdetail.ListedQuantity);
                            SetListNFT(ownerdetail.ListedQuantity)

                            let CoursetroContract = new web3.eth.Contract(
                                Market,
                                Wallet_Details.networkConfiguration.TradeContract,
                            )
                            let CurrServiceFee = await GetServiceFee( item?.ServiceType, CoursetroContract )
                            console.log('slkfklsjflksfs',CurrServiceFee)
                            SetCurrentFees(CurrServiceFee)
                        }
                    }
                    else {
                        if(Wallet_Details.UserAccountAddr==="")
                        {
                        window.$('#connect_modal').modal('show');
                        }
                    }

                }


                // }, 1000);
            }
        }),
    )





    async function switchNetwork(configdata) {
        var type = ""
        var networkConfiguration = {}
        if (configdata) {
            if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

                type = "MetaMask"
            }
            else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
                type = "WalletConnect"
            }

            // console.log("connecttype............", type)
            var accountDetails = await connectWallet(type, configdata)
            // console.log("accountDetailsin create page......................", accountDetails)
            //  const id=toast.loading("Wallet Connecting...")
            var web3 = new Web3(window.ethereum);
            // console.log("dfghrtfh", web3);
            if (window.ethereum.isMetaMask == true) {
                const chainId = await web3.eth.getChainId();
                // console.log("fghdtgj", chainId);

                if (chainId === network.ETH.Chainid) {
                    networkConfiguration = network.ETH
                }
                else if (chainId === network.BSC.Chainid) {
                    networkConfiguration = network.BSC
                }


            }
            // console.log("networkConfiguration", networkConfiguration);
            // setPriceoption()

            if (accountDetails != '') {
                // console.log("Wallet Connecting...increate", accountDetails.web3._provider);
                dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                        UserAccountAddr: accountDetails.accountAddress,
                        UserAccountBal: accountDetails.coinBalance,
                        WalletConnected: "true",
                        Wen_Bln: accountDetails.tokenBalance,
                        Accounts: accountDetails.accountAddress,
                        providerss: accountDetails.web3._provider,
                        networkConfiguration: networkConfiguration
                    }
                })
            }
        }



        //   var ReqData = {
        //     addr: String(accountDetails.accountAddress).toLowerCase()
        // }
        // var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
        // if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
        //     return Resp.data.data.User
        // } else {
        //     return null
        // }





        return accountDetails
    }

    async function orderApprovecheck(item, tokenOwnerInfo) {
        if (Wallet_Details.providerss == null) {
            toast.warning("OOPS!..connect Your Wallet", toasterOption);
            return false;
        }
        var web3 = new Web3(Wallet_Details.providerss);
        try {

            //   console.log("OrderApprove Check", item,tokenOwnerInfo);
            var MultiContract = new web3.eth.Contract(
                (item.type === 721 ? SINGLE : MULTIPLE),
                item.contractAddress
            );
            var status = await MultiContract.methods.isApprovedForAll(
                tokenOwnerInfo.tokenOwner,
                Wallet_Details.networkConfiguration.TradeContract
            ).call();
            return status;
        }
        catch (e) {
            // console.log("OrderApprove Check", e);
            return false

        }
    }
    async function ApproveCall() {
        var receiptt = null;
        var handlee = null;
        if (Wallet_Details.providerss == null) {
            toast.warning("OOPS!..connect Your Wallet", toasterOption);
            return false;
        }
        var web3 = new Web3(Wallet_Details.providerss);
        var currAddr = Wallet_Details.UserAccountAddr

        if (currAddr === "") {
            toast.warning("OOPS!..connect Your Wallet", toasterOption);
            return false;
        }
        setApproveCallStatus('processing');
        try {
            var MultiContract = new web3.eth.Contract(
                (Items.type === 721 ? SINGLE : (Items.type == 1155 ? MULTIPLE : LITERATURE )),
                Items.contractAddress,
            );
            // console.log("dfbhadfbhadfsbh", MultiContract, Wallet_Details.networkConfiguration.TradeContract, owner_Detail.tokenOwner);
            await MultiContract.methods.setApprovalForAll(
                Wallet_Details.networkConfiguration.TradeContract,
                true
            ).send({
                from: owner_Detail.tokenOwner,
            }).on('transactionHash', async (transactionHash) => {
                if (transactionHash !== "") {
                    handlee = setInterval(async () => {
                        receiptt = await getReceipt(web3, transactionHash)
                        clr();
                    }, 8000)
                }
            })
        }
        catch (error) {
            console.log("FSnhbsfgnsfh", error);
            toast.error("Approve failed", toasterOption);
            setApproveCallStatus('tryagain');
        }
        async function clr() {
            if (receiptt != null) {
                clearInterval(handlee)
                toast.success("Approve Successfully", toasterOption);
                setApproveCallStatus('done');
                window.$('#price_item_modal1').modal('hide');
                window.$('#Withdraw_modal').modal('show');
            }
        }
    }


    async function priceListFunction() {
        //console.log("POPup starting")
        window.$('#price_item_modal').modal('hide');
        window.$('#Withdraw_modal').modal('show');
    }

    const SettoInitalValueFuc = () => {
        Set_FormSubmitLoading('start');
        Set_Items({});
        set_owner_Detail({});
        setPutOnSaleType('');
        Set_ValidateError({ TokenPrice: '' });
        SetWithdrawQuantity(1);
        SetListedQuantity(1);
        setApproveCallStatus("init")
    }

    return (
        <>

            <div className="modal fade primary_modal" id="Withdraw_modal" tabIndex="-1" role="dialog" data-bs-backdrop="static" data-keyboard="false" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true"   >
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="accept_modalLabel">Withdraw NFT</h5>
                            <p className="text-gray font_we_600 font_12">You are going to Withdraw your NFT
                                
                                to
                                <span className="buy_desc_sm_bold pl-1 bold_red owner_break" styel={{ fontSize: 10 }}>
                                    {/* {
                                        
                                            <span className="word_brak_text_inline_new">owner <span>Quantity 0</span></span>
                                            
                                    } */}
                                    {
                                        token_onwers_info
                                        && (token_onwers_info)
                                        && (token_onwers_info.name !== "" ?
                                            <span >{token_onwers_info.name}</span>
                                            :
                                            <span className="word_brak_text_inline_new" title={"Owner : " + owner_Detail.tokenOwner}>{String(owner_Detail.tokenOwner).slice(0, 5).concat('...')}</span>)

                                    }
                                </span>
                            </p>
                            <div className="row pb-3">
                                    <div className="col-12 col-sm-6">
                                        <p className="buy_desc_sm">Listed NFT's</p>
                                    </div>
                                    <div className="col-12 col-sm-6 text-sm-right">
                                        <p className="buy_desc_sm_bold">{owner_Detail?.ListedQuantity}</p>
                                    </div>
                                </div>
                            

                            <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close" id="close9" onClick={()=>SettoInitalValueFuc()}>

                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body px-0 pt-0">
                            <form className="px-4 bid_form">
                                <div className="row mx-0 mt-3 fixed_price_sec">

                                    <label className="primary_label" htmlFor="price_new">Withdraw Quantity</label>

                                    <div className="quantity_wirh">

                                        <input type="text"
                                            // value={collectionName}
                                            className="form-control primary_inp"
                                            id="WithdrawQuantity"
                                            //    value ={putonsalequantity}
                                            placeholder="Enter your quantity"
                                            onChange={(e)=>{SetWithdrawQuantity(e.target.value);Set_FormSubmitLoading("start")}}
                                            value={WithdrawQuantity}
                                            disabled={Number(item.type) === 721}
                                        />
                                        {ValidateError.WithdrawQuantity && <span className="text-danger">{ValidateError.WithdrawQuantity}</span>}

                                    </div>
                                </div>

                                <div className="row pb-3">
                                    <div className="col-12 col-sm-6">
                                        <p className="buy_desc_sm">Withdraw fee</p>
                                    </div>
                                    <div className="col-12 col-sm-6 text-sm-right">
                                        <p className="buy_desc_sm_bold">{(currentFees?.Withdraw_fee?currentFees?.Withdraw_fee:Wallet_Details?.Withdraw_fee) / config.decimalvalues} <span>{Wallet_Details?.tokenAddress?.length > 0 && 
                                        Wallet_Details?.tokenAddress?.map((val)=>{
                                            return(
                                                <>{val?.Address?.includes('000000000000') && val.value}</>
                                            )
                                        })
                                        }</span></p>
                                    </div>
                                </div>

                                <div className="text-center">

                                    <Button
                                        className="create_btn btn-block"
                                        type="button"
                                        disabled={(FormSubmitLoading === 'processing' || FormSubmitLoading === 'done' || FormSubmitLoading === 'init' || FormSubmitLoading === 'error')}
                                        onClick={(FormSubmitLoading === 'start' || FormSubmitLoading === 'try') ? (() => FormSubmit()) : ''}

                                    >
                                        {FormSubmitLoading === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                        {FormSubmitLoading === 'processing' && 'In-Progress'}
                                        {FormSubmitLoading === 'init' && 'Start'}
                                        {FormSubmitLoading === 'start' && 'Start'}
                                        {FormSubmitLoading === 'done' && 'Done'}
                                        {FormSubmitLoading === 'try' && 'Try-Again'}
                                        {FormSubmitLoading === 'error' && 'Error in Entered Price'}
                                    </Button>
                                    <Button className="btn_outline_red btn-block" onClick={()=>SettoInitalValueFuc()}
                                         data-bs-dismiss="modal" aria-label="Close" disabled={(FormSubmitLoading === 'processing')}>Cancel</Button>


                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>

            
            <div className="modal fade primary_modal" id="price_item_modal1" tabIndex="-1" role="dialog" aria-labelledby="create_item_modalCenteredLabel" aria-hidden="true" data-bs-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="create_item_modalLabel">Follow Steps</h5>

                            <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close" onClick={()=>SettoInitalValueFuc()}>

                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="media approve_media">
                                    {ApproveCallStatus === 'init' && <i className="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>}
                                    {ApproveCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                                    {ApproveCallStatus === 'done' && <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>}
                                    {ApproveCallStatus === 'tryagain' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}

                                    <div className="media-body">
                                        <p className="mt-0 approve_text">Approve</p>
                                        <p className="mt-0 approve_desc">Checking balance and approving</p>
                                    </div>
                                </div>
                                <div className="text-center my-3">
                                    <Button className={"primary_btn btn-block"}
                                        disabled={(ApproveCallStatus === 'processing' || ApproveCallStatus === 'done')}
                                        onClick={ApproveCall}
                                    >
                                        {ApproveCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                        {ApproveCallStatus === 'init' && 'Approve'}
                                        {ApproveCallStatus === 'processing' && 'In-progress...'}
                                        {ApproveCallStatus === 'done' && 'Done'}
                                        {ApproveCallStatus === 'tryagain' && 'Try Again'}

                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            
        </>
    )
})
