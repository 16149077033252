import React, { useEffect, useState, useRef } from "react";
import Artcards from "../../assets/images/artimage/artcard.png";
import { Link,useLocation } from "react-router-dom";

import config from '../../lib/config'

export default function Artcard(props) {

    console.log('lljkfdjlks',props.Collection)

    const Collection = props.Collection

    return (

        <div>
            <Link to={`/collectiondetail/${Collection.userAddress}/${Collection?.customurl}/${Collection?.collectionAddress}`}>
            <div class="card">
                <img class="card-img-top" src={Collection?.imageName ? `${config.Back_URL}/collectionLogo/${Collection.userAddress}/${Collection.imageName}` : require("../../assets/images/hotcollectionone.png") } alt="Card image cap"/>
                    <div class="card-body">
                        <h5 class="card-title mt-0 mb-2 text-center">{Collection.collectionName}</h5>
                      <div className="d-flex justify-content-between">
                        <div><p className="floor mb-0">Floor</p><p className="amount  mb-0">{"<"}{Collection.floorPrice} ETH</p></div>
                        <div><p className="floor mb-0">Total NFTS</p><p className="amount text-end  mb-0">{ Collection?.TotalNfts ? Collection?.TotalNfts : 0 }</p></div>
                      </div>
                    </div>
            </div>
            </Link>
        </div>

    );
}
