//kslp
import React, { useEffect, useState, useRef } from "react";
// nodejs library that concatenates classes

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link, useParams } from "react-router-dom";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

// Import css files
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// mysid
import '@metamask/legacy-web3'
import { toast } from 'react-toastify';

import Artcarousel from "components/Artpage/artcarousel";
import Artcard from "components/Artpage/artcard";
import Artcardcategory from "components/Artpage/artcardcategory";
import Arttable from "components/Artpage/arttable";
import { ExploreNewCollection } from "../actions/v1/token";
import config from '../lib/config'

toast.configure();
const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function Art(props) {

    const classes = useStyles();

    const {category} = useParams();

    const [TrendingSubCollections, SetTrendingSubCollections] = useState([])
    const [TrendingCollections, SetTrendingCollections] = useState([])
    const [TopCollections, SetTopCollections] = useState([]);
    const [Promotions, SetPromotions] = useState([]);
    const [ Category, SetCategory ] = useState([]);


    
    const optionlist = {
        dots: false,
        infinite: true,
        autoplay: true,
        smartSpeed: 400,
        //loop:true,
        margin: 16,
        nav: true,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
        speed: 1500,

        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            992: {
                items: 3
            },
            1000: {
                items: 3.8
            }
        }

    };

    const artcardcaro = {
        loop: true,
        autoplay: true,
        // autoplayTimeout: 1500,
        smartSpeed: 400,
        margin: 16,
        dots: false,
        nav: false,
        responsive: {
            0: {
                items: 1.1
            },
            576: {
                items: 2.2
            },
            992: {
                items: 3.2
            },
            1000: {
                items: 3.6
            }
        }

    };

    const [carousels, setcrousels] = useState([
        {
            "id": 1
        },
        {
            "id": 2
        },
        {
            "id": 3
        },
        {
            "id": 4
        },
        {
            "id": 5
        },
        {
            "id": 6
        },
        {
            "id": 7
        },
    ])

    const [trendingcard, settrendingcard] = useState([
        {
            "id": 1,
            "title": "Art Spotlight",
        },
        {
            "id": 2,
            "title": "Trending in Digital Art",
        },
        {
            "id": 3,
            "title": "Trending in 3D Art",
        },
        {
            "id": 4,
            "title": "Trending in Abstract Art",
        },

    ])

    const { ...rest } = props;
    const { Param_category } = useParams()

    useEffect(()=>{
        GetDatas();
    },[category])

    const GetDatas = async() => {
        let Resp = await ExploreNewCollection({category:category});
        console.log('ksjfsfls',Resp)
        SetTrendingSubCollections(Resp?.data?.CollectionDatas);
        SetCategory(Resp?.data?.Categories)
        SetTrendingCollections(Resp?.data?.TrendingCollections);
        SetTopCollections(Resp?.data?.TopCollections);
        SetPromotions(Resp?.data?.Promotion)
    }

    return (

        <div>
            <div className="home_header">
                <Header
                    color="transparent"
                    routes={dashboardRoutes}
                    brand={<>
                        <div className="light_logo"><a href="https://edaface.com/" target="_blank">
                            <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></a></div>
                        <div className="dark_logo"><Link to="/">
                            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
                    </>}
                    rightLinks={<HeaderLinks />}
                    fixed
                    changeColorOnScroll={{
                        height: 20,
                        color: "white"
                    }}
                    {...rest}
                />
                <section>
                    <div className="container-fluid container-theme container-lg">
                        {   Promotions?.length > 0 &&
                        <div className="section-carousel pt-5 -pb-4">
                            <OwlCarousel className='owl-theme' {...optionlist}>
                                
                                {Promotions?.map((val, i) =>
                                    <div class="item">
                                        
                                        <Artcarousel 
                                            promotion = {val}
                                        />
                                        
                                    </div>
                                )}
                            </OwlCarousel>
                        </div>
                        }
                        <div className="trendingtabs pt-4 pb-3">
                            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Trending</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Top</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    { TrendingCollections?.length > 0 && <Arttable trending={TrendingCollections}/>}
                                </div>
                                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                { TopCollections?.length > 0 && <Arttable trending={TopCollections}/> }
                                </div>
                            </div>
                        </div>
                        { TrendingSubCollections?.length > 0 &&
                        TrendingSubCollections.map((val, i) =>
                        <>
                            {val?.Collections?.length > 0 &&
                                <div className="artcard pt-4 pb-3">
                                    <h2>{ category == 'All' ? "Trending in "+val.name : "Trending in "+val.subcategory+(config.literaturcategory.toLowerCase() == category?.toLowerCase() ? "" : " "+val.name) }</h2>
                                    <OwlCarousel className='owl-theme' {...artcardcaro}>
                                        {val?.Collections?.map((collectionval, i) =>
                                            <div class="item">
                                                <Artcard 
                                                    Collection ={collectionval}
                                                />
                                            </div>
                                        )}
                                    </OwlCarousel>
                                </div>
                            }
                        </>
                        )}
                        {
                            (category).toLowerCase() != config.literaturcategory.toLowerCase() &&
                        <div className="artcard pt-4 pb-3">
                            {Category?.length > 0 &&
                            <OwlCarousel className='owl-theme' {...artcardcaro}>
                                {Category.map((val, i) =>
                                    <div class="item">
                                        <Artcardcategory data={val}/>
                                    </div>
                                )}
                            </OwlCarousel>
                            }
                        </div>
                        }
                    </div>
                </section>
                <div id="logo_overlay" className="logo_ovelay">
                    <Footer />
                </div>
            </div>
        </div>

    );
}
