import React, { useEffect, useState, useRef, useMemo } from "react";
import Web3 from "web3";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import config from "../lib/config";
import { WalletRef } from "./separate/WalletRef";
import isEmpty from "../lib/isEmpty";
import moment from "moment";
import Countdown from "react-countdown";
import { Document, Page, pdfjs } from "react-pdf";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import Avatars from "./Avatar";
import Convert from "views/separate/Convert";
import { toast } from "react-toastify";
import Bannerimg from "../assets/images/bannerheade.png";
import { PlaceAndAcceptBidRef } from "./separate/PlaceAndAcceptBidRef";
import { PurchaseNowRef } from "./separate/PurchaseNowRef";
import { PutOnSaleRef } from "./separate/PutOnSaleRef";
import { LikeRef } from "./separate/LikeRef";
import { CancelOrderRef } from "./separate/CancelOrderRef";
import { BurnRef } from "./separate/BurnRef";
import { ReportNowRef } from "./separate/ReportNowRef";
import { ShareNowRef } from "./separate/ShareNowRef";
import { TransferRef } from "./separate/TransferRef";
import { WithDrawRef } from "./separate/WithDrawRef";
import { network } from "../views/network";
import Loader from "./Loader";
import axios from "axios";
import LazyLoad from "react-lazyload";
import LazyLoader from "./lazyloader";

import {
  TokenCounts_Get_Detail_Action,
  setTokenCounts_Get_Detail_Action,
  History,
  saverating,
  likes,
  IpfsUpdate,
  GetVideo,
} from "../actions/v1/token";

const dashboardRoutes = [];
toast.configure();
let toasterOption = config.toasterOption;

const useStyles = makeStyles(styles);

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Info(props) {
  const classes = useStyles();

  const location = useLocation();

  const { ...rest } = props;
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);

  var params = useParams();

  var { owneraddress, collectionaddress, tokenidval } = params;

  const LikeForwardRef = useRef();
  const PlaceABidForwardRef = useRef();
  const PutOnSaleForwardRef = useRef();
  const PurchaseNowForwardRef = useRef();
  const CancelOrderForwardRef = useRef();
  const WalletForwardRef = useRef();
  const WithdrawForwardRef = useRef();
  // const location = useLocation();
  var BurnForwardRef = useRef();
  var ReportForwardRef = useRef();
  var ShareForwardRef = useRef();
  const TransferForwardRef = useRef();

  // wallet related : common state
  const [AllowedQuantity, Set_AllowedQuantity] = useState(0);
  const [tokenUsers, setTokenUsers] = useState({});
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = React.useState("");
  const [tokenCounts, Set_tokenCounts] = useState(tokenidval);
  const [item, Set_item] = useState({});
  const [his, sethis] = useState([]);
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = useState(0);
  const [Bids, Set_Bids] = useState({});
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [LikedTokenList, setLikedTokenList] = React.useState([]);
  const [
    BidApply_ApproveCallStatus,
    Set_BidApply_ApproveCallStatus,
  ] = React.useState("init");
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = React.useState(
    "init"
  );
  const [HitItem, Set_HitItem] = useState({});
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [convertVal, setConvertVal] = React.useState(0);

  const [OwnersDetailFirst, Set_OwnersDetailFirst] = useState({});
  const [showingLoader, setshowingLoader] = React.useState(true);
  const [onwer_price, set_onwer_price] = useState({});
  const [Service_Fee, set_Service_Fee] = useState(0);
  const [Wen_Bln, set_Wen_Bln] = useState(0);
  const [MyItemAccountAddr_Details, Set_MyItemAccountAddr_Details] = useState(
    {}
  );
  const [Zero_Price_Detail, Set_Zero_Price_Detail] = useState([]);
  const [token_owner_detail_first, set_token_owner_detail_first] = useState({});
  const [swap_fee, set_swap_fee] = useState(0);
  const [ChainId, setChainId] = useState("");
  const [currentChainId, setcurrentChainId] = useState("");
  const [star, setstar] = useState(0);
  const [likeList, setlikeList] = useState(LikedTokenList);
  const history = useHistory();
  const [IpfsUpdated, SetIpfsUpdated] = useState(false);

  const [Refresh, SetRefresh] = useState(false);

  const [PageCount,SetPageCount] = useState({});

  console.log("lshfsjfsj", item);

  useEffect(() => {
    (async () => {
      var state = location.state;
      var curAddr = Wallet_Details.UserAccountAddr;
      if (state) {
        var payload = {
          curAddr: curAddr,
          tokenCounts: String(tokenidval),
          paramAddress: String(owneraddress).toLowerCase(),
          contractAddress: String(collectionaddress).toLowerCase(),
          image: state.imgurl,
          amount: state.amount,
          name: state.name,
          title: state.title,
          type: state.type,
          from: state.from,
          collectionName: state.collectionName,
          metadata: state.metfile,
          ipfshash: state.ipfshash,
          meta: state.meta,
        };
      } else {
        var payload = {
          curAddr: curAddr,
          tokenCounts: String(tokenidval),
          paramAddress: String(owneraddress).toLowerCase(),
          contractAddress: String(collectionaddress).toLowerCase(),
        };
      }

      // console.log("FGndghmnfgh",payload,state);

      if (Wallet_Details.UserAccountAddr !== "") {
      }

      var dataHistory = await History({ tokenCounts: String(tokenidval) });
      if (dataHistory?.data?.length > 0) {
        sethis(dataHistory.data);
        await TokenCounts_Get_Detail_Call(payload);
      } else if (state && !dataHistory?.data?.length) {
        // console.log('noissueeee-------',payload,state?.data?.metadata?.animation_url)
        if (state?.data?.metadata?.animation_url) {
          var Response = await axios.get(state.data.metadata.animation_url);
          // console.log('judgfhsd',Response)
          payload.filetype = Response.headers["content-type"].includes("image")
            ? "image"
            : Response.headers["content-type"].includes("video")
            ? "video"
            : "audio";
        }
        sethis(payload);
        Set_item(payload);
        Set_AllowedQuantity(payload.amount);
        setshowingLoader(false);
      }
    })();
  }, [
    Wallet_Details.UserAccountAddr,
    params,
    star,
    Wallet_Details.networkConfiguration,
    Refresh,
  ]);

  const Starratingcall = async (item1) => {
    // console.log("dfhbsfrh",item1);
    if (
      item1 != "" &&
      item1.tokenowners_current &&
      item1.tokenowners_current.length > 0
    ) {
      var data = {
        from: "getrating",
        curraddress: Wallet_Details.UserAccountAddr,
        tokenCounts: item1.tokenowners_current[0].tokenCounts,
      };
      var resp = await saverating(data);
      //starCount
      if (resp?.data?.data?.data?.[0]?.starCount) {
        // console.log("dfhadrhstdfhndhns",resp.data.data.data[0].starCount);
        var starCount = resp.data.data.data[0].starCount;
        setstar(starCount);
      }
    }
    setshowingLoader(false);
  };

  const getLikes = async (item) => {
    // console.log("fgsndghmnjdgh",Wallet_Details.UserAccountAddr);
    if (Wallet_Details.UserAccountAddr != "" && item) {
      var data = {
        currAdd: Wallet_Details.UserAccountAddr,
        tokenCount: item.tokenCounts,
      };
      // console.log("fgnsdghngdhmn",data);
      var likesCount = await likes(data);
      // console.log("dfbgsdfthnfdgnj",likesCount.data.data.data);
      if (likesCount?.data?.data) {
        setlikeList(likesCount.data.data.data);
        setLikedTokenList(likesCount.data.data.data);
      }
    }
  };

  const TokenCounts_Get_Detail_Call = async (payload) => {
    // if(!IpfsUpdated){
    //   setshowingLoader(true)
    // }
    setshowingLoader(true);

    //  var web3 = new Web3(window.ethereum);
    //   const chainId = await web3.eth.getChainId();

    //   setcurrentChainId(chainId)
    // console.log("sgetrh",chainId);

    // console.log("TokenCounts_Get_Detail_Call", payload);
    var curAddr = payload.curAddr;
    var Resp;

    if (payload.refresh === "refresh") {
      // if(!IpfsUpdated){
      //   setshowingLoader(true)
      // }
      setshowingLoader(true);

      //   await setTokenCounts_Get_Detail_Action(payload)
      //   setshowingLoader(false)
      // }
      var refresh = await setTokenCounts_Get_Detail_Action(payload);
    }
    if (refresh != null) {
      toast.success("refreshed successfully", toasterOption);

      setshowingLoader(false);
    }
    Resp = await TokenCounts_Get_Detail_Action(payload);
    console.log("djsghskfgsgfjs", Resp);

    setTimeout(() => {
      setshowingLoader(false);
    }, 3000);

    if (
      Resp &&
      Resp &&
      Resp.data &&
      Resp.data.Detail &&
      Resp.data.Detail.Resp
    ) {
      var TokenResp = Resp.data.Detail.Resp;
      if (
        TokenResp &&
        TokenResp.Token &&
        TokenResp.Token[0] &&
        TokenResp.Token[0].tokenowners_current
      ) {
        var sum = 0;
        sum = TokenResp.Token[0].tokenowners_current.reduce(
          (a, b) => a + (b["ListedQuantity"] || 0),
          0
        );
        Set_AllowedQuantity(sum);
        // console.log("Set_AllowedQuantity", TokenResp.Token[0].tokenowners_current, sum)
        console.log('dataaaaaaaa',TokenResp?.Token?.[0]?.tokenowners_current)
        for (
          let i = 0;
          i < TokenResp.Token[0].tokenowners_current.length;
          i++
        ) {
          const element = TokenResp.Token[0].tokenowners_current[i];

          console.log('looooppppppppppp',element.tokenOwner,i,owneraddress,owneraddress == element.tokenOwner)

          if( owneraddress == element.tokenOwner ){
            set_onwer_price(element);
          }
          
          ////console.log("eowieoiwieoiwoeiwe",sum,element.balance,TokenResp.Token[0])

          if (element.tokenPrice > 0 && element.tokenOwner !== curAddr) {
            Set_BuyOwnerDetailFirst(element);
            // break;
          }
          // if (element.tokenOwner !== curAddr) {
          //   Set_Zero_Price_Detail(element);

          //   break;
          // }
          // if (element.tokenPrice > 0 && element.tokenOwner === curAddr) {
          //   Set_OwnersDetailFirst(element);

          //   break;
          // }
        }
      }
      // console.log('hiiiiii',TokenResp)
      Set_tokenCounts_Detail(TokenResp);
      //console.log("slasjakljkasjdsajdasdlasj",TokenResp.Bids)
      if (TokenResp.Bids) {
        Set_Bids(TokenResp.Bids);
      }

      var IndexVal = -1;
      var tokenOwnInf = {};
      if (TokenResp.Token[0] && TokenResp.Token[0].tokenowners_all && curAddr) {
        var tokenowners_all = TokenResp.Token[0].tokenowners_all;
        IndexVal = tokenowners_all.findIndex(
          (val) =>
            val.tokenOwner.toString() === curAddr.toString() && val.balance > 0
        );
      }

      // //////////console...log("check all val")

      if (IndexVal > -1) {
        Set_MyTokenBalance(tokenowners_all[IndexVal].balance);
        Set_MyTokenDetail(tokenowners_all[IndexVal]);
        var addrs = TokenResp.Token[0].tokenOwnerInfo.curraddress[IndexVal];
        tokenOwnInf.curraddress = addrs;
        tokenOwnInf.name = TokenResp.Token[0].tokenOwnerInfo.name[IndexVal];
        set_token_owner_detail_first(tokenOwnInf);
        // console.log("check all val122", TokenResp.Token[0].tokenOwnerInfo, TokenResp.Token[0].tokenOwnerInfo.curraddress[IndexVal])
      } else {
        Set_MyTokenDetail({});
        Set_MyTokenBalance(0);
      }
      console.log('ldkjfklsjlkfls',TokenResp)

      if (TokenResp.Token && TokenResp.Token[0]) {
        Set_item(TokenResp.Token[0]);

        Starratingcall(TokenResp.Token[0]);
        getLikes(TokenResp.Token[0]);
        setTokenUsers(TokenResp.Tusers);
        // console.log("tokenvaluesss", TokenResp.Token[0])
      }
    }
  };

  const checkerror = (e, item) => {
    e.target.src = `${config.IPFS_IMG}/${item.ipfsimage}`;
  };

  var renderer = ({
    days,
    Month,
    Year,
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      return <span>Waiting for Owner To Accept</span>;
    } else {
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s left
        </span>
      );
    }
  };

  async function GetUserBal() {
    await WalletForwardRef.current.GetUserBal();
  }

  const onlikeClick = async (item) => {
    LikeForwardRef.current.hitLike(item);
    setTimeout(() => {
      // console.log("sdfhbsfghtfsdh",item,item.tokenCounts);
      getLikes(item);
    }, 100);
  };

  async function userRating(e) {
    setshowingLoader(false);
    if (e.target) {
      var stars = 0;
      // console.log("jhbkjhbhj",e.target.id);
      switch (e.target.id) {
        case "star-5":
          setstar(5);
          stars = 5;
          break;
        case "star-4":
          setstar(4);
          stars = 4;
          break;
        case "star-3":
          setstar(3);
          stars = 3;
          break;
        case "star-2":
          setstar(2);
          stars = 2;
          break;
        case "star-1":
          setstar(1);
          stars = 1;
        default:
      }
      // console.log("fbghsdfhbzdf",item.tokenowners_current[0].tokenCounts);
      if (stars != 0) {
        var data = {
          curraddress: Wallet_Details.UserAccountAddr,
          rating: stars,
          tokenCounts: item.tokenowners_current[0].tokenCounts,
        };
        var rating = await saverating(data);
      }
    }
    //var rating = await saverating()
    // console.log("fbghsdfhbzdf",stars);
  }

  function hideDetail() {
    document.getElementById("image_div").classList.toggle("expand_img");
    document.getElementById("img_des").classList.toggle("show_des");
    document.getElementById("detai_div").classList.toggle("hide_detail");
    document.getElementById("arrow_icon").classList.toggle("fa-shrink");
  }

  const GetUpdatas = () => {
    SetRefresh(!Refresh);
    setshowingLoader(true);
  };

  useEffect(() => {
    console.log("lkdsjflksdjfldsfsd", item);
    if (item?.ipfsimage == "" && item?.tokenCounts !== "" && !IpfsUpdated) {
      LazyIpfsUpdate();
    }
  }, [item]);
  const LazyIpfsUpdate = async () => {
    let updated = await IpfsUpdate({ tokenCounts: item?.tokenCounts });
    if (updated?.success == "success") {
      Set_item({ ...item, ...updated.data });
    } else {
      return LazyIpfsUpdate();
    }
  };
console.log('dlskjfkljslfsks',item)
  const DisplayNFT = () => {
    if (item?.Preview) {
      if (
        (item?.tokenowners_all)?.some((instowner)=>(instowner?.WhiteListUser?.includes(Wallet_Details.UserAccountAddr && instowner?.balance > 0))) ||
        (item?.tokenowners_all)?.some((instowner)=>{return (instowner?.tokenOwner == Wallet_Details.UserAccountAddr && instowner?.balance > 0)})
      ) {
        return item && item?.image && item?.image == ""
          ? `${config.Back_URL}/nftImg/${item?.tokenCreator}/${item.additionalImage}`
          : `${config.Back_URL}/compressedImage/${item.tokenCreator}/${item.image}`;
      } 
      else {
        return item && item?.Preview
          ? `${config.Back_URL}/preview/${item?.tokenCreator}/${item.Preview}`
          : `${config.IPFS_IMG}/${item?.ipfsimage}`;
      }
    } 
    else {
      return item && item?.image && item?.image == ""
        ? `${config.Back_URL}/nftImg/${item?.tokenCreator}/${item.additionalImage}`
        : `${config.Back_URL}/compressedImage/${item.tokenCreator}/${item.image}`;
    }
  };

  useEffect(()=>{
    if (!isEmpty(item?.Preview)) {
      PDFDecode()
    }
  },[item])

  const PDFDecode = async() => {
    
    // console.log('dlsfjsljflsf',response)
    // var reader = new FileReader();
    // reader.readAsBinaryString(`${config.Back_URL}/preview/${item?.tokenCreator}/${item.Preview}`);
    // reader.onloadend = function () {
    //   var count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
    //   console.log('Number of Pages:', count);
    // }
  }

  const GetPDFCount = useMemo(() => {
    console.log('lsjslfkslfs',item)
    function onDocumentLoadSuccess({ numPages },type) {
      SetPageCount({...PageCount,...{ [type]: numPages } })
      console.log('dkskfjhsjfs',numPages)
    }

    return(
      <>
      {
          item?.Preview &&
          <Document
            file={
              `${config.Back_URL}/preview/${item?.tokenCreator}/${item.Preview}`
            }
            onLoadSuccess={(e) => onDocumentLoadSuccess(e, 'Preview')}
          >

            {/* <Page /> */}
          </Document>
      }
              <Document
                file={
                  `${config.Back_URL}/compressedImage/${item.tokenCreator}/${item.image}`
                }
                onLoadSuccess={(e)=>onDocumentLoadSuccess(e,'Original')}
              >
                
                {/* <Page /> */}
              </Document>
      </>
    )
  },[item,PageCount])

  console.log("jskhfsnumPagesfs", item,PageCount);

  useEffect(()=>{
    if(!isEmpty(item?.image)&&!item?.image?.includes('.pdf')&&!item?.image?.includes('.webp')){
      GetFileTiming()
    }
  },[item])

  const GetFileTiming = async() => {
    let previewpath = isEmpty(item?.Preview) ? undefined : `preview/${item?.tokenCreator}/${item.Preview}`;
    let FullFilePath = isEmpty(item?.image) ? `nftImg/${item?.tokenCreator}/${item.additionalImage}` : `compressedImage/${item.tokenCreator}/${item.image}`;
    console.log('dskjlkdjslfs',item?.image?.includes('.mp3'))
    if(item?.image?.includes('.mp3')){
      if (item?.Preview) {
        let audiofile = new Audio();
        audiofile.src = `${config.Back_URL}/${previewpath}`;
        audiofile.addEventListener('loadedmetadata', () => {
          let timing = { Preview: TimeDurationDisplay(audiofile.duration) };
          let originalaudiofile = new Audio();
          originalaudiofile.src = `${config.Back_URL}/${FullFilePath}`;
          originalaudiofile.addEventListener('loadedmetadata', () => {
            timing.Original = TimeDurationDisplay(originalaudiofile.duration);
            console.log('jskhfsnumPagesfsinnn', timing)
            SetPageCount({ ...timing });
          })
        })
      }
      else {
        let timing = {};
        let originalaudiofile = new Audio();
        originalaudiofile.src = FullFilePath;
        originalaudiofile.addEventListener('loadedmetadata', () => {
          timing.Original = TimeDurationDisplay(originalaudiofile.duration);
          console.log('jskhfsnumPagesfsinnn', timing)
          SetPageCount({ ...timing });
        })
      }
    }
    else{
      let videodurations = await GetVideo({Preview: previewpath, Original: FullFilePath});
      videodurations.data.Original = isEmpty(videodurations?.data?.Original) ? videodurations?.data?.Original : (isNaN(videodurations?.data?.Original) ? RemoveExtraTiming(videodurations?.data?.Original) : TimeDurationDisplay(Number(videodurations?.data?.Original)) );
      videodurations.data.Preview = isEmpty(videodurations?.data?.Preview) ? videodurations?.data?.Preview : (isNaN(videodurations?.data?.Preview) ? RemoveExtraTiming(videodurations?.data?.Preview) : TimeDurationDisplay(Number(videodurations?.data?.Preview)) );
      SetPageCount({...videodurations.data})
      console.log('ddjdkjlsfsjklfs1111',item?.Preview,videodurations)
      
    }
  }

  const RemoveExtraTiming = (duration) => {
    let inarray = duration.split(':');
    let result = '';
    if(inarray?.length>2){

      inarray.map((times,ind)=>{
        if(ind==0){
          if(Number(times)==0){
            result = inarray[1]+":"+inarray[2];
          }
        }
      })
    }
    return result
  }

  const TimeDurationDisplay = (duration) => {
    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;
    let ret = "";
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    if(mins<10){
      ret += "0"
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  // color options

  const colorType = [
    {
      id: 1,
      color: "red",
    },
    {
      id: 2,
      color: "blue",
    },
    {
      id: 3,
      color: "green",
    },
    {
      id: 4,
      color: "orange",
    },
    {
      id: 5,
      color: "yellow",
    },
    {
      id: 6,
      color: "red",
    },
    {
      id: 7,
      color: "orange",
    },
    {
      id: 8,
      color: "orange",
    },
    {
      id: 9,
      color: "orange",
    },
    {
      id: 10,
      color: "orange",
    },
  ];
  return showingLoader === true ? (
    <Loader />
  ) : (
    <div className="inner_header">
      <>
        {
          <PutOnSaleRef
            ref={PutOnSaleForwardRef}
            Set_HitItem={Set_HitItem}
            item={HitItem}
            Service_Fee={Service_Fee}
            set_Service_Fee={set_Service_Fee}
            // UserAccountAddr={Wallet_Details.UserAccountAddr}
            // UserAccountBal={Wallet_Details.UserAccountBal}
            // Accounts={Wallet_Details.Accounts}
            GetUserBal={GetUserBal}
            againCall={TokenCounts_Get_Detail_Call}
            setConvertVal={setConvertVal}
            convertVal={convertVal}
            GetUpdatas={GetUpdatas}
            // set_providers={set_providers}
            // providerss={Wallet_Details.providerss}
          />
        }
        {
          <BurnRef
            ref={BurnForwardRef}
            Set_HitItem={Set_HitItem}
            item={HitItem}
            Service_Fee={Service_Fee}
            set_Service_Fee={set_Service_Fee}
            // UserAccountAddr={Wallet_Details.UserAccountAddr}
            // UserAccountBal={Wallet_Details.UserAccountBal}
            // Accounts={Wallet_Details.Accounts}
            GetUserBal={GetUserBal}
            againCall={TokenCounts_Get_Detail_Call}
            setConvertVal={setConvertVal}
            convertVal={convertVal}
            GetUpdatas={GetUpdatas}
            // set_providers={set_providers}
            // providerss={Wallet_Details.providerss}
          />
        }
        {
          <PlaceAndAcceptBidRef
            ref={PlaceABidForwardRef}
            // Set_WalletConnected={Set_WalletConnected}
            // Set_UserAccountAddr={Set_UserAccountAddr}
            // Set_UserAccountBal={Set_UserAccountBal}
            Set_AddressUserDetails={Set_AddressUserDetails}
            // Set_Accounts={Set_Accounts}
            Set_MyItemAccountAddr={Set_MyItemAccountAddr}
            Set_tokenCounts={Set_tokenCounts}
            Set_item={Set_item}
            Set_tokenCounts_Detail={Set_tokenCounts_Detail}
            Set_MyTokenBalance={Set_MyTokenBalance}
            Set_Bids={Set_Bids}
            Set_AccepBidSelect={Set_AccepBidSelect}
            Set_tokenBidAmt={Set_tokenBidAmt}
            Set_NoOfToken={Set_NoOfToken}
            Set_ValidateError={Set_ValidateError}
            Set_TokenBalance={Set_TokenBalance}
            Set_YouWillPay={Set_YouWillPay}
            Set_YouWillPayFee={Set_YouWillPayFee}
            Set_YouWillGet={Set_YouWillGet}
            Set_BidApply_ApproveCallStatus={Set_BidApply_ApproveCallStatus}
            Set_BidApply_SignCallStatus={Set_BidApply_SignCallStatus}
            // WalletConnected={Wallet_Details.WalletConnected}
            // UserAccountAddr={Wallet_Details.UserAccountAddr}
            // UserAccountBal={Wallet_Details.UserAccountBal}
            AddressUserDetails={Wallet_Details.AddressUserDetails}
            // Accounts={Wallet_Details.Accounts}
            MyItemAccountAddr={MyItemAccountAddr}
            tokenCounts={tokenCounts}
            item={item}
            tokenCounts_Detail={tokenCounts_Detail}
            MyTokenBalance={MyTokenBalance}
            Bids={Bids}
            AccepBidSelect={AccepBidSelect}
            tokenBidAmt={tokenBidAmt}
            NoOfToken={NoOfToken}
            ValidateError={ValidateError}
            TokenBalance={TokenBalance}
            YouWillPay={YouWillPay}
            YouWillPayFee={YouWillPayFee}
            YouWillGet={YouWillGet}
            BidApply_ApproveCallStatus={BidApply_ApproveCallStatus}
            BidApply_SignCallStatus={BidApply_SignCallStatus}
            AllowedQuantity={AllowedQuantity}
            Service_Fee={Service_Fee}
            set_Service_Fee={set_Service_Fee}
            // set_Wen_Bln={set_Wen_Bln}
            // Wen_Bln={Wallet_Details.Wen_Bln}
            MyItemAccountAddr_Details={MyItemAccountAddr_Details}
            againCall={TokenCounts_Get_Detail_Call}
            setConvertVal={setConvertVal}
            convertVal={convertVal}
            GetUpdatas={GetUpdatas}
            // set_providers={set_providers}
            // providerss={Wallet_Details.providerss}
          />
        }
        {
          <PurchaseNowRef
            ref={PurchaseNowForwardRef}
            Set_HitItem={Set_HitItem}
            item={HitItem}
            // UserAccountAddr={Wallet_Details.UserAccountAddr}
            // UserAccountBal={Wallet_Details.UserAccountBal}
            // Accounts={Wallet_Details.Accounts}
            GetUserBal={GetUserBal}
            Service_Fee={Service_Fee}
            set_Service_Fee={set_Service_Fee}
            set_Wen_Bln={set_Wen_Bln}
            Wen_Bln={Wallet_Details.Wen_Bln}
            MyItemAccountAddr_Details={MyItemAccountAddr_Details}
            againCall={TokenCounts_Get_Detail_Call}
            setConvertVal={setConvertVal}
            convertVal={convertVal}
            GetUpdatas={GetUpdatas}
            // set_providers={set_providers}
            // providerss={Wallet_Details.providerss}
          />
        }
        <WalletRef
          ref={WalletForwardRef}
          // Set_UserAccountAddr={Set_UserAccountAddr}
          // Set_WalletConnected={Set_WalletConnected}
          // Set_UserAccountBal={Set_UserAccountBal}
          // set_providers={set_providers}
          // providerss={Wallet_Details.providerss}
        />
        <LikeRef
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          ref={LikeForwardRef}
          // WalletConnected={Wallet_Details.WalletConnected}
          // set_providers={set_providers}
          // providerss={Wallet_Details.providerss}
          setLikedTokenList={setLikedTokenList}
          MyItemAccountAddr={MyItemAccountAddr}
        />
        <CancelOrderRef
          ref={CancelOrderForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          // WalletConnected={Wallet_Details.WalletConnected}

          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          // Accounts={Wallet_Details.Accounts}
          GetUserBal={GetUserBal}
          againCall={TokenCounts_Get_Detail_Call}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
          GetUpdatas={GetUpdatas}
          // set_providers={set_providers}
          // providerss={Wallet_Details.providerss}
        />
        {
          <WithDrawRef
            ref={WithdrawForwardRef}
            Set_HitItem={Set_HitItem}
            item={HitItem}
            Service_Fee={Service_Fee}
            set_Service_Fee={set_Service_Fee}
            // UserAccountAddr={Wallet_Details.UserAccountAddr}
            // UserAccountBal={Wallet_Details.UserAccountBal}
            // Accounts={Wallet_Details.Accounts}
            GetUserBal={GetUserBal}
            againCall={TokenCounts_Get_Detail_Call}
            setConvertVal={setConvertVal}
            convertVal={convertVal}
            GetUpdatas={GetUpdatas}
            // set_providers={set_providers}
            // providerss={Wallet_Details.providerss}
          />
        }

        <ReportNowRef
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          ref={ReportForwardRef}
        />
        <ShareNowRef ref={ShareForwardRef} />
        <TransferRef
          ref={TransferForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          // Accounts={Wallet_Details.Accounts}
          GetUserBal={GetUserBal}
          swap_fee={swap_fee}
          set_swap_fee={set_swap_fee}
          againCall={TokenCounts_Get_Detail_Call}
          GetUpdatas={GetUpdatas}
          // set_providers={set_providers}
          // providerss={Wallet_Details.providerss}
        />
        <Header
          fixed
          color="transparent"
          routes={dashboardRoutes}
          brand={
            <>
              <div className="light_logo">
                <a href="https://edaface.com/" target="_blank">
                  <img
                    src={require("../assets/images/logo.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </a>
              </div>
              <div className="dark_logo">
                <Link to="/">
                  <img
                    src={require("../assets/images/dark-theme-logo.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </Link>
              </div>
            </>
          }
          rightLinks={<HeaderLinks />}
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <ScrollToTopOnMount />

          { console.log('ownerrrrrrr',onwer_price) }

        <div className="inner_pageheader info_header info">
          <div
            className={
              classes.pageHeader +
              "container-fluid container-theme container-lg"
            }
          >
            {/* info row */}
            <div className="row mx-0 buyer_div">
              <div className="col-12 col-lg-6" id="image_div">
                <div className="flex_center">
                  <div
                    className="float-right arrow_expand"
                    onClick={hideDetail}
                  ></div>
                  <div className="clearfix"></div>
                  {/* <div className="mid-cont">

                  <img src={Bannerimg} alt="Collections" className="img-fluid" />

                </div> */}

                  <>
                    {item?.from !== "userCollection" ? (
                      <div className="mid-cont mid_conts">
                        {item &&
                          item.image &&
                          item.image !== "" &&
                          (String(item.image).split(".").pop() === "mp4" ||
                            String(item.image).split(".").pop() === "webm" ||
                            String(item.image).split(".").pop() === "WEBM" ||
                            String(item.image).split(".").pop() === "ogv" ||
                            String(item.image).split(".").pop() === "OGV") && (
                            <>
                              {MyTokenDetail.tokenOwner !==
                              Wallet_Details.UserAccountAddr ? (
                                <video
                                  id="my-video"
                                  className="img-fluid"
                                  muted
                                  controlsList="nodownload"
                                  playsInline
                                  loop
                                  controls
                                  autoPlay
                                  // preload="auto"
                                  alt="video"
                                >
                                  <source src={DisplayNFT()} type="video/mp4" />
                                </video>
                              ) : (
                                <video
                                  id="my-video"
                                  className="img-fluid"
                                  muted
                                  playsInline
                                  loop
                                  controls
                                  autoPlay
                                  controlsList={
                                    onwer_price.tokenOwner !==
                                      Wallet_Details.UserAccountAddr &&
                                    "nodownload"
                                  }
                                  // preload="auto"
                                  alt="video"
                                >
                                  <source src={DisplayNFT()} type="video/mp4" />
                                </video>
                              )}
                            </>
                          )}
                        {item &&
                          item.image &&
                          item.image !== "" &&
                          (String(item.image).split(".").pop() === "mp3" ||
                            String(item.image).split(".").pop() === "aac" ||
                            String(item.image).split(".").pop() === "AAC" ||
                            String(item.image).split(".").pop() === "FLAC" ||
                            String(item.image).split(".").pop() === "flac") && (
                            <>
                              {item.thumb != "" ? (
                                <img
                                  src={`${config.Back_URL}/Thumb_compressedImage/${item?.tokenCreator}/${item.thumb}`}
                                  className="img-fluid"
                                  alt="text"
                                />
                              ) : (
                                <img
                                  src={config.AudioImg}
                                  className="img-fluid"
                                  alt="text"
                                />
                              )}

                              {
                                // MyTokenDetail.tokenOwner !== Wallet_Details.UserAccountAddr ?
                                <>
                                  <audio
                                    controls
                                    controlsList="nodownload"
                                    muted
                                    alt="audio"
                                    id="audio_play"
                                    playsInline
                                    loop
                                    type="audio/mp3"
                                    autostart="off"
                                    src={DisplayNFT()}
                                  ></audio>
                                </>
                              }
                            </>
                          )}

                        {/* Book */}
                        {item &&
                          item.image &&
                          item.image !== "" &&
                          (String(item.image).split(".").pop() === "pdf" ||
                            String(item.image).split(".").pop() === "PDF") && (
                            <>
                              {item.thumb != "" ? (
                                <img
                                  src={`${config.Back_URL}/Thumb_compressedImage/${item?.tokenCreator}/${item.thumb}`}
                                  className="img-fluid"
                                  alt="text"
                                />
                              ) : (
                                <img
                                  src={config.AudioImg}
                                  className="img-fluid"
                                  alt="text"
                                />
                              )}
                            </>
                          )}
                        {/* Book */}
                        {
                          item &&
                          item.from !== "userCollection" &&
                          item.image &&
                          item.image !== "" &&
                          (String(item.image).split(".").pop() === "webp" ||
                            String(item.image).split(".").pop() === "WEBP" ||
                            String(item.image).split(".").pop() === "gif" ||
                            String(item.image).split(".").pop() === "jpg" ||
                            String(item.image).split(".").pop() === "GIF" ||
                            String(item.image).split(".").pop() === "JPG" ||
                            String(item.image).split(".").pop() === "JPEG" ||
                            String(item.image).split(".").pop() === "jpeg" ||
                            String(item.image).split(".").pop() === "png" ||
                            String(item.image).split(".").pop() === "PNG") ? (
                            item?.ipfsimage?.split("/").includes("ipfs") ===
                            false ? (
                              <img
                                src={
                                  item?.additionalImage !== ""
                                    ? `${config.Back_URL}/nftImg/${item?.tokenCreator}/${item?.additionalImage}`
                                    : `https://ipfs.io/ipfs/${item?.ipfsimage}`
                                }
                                onError={(e) => checkerror(e, item)}
                                alt="Collections"
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src={
                                  item?.ipfsimage
                                    ?.split("/")
                                    .includes("ipfs") && `${item?.ipfsimage}`
                                }
                                onError={(e) => checkerror(e, item)}
                                alt="Collections"
                                className="img-fluid "
                              />
                            )
                          ) : (
                            ""
                          )

                          //  <img src={item.ipfsimage !== "" ? `${config.IPFS_IMG}/${item.ipfsimage}` : `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`}
                          //                     onError={(e) => checkerror(e, item)}
                          //                     alt="Collections" className="img-fluid " />

                          // <img src={(item?.ipfsimage?.split("/").includes("ipfs"||"http"||"https"))?item.ipfsimage:item?.additionalImage !== ""  ? `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`:`${config.IPFS_IMG}/${item.ipfsimage}` }
                          //   onError={(e) => checkerror(e, item)}
                          //   alt="Collections" className="img-fluid" />
                        }
                      </div>
                    ) : (
                      <div className="ethactios">
                        <LazyLoad
                          height={200}
                          placeholder={<LazyLoader />}
                          offset={[-200, 0]}
                          debounce={500}
                        >
                          {(item && item?.image)?.includes("ipfs://") ===
                            true ||
                          item?.image?.includes("ipfs/") === true ||
                          item?.image?.includes("ipfs:/") === true ? (
                            <object
                              type="image/webp"
                              data={
                                "https://ipfs.io/ipfs/" +
                                item?.image
                                  ?.split("ipfs://")
                                  .pop()
                                  .split("ipfs/")
                                  .pop()
                                  .split("ipfs:/")
                                  .pop()
                              }
                            ></object>
                          ) : (
                            item?.image !== "" && (
                              <object
                                type="image/webp"
                                data={item?.image}
                              ></object>
                            )
                          )}
                        </LazyLoad>
                      </div>
                    )}
                  </>

                  <div className="img_des" id="img_des">
                    <p className="info_title">
                      {item && item.from === "userCollection"
                        ? item?.name
                        : item?.tokenName}{" "}
                      - {item?.tokenCounts}
                    </p>
                    <h3 className="info_h3">
                      by
                      <span className="px-2">
                        {item && item.from === "userCollection"
                          ? Wallet_Details.Regname
                          : isEmpty(item.usersinfo)
                          ? item.tokenOwner
                          : item.usersinfo.name}
                      </span>
                      on<span className="pl-2">NFT</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 mt-3 mt-lg-0" id="detai_div">
                <div className="">
                  <div className="d-md-flex justify-content-between align-items-start d-sm-block">
                    <div className="d-flex flex-column">
                      {/* <span className="info_title">
                        {item && item.from === "userCollection"
                          ? item?.name
                          : item.tokenName}{" "}
                        - {item?.tokenCounts}
                      </span> */}
                      <span className="info_title">
                        {item && item.from === "userCollection"
                          ? item?.name
                          : item.tokenName}{" "}
                      </span>
                      <span className="info_title mt-2">
                        {item?.tokenCounts}
                      </span>
                    </div>

                    <div className="masonry_likes masonry_krd">
                      {collectionaddress !==
                        "0x1419c94d6560b81f16486a4c57c7c66f1253cf20".toLowerCase() &&
                      collectionaddress !==
                        "0x8D43916d784759B46255c0F47e3b67E1c8375e40".toLowerCase() &&
                      collectionaddress !==
                        "0x008505ac19add467B1a6177A27D8D383A078dA26".toLowerCase() &&
                      collectionaddress !==
                        "0x1938f408543A19EF6d6d60C3b8A806eBD4E69236".toLowerCase() ? (
                        //  MyTokenDetail.tokenOwner==UserAccountAddr&&
                        <i
                          className="fas fa-redo pr-3 refresh_icon"
                          aria-hidden="true"
                          onClick={() => {
                            setshowingLoader(true);

                            var payload = {
                              curAddr: Wallet_Details.UserAccountAddr,
                              tokenCounts: tokenidval,
                              paramAddress: String(owneraddress).toLowerCase(),
                              contractAddress: String(
                                collectionaddress
                              ).toLowerCase(),
                              refresh: "refresh",
                            };
                            TokenCounts_Get_Detail_Call(payload);
                          }}
                        ></i>
                      ) : (
                        ""
                      )}

                      {item && item.from !== "userCollection" && (
                        <>
                          {likeList.findIndex(
                            (tokenCounts) =>
                              tokenCounts.tokenCounts === item.tokenCounts
                          ) > -1 ? (
                            <i
                              className="fas fa-heart mr-2 liked"
                              onClick={() => onlikeClick(item)}
                              style={{ cursor: "pointer" }}
                            ></i>
                          ) : (
                            <i
                              className="far fa-heart mr-2"
                              onClick={() => onlikeClick(item)}
                              style={{ cursor: "pointer" }}
                            ></i>
                          )}

                          <div className="dropdown dd_info_inline">
                            <button
                              className="drop-down kr_infodrop"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              More
                              <i class="fa-solid fa-sort-down kr_dropicd"></i>
                            </button>

                            {
                              <div
                                className="dropdown-menu dropdown-menu-right"
                                aria-labelledby="dropdownMenuButton"
                              >
                                {MyTokenDetail &&
                                  MyTokenDetail.balance > 0 &&
                                  (String(
                                    MyTokenDetail.contractAddress
                                  ).toLowerCase() ===
                                    String(
                                      Wallet_Details.networkConfiguration
                                        .singleContract
                                    ).toLowerCase() ||
                                    String(
                                      MyTokenDetail.contractAddress
                                    ).toLowerCase() ===
                                      String(
                                        Wallet_Details.networkConfiguration
                                          .multipleContract
                                      ).toLowerCase() ||
                                    String(
                                      MyTokenDetail.contractAddress
                                    ).toLowerCase() ===
                                      String(
                                        Wallet_Details.networkConfiguration
                                          .TradeContract
                                      ).toLowerCase()) && (
                                    <span
                                      className="dropdown-item"
                                      data-toggle="modal"
                                      data-target="#"
                                      onClick={() =>
                                        BurnForwardRef.current.Burn_Click(
                                          item,
                                          MyTokenDetail
                                        )
                                      }
                                    >
                                      Burn Token
                                    </span>
                                  )}

                                {MyTokenDetail &&
                                  MyTokenDetail.balance > 0 &&
                                  (String(
                                    MyTokenDetail.contractAddress
                                  ).toLowerCase() ===
                                    String(
                                      Wallet_Details.networkConfiguration
                                        .singleContract
                                    ).toLowerCase() ||
                                    String(
                                      MyTokenDetail.contractAddress
                                    ).toLowerCase() ===
                                      String(
                                        Wallet_Details.networkConfiguration
                                          .multipleContract
                                      ).toLowerCase()) &&
                                  item &&
                                  item?.tokenowners_current?.clocktime ==
                                    null &&
                                  item?.tokenowners_current?.endclocktime ==
                                    null && (
                                    <span
                                      className="dropdown-item"
                                      data-toggle="modal"
                                      data-target="#"
                                      onClick={() =>
                                        TransferForwardRef.current.Transfer_Click(
                                          item,
                                          MyTokenDetail
                                        )
                                      }
                                    >
                                      Transfer Token
                                    </span>
                                  )}

                                {/* <span className="dropdown-item" data-toggle="modal" data-target="#" > Put On Sale</span> */}

                                {MyTokenDetail &&
                                  MyTokenDetail &&
                                  MyTokenDetail.balance > 0 &&
                                  (MyTokenDetail.tokenPrice === 0 ||
                                    MyTokenDetail.tokenPrice == null) &&
                                  ((MyTokenDetail.clocktime != null &&
                                    MyTokenDetail.endclocktime != null) ||
                                    (MyTokenDetail.clocktime == null &&
                                      MyTokenDetail.endclocktime == null)) &&
                                  // ?
                                  new Date(
                                    MyTokenDetail.endclocktime
                                  ).getTime() < Date.now() && (
                                    <span
                                      className="dropdown-item"
                                      data-toggle="modal"
                                      data-target="#"
                                      onClick={() =>
                                        PutOnSaleForwardRef.current.PutOnSale_Click(
                                          item,
                                          token_owner_detail_first,
                                          MyTokenDetail
                                        )
                                      }
                                    >
                                      {MyTokenDetail?.ListNFT
                                        ? "Put On Sale"
                                        : "Deposit"}
                                    </span>
                                  )}

                                {MyTokenDetail &&
                                  MyTokenDetail &&
                                  MyTokenDetail.balance > 0 &&
                                  (MyTokenDetail.tokenPrice === 0 ||
                                    MyTokenDetail.tokenPrice == null) &&
                                  ((MyTokenDetail.clocktime != null &&
                                    MyTokenDetail.endclocktime != null) ||
                                    (MyTokenDetail.clocktime == null &&
                                      MyTokenDetail.endclocktime == null)) &&
                                  // ?
                                  new Date(
                                    MyTokenDetail.endclocktime
                                  ).getTime() < Date.now() &&
                                  MyTokenDetail?.ListNFT && (
                                    <span
                                      className="dropdown-item"
                                      data-toggle="modal"
                                      data-target="#"
                                      onClick={() =>
                                        WithdrawForwardRef.current.WithDraw_Click(
                                          item,
                                          token_owner_detail_first,
                                          MyTokenDetail
                                        )
                                      }
                                    >
                                      WithDraw
                                    </span>
                                  )}
                                {MyTokenDetail &&
                                  MyTokenDetail.balance > 0 &&
                                  MyTokenDetail.tokenPrice > 0 && (
                                    <>
                                      <>
                                        <span
                                          className="dropdown-item"
                                          data-toggle="modal"
                                          data-target="#"
                                          onClick={() =>
                                            PutOnSaleForwardRef.current.PutOnSale_Click(
                                              item,
                                              token_owner_detail_first,
                                              MyTokenDetail
                                            )
                                          }
                                        >
                                          Lower Price
                                        </span>
                                      </>
                                      <>
                                        <span
                                          className="dropdown-item"
                                          data-toggle="modal"
                                          data-target="#"
                                          onClick={() =>
                                            CancelOrderForwardRef.current.CancelOrder_Click(
                                              item,
                                              token_owner_detail_first,
                                              MyTokenDetail
                                            )
                                          }
                                        >
                                          Cancel Order
                                        </span>
                                      </>
                                    </>
                                  )}

                                <span
                                  className="dropdown-item"
                                  data-toggle="modal"
                                  data-target="#report_page_modal"
                                  onClick={() =>
                                    ReportForwardRef.current.SubmitReport_Click(
                                      item,
                                      onwer_price
                                    )
                                  }
                                >
                                  Report
                                </span>
                                <span
                                  className="dropdown-item"
                                  data-toggle="modal"
                                  data-target="#share_modal"
                                  onClick={() =>
                                    ShareForwardRef.current.ShareSocial_Click(
                                      item,
                                      onwer_price
                                    )
                                  }
                                >
                                  Share
                                </span>
                              </div>
                            }
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="d-md-flex justify-content-between align-items-center d-sm-block mt-3">
                    <div>
                      <h3 className="info_h3">
                        <span className="pr-2">
                          {item && item.from !== "userCollection" && (
                            <>
                              {item &&
                              item.tokenBid === true &&
                              item.tokenowners_current.clocktime == null &&
                              item.tokenowners_current.endclocktime == null ? (
                                onwer_price &&
                                (onwer_price.tokenPrice &&
                                onwer_price.tokenPrice != null &&
                                onwer_price.tokenPrice !== 0 ? (
                                  <>
                                    <Convert
                                      item={onwer_price.tokenPrice}
                                      coinName={onwer_price.CoinName}
                                      convertVal={1}
                                    />
                                    {" " + onwer_price.CoinName}
                                    <p className="info_pricedkr">
                                      $
                                      {(
                                        onwer_price.tokenPrice *
                                        (Wallet_Details?.currency_price?.[
                                          onwer_price.CoinName.toUpperCase()
                                        ]
                                          ? Number(
                                              Wallet_Details?.currency_price?.[
                                                onwer_price.CoinName.toUpperCase()
                                              ]
                                            )
                                          : 0)
                                      ).toFixed(
                                        Number(onwer_price?.tokenPrice) == 0
                                          ? 0
                                          : 4
                                      )}
                                    </p>
                                  </>
                                ) : (
                                  "For Sale"
                                ))
                              ) : (
                                <>
                                  {onwer_price.minimumBid +
                                    " " +
                                    Wallet_Details.networkConfiguration
                                      .tokenSymbol}
                                  <Convert
                                    item={onwer_price.minimumBid}
                                    coinName={"WENLAMBO"}
                                    convertVal={1}
                                  />
                                  <p className="info_pricedkr">
                                    $
                                    {(
                                      onwer_price?.minimumBid *
                                      (Wallet_Details?.currency_price?.[
                                        onwer_price?.CoinName?.toUpperCase()
                                      ]
                                        ? Number(
                                            Wallet_Details?.currency_price?.[
                                              onwer_price?.CoinName?.toUpperCase()
                                            ]
                                          )
                                        : 0)
                                    ).toFixed(
                                      Number(onwer_price?.minimumBid) == 0
                                        ? 0
                                        : 4
                                    )}
                                  </p>
                                </>
                              )}
                            </>
                          )}
                        </span>

                            {
                              console.log('itemitemitem',item,onwer_price)
                            }

                        {item && item.from !== "userCollection"
                          ? onwer_price.ListedQuantity
                          : item.amount}{" "}
                        <> </> of{" "}
                        {item && item.from !== "userCollection"
                          ? onwer_price.balance
                          : item.amount}
                      </h3>
                    </div>
                    {item && item.from !== "userCollection" && (
                      <div>
                        <div className={"star-rating star" + star}>
                          <input
                            id="star-5"
                            type="radio"
                            name="rating"
                            value="star-5"
                            onClick={(e) => userRating(e)}
                          />
                          <label for="star-5" title="5 stars" className="five">
                            <i
                              className="active fa fa-star"
                              aria-hidden="true"
                            ></i>
                          </label>
                          <input
                            id="star-4"
                            type="radio"
                            name="rating"
                            value="star-4"
                            onClick={(e) => userRating(e)}
                          />
                          <label for="star-4" title="4 stars" className="four">
                            <i
                              className="active fa fa-star"
                              aria-hidden="true"
                            ></i>
                          </label>
                          <input
                            id="star-3"
                            type="radio"
                            name="rating"
                            value="star-3"
                            onClick={(e) => userRating(e)}
                          />
                          <label for="star-3" title="3 stars" className="three">
                            <i
                              className="active fa fa-star"
                              aria-hidden="true"
                            ></i>
                          </label>
                          <input
                            id="star-2"
                            type="radio"
                            name="rating"
                            value="star-2"
                            onClick={(e) => userRating(e)}
                          />
                          <label for="star-2" title="2 stars" className="two">
                            <i
                              className="active fa fa-star"
                              aria-hidden="true"
                            ></i>
                          </label>
                          <input
                            id="star-1"
                            type="radio"
                            name="rating"
                            value="star-1"
                            onClick={(e) => userRating(e)}
                          />
                          <label for="star-1" title="1 star" className="one">
                            <i
                              className="active fa fa-star"
                              aria-hidden="true"
                            ></i>
                          </label>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="mt-3 links_align_hor">
                    {item?.SocialLinks?.length > 0 &&
                      item?.SocialLinks?.map((val) => {
                        return (
                          <>
                            <a href={val?.url} target="_blank">
                              <span className="pr-3">
                                <i
                                  className={val?.classname + " notes_fa mr-2"}
                                ></i>
                                {val.url}
                              </span>{" "}
                            </a>
                          </>
                        );
                      })}
                  </div>
                  {item &&
                    onwer_price.clocktime &&
                    onwer_price.endclocktime &&
                    onwer_price.clocktime != null &&
                    onwer_price.endclocktime != null && (
                      <div className="text-center">
                        <badge className="badge badge-dark badge-timer my-3 badge_red">
                          {new Date(onwer_price.endclocktime) > Date.now() ? (
                            <>
                              <Countdown
                                // date={Date.now()+100000000000}
                                date={new Date(onwer_price.endclocktime)}
                                autoStart={true}
                                onStart={() => new Date(onwer_price.clocktime)}
                                renderer={renderer}
                              ></Countdown>
                              <i className="fas fa-fire ml-2"></i>
                            </>
                          ) : (
                            "Auction Ended"
                          )}
                        </badge>
                      </div>
                    )}

                  {/* <a target="_blank"><button type="submit" className="btn btn_ipfs">View On IPFS</button></a> */}
                  {console.log("kgdsfkgdlskgjdsl", item)}

                  <div className="d-flex align-items-center btn_flx_wrp">
                    {item?.Preview ? (
                      (item?.tokenowners_all)?.some((instowner)=>(instowner?.WhiteListUser?.includes(Wallet_Details.UserAccountAddr && instowner?.balance > 0))) ||
                      (item?.tokenowners_all)?.some((instowner)=>{return (instowner?.tokenOwner == Wallet_Details.UserAccountAddr && instowner?.balance > 0)}) && (
                        <div>
                          <a
                            href={
                              item && item.from !== "userCollection"
                                ? item?.ipfsimage
                                    ?.split("/")
                                    .includes("ipfs" || "http" || "https")
                                  ? item.ipfsimage
                                  : `https://ipfs.io/ipfs/${item.ipfsimage}`
                                : "https://ipfs.io/ipfs/" +
                                  item?.image
                                    ?.split("ipfs://")
                                    .pop()
                                    .split("ipfs/")
                                    .pop()
                                    .split("ipfs:/")
                                    .pop()
                            }
                            target="_blank"
                          >
                            <button type="submit" className="btn btn_ipfs me-3">
                              View On IPFS
                            </button>
                          </a>
                        </div>
                      )
                    ) : (
                      <div>
                        <a
                          href={
                            item && item.from !== "userCollection"
                              ? item?.ipfsimage
                                  ?.split("/")
                                  .includes("ipfs" || "http" || "https")
                                ? item.ipfsimage
                                : `https://ipfs.io/ipfs/${item.ipfsimage}`
                              : "https://ipfs.io/ipfs/" +
                                item?.image
                                  ?.split("ipfs://")
                                  .pop()
                                  .split("ipfs/")
                                  .pop()
                                  .split("ipfs:/")
                                  .pop()
                          }
                          target="_blank"
                        >
                          <button type="submit" className="btn btn_ipfs me-3">
                            View On IPFS
                          </button>
                        </a>
                      </div>
                    )}

                    {item?.additionalImage?.includes("pdf") && (
                      <div className="d-flex align-items-center">
                        <div>
                          {/* <a href={DisplayNFT()} target="_blank"> */}
                          <a
                            href={`/pdfview/${
                              item?.tokenowners_current?.[0]?.tokenOwner ??
                              item?.tokenowners_current?.tokenOwner
                            }/${item?.tokenCounts}`}
                            target="_blank"
                          >
                            <button type="submit" className="btn btn_ipfs me-3">
                              View Book
                            </button>
                          </a>
                        </div>
                        <div className="">
                          <a href={DisplayNFT()} target="_blank">
                            <button type="button" className="btn btn_ipfs me-3">
                              PDF View
                            </button>
                          </a>
                        </div>
                      </div>
                    )}
                    { item?.image?.includes('.pdf') && GetPDFCount}
                    {console.log('dkjhfsfhskjf',item)}
                    { (item?.tokenowners_all?.length > 0 &&
                    item?.tokenowners_all?.some((ownerss)=>ownerss?.tokenOwner?.toLowerCase() == Wallet_Details?.UserAccountAddr?.toLowerCase() ) &&
                    !isEmpty(item?.Preview)) &&
                    <>

                    
                    <div className="me-3">
                    
                      <a href={`${config.Back_URL}/preview/${item?.tokenCreator}/${item.Preview}`} target="_blank">
                        <button type="button" className="btn btn_ipfs">
                          View Preview
                        </button>
                      </a>
                    </div>
                    {/* <div className="me-3">
                      <a href="/" target="_blank">
                        <button type="button" className="btn btn_ipfs">
                          Preview
                        </button>
                      </a>
                    </div> */}

                    </>
}
                  </div>

                    {
                      DisplayNFT().includes('preview') ?

                      <p className="mt-3 mb-0">
                        {/* You are Viewing only {item?.image?.includes('.pdf')?`${PageCount?.Preview}/${PageCount?.Original} of Complete Book NFT.`:(item?.image?.includes('.mp3')?`${PageCount?.Preview}/${PageCount?.Original} of Complete Audio NFT.`:`${PageCount?.Preview}/${PageCount?.Original} of Complete Video NFT.`)} */}
                        You are viewing only  preview {item?.image?.includes('.pdf')?'book':(item?.image?.includes('.mp3')?'audio file':'video file')}. If you
                        want to view the full {item?.image?.includes('.pdf')?'book':(item?.image?.includes('.mp3')?'audio file':'video file')}. Please purchase
                        the NFT {item?.image?.includes('.pdf')?'book':(item?.image?.includes('.mp3')?'audio file':'video file')}. ({item?.image?.includes('.pdf') ? "No.of pages in preview" : (item?.image?.includes('.mp3')?'Preview Audio duration:':' Preview Video duration:') }: {PageCount?.Preview} / {item?.image?.includes('.pdf') ? "No.of pages in full book" : (item?.image?.includes('.mp3')?'Complete Audio duration:':' Complete Video duration:') }: {PageCount?.Original}
                      </p>
                      :(
                      ((item?.tokenowners_all)?.some((instowner)=>{return (instowner?.tokenOwner == Wallet_Details.UserAccountAddr && instowner?.balance > 0)})
                       && item?.Preview) ? (
                          <>
                          <p className="mt-3 mb-0">
                          Preview {item?.image?.includes('.pdf')?'Book Page:':(item?.image?.includes('.mp3')?'Audio duration:':'Video duration:')} {PageCount?.Preview}
                          </p>
                          <p className="mt-3 mb-0">
                          Complete {item?.image?.includes('.pdf')?'Book Page:':(item?.image?.includes('.mp3')?'Audio duration:':'Video duration:')} {PageCount?.Original}
                          </p>
                          </>
                      ) 
                      :(
                        (item?.tokenowners_all)?.some((instowner)=>(instowner?.WhiteListUser?.includes(Wallet_Details.UserAccountAddr && instowner?.balance > 0))) &&
                        <p className="mt-3 mb-0">
                          You are whitelisted so you have access to view the whole {item?.image?.includes('.pdf')?"book":item?.image?.includes('.mp3')?"audio":"video"} file ({item?.image?.includes('.pdf')?"No.of pages in full book":item?.image?.includes('.mp3')?"full audio duration":"full video duration"}: {PageCount.Original})
                        </p>
                      )
                      )
                    }
                  
                  {/* <a href="/bookview" target="_blank">

                          <button type="submit" className="btn btn_ipfs">
                            Book View
                          </button>
                        </a>
                      </div>
                    </div>
                  )}

                  <p className="info_des brak_word_sd">{item.tokenDesc}</p>

                  {MyTokenDetail
                    ? MyTokenDetail.tokenOwner &&
                      MyTokenDetail.tokenOwner ===
                        Wallet_Details.UserAccountAddr &&
                      item.unlockcontent && (
                        <p className="info_des">
                          <ReactMarkdown children={item.unlockcontent} />
                        </p>
                      )
                    : false}
                  {/* {MyTokenDetail &&
                  MyTokenDetail.tokenOwner
                  &&
                  MyTokenDetail.tokenOwner === Wallet_Details.UserAccountAddr
                  &&
                 ( item.unlockcontent
                  && <p className="info_des">
                    <ReactMarkdown children={item.unlockcontent} />
                  </p>)} */}

                  <nav className="masonry_tab_nav items_tab_outer mt-3 mb-3">
                    <div
                      className="nav nav-tabs masonry_tab kr_masonrytabs primary_tab items_tab d-flex items_tab_new pb-2 pl-2"
                      id="nav-tab"
                      role="tablist"
                    >
                      <a
                        className="nav-link active"
                        id="info-tab"
                        data-toggle="tab"
                        href="#info"
                        role="tab"
                        aria-controls="info"
                        aria-selected="true"
                      >
                        <div className="tab_head ">Info</div>
                      </a>
                      <a
                        className="nav-link"
                        id="owners-tab"
                        data-toggle="tab"
                        href="#owners"
                        role="tab"
                        aria-controls="active"
                        aria-selected="false"
                      >
                        <div className="tab_head">Owners</div>
                      </a>
                      {item && item.from !== "userCollection" && (
                        <>
                          <a
                            className="nav-link"
                            id="bid-tab"
                            data-toggle="tab"
                            href="#bid"
                            role="tab"
                            aria-controls="bid"
                            aria-selected="false"
                          >
                            <div className="tab_head">Bid</div>
                          </a>
                          <a
                            className="nav-link"
                            id="history-tab"
                            data-toggle="tab"
                            href="#history"
                            role="tab"
                            aria-controls="history"
                            aria-selected="false"
                          >
                            <div className="tab_head">history</div>
                          </a>
                        </>
                      )}
                    </div>
                  </nav>
                  <div
                    className="tab-content explore_tab_content mt-2"
                    id="nav-tabContent"
                  >
                    <div
                      className="tab-pane fade show active"
                      id="info"
                      role="tabpanel"
                      aria-labelledby="info-tab"
                    >
                      <div className="proposal_panel_overall">
                        <div className="inner_div_info max_ht_unst">
                          {item && item.from !== "userCollection" ? (
                            <>
                              {item && item.tokenowners_current && (
                                <div className="media follow_media info_media">
                                  <div className="info_media_img_div mr-3">
                                    {/* <Avatars item="img-fluid"></Avatars> */}

                                    {item?.tokenCreatorInfo?.image[0] !==
                                      undefined &&
                                    item?.tokenCreatorInfo?.image[0] != "" ? (
                                      <Link
                                        to={
                                          item &&
                                          item.tokenCreatorInfo &&
                                          (item?.tokenCreatorInfo
                                            ?.customurl[0] !== ""
                                            ? `/${item.tokenCreatorInfo.customurl[0]}`
                                            : `/user/${item.tokenCreator}`)
                                        }
                                        title={`Creator : ${
                                          item?.tokenCreatorInfo?.name[0] !== ""
                                            ? item.tokenCreatorInfo.name[0]
                                            : item.tokenCreator
                                        }`}
                                      >
                                        <img
                                          src={`${config.Back_URL}/images/${item.tokenCreatorInfo._id[0]}/${item.tokenCreatorInfo.image[0]}`}
                                          alt="Owner"
                                          className=""
                                        />
                                      </Link>
                                    ) : (
                                      <Link
                                        to={`/user/${item.tokenCreator}`}
                                        title={`Owner : ${item.tokenCreator}`}
                                      >
                                        <Avatars item="img-fluid"></Avatars>
                                      </Link>
                                    )}
                                  </div>
                                  <div className="media-body flex_body">
                                    <div>
                                      {/* <p className="mt-0 media_num">Creator</p>
                                <p className="mt-0 media_text  mb-0" >0x123456789</p> */}

                                      <p className="mt-0 media_num">Creator</p>
                                      <p
                                        className="mt-0 media_text  mb-0"
                                        title={
                                          item.tokenCreatorInfo.name[0] !==
                                            undefined &&
                                          item.tokenCreatorInfo.name[0] != ""
                                            ? item.tokenCreatorInfo.name[0]
                                            : item.tokenCreator
                                        }
                                      >
                                        {item.tokenCreatorInfo.name[0] !==
                                          undefined &&
                                        item.tokenCreatorInfo.name[0] != ""
                                          ? item.tokenCreatorInfo.name[0]
                                          : String(item.tokenCreator)
                                              .slice(0, 8)
                                              .concat("...")}
                                      </p>
                                    </div>
                                    <div className="ml-2 ml-cus">
                                      <div className="card owner_card my-0 border-0">
                                        <div className="card-body p-2">
                                          <div className="flex_txt">
                                            <div className="media_num">
                                              {item.tokenRoyality}%
                                            </div>

                                            <p className="mb-0 price_1 ml-1">
                                              {" "}
                                              Royalty to the Creator
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <div className="media follow_media info_media">
                              <div className="info_media_img_div mr-3">
                                {/* <Avatars item="img-fluid"></Avatars> */}

                                {Wallet_Details?.Profile !== "" ? (
                                  <Link
                                    to={
                                      Wallet_Details &&
                                      (Wallet_Details?.Customurl !== ""
                                        ? `/${Wallet_Details?.Customurl}`
                                        : `/user/${Wallet_Details?.UserAccountAddr}`)
                                    }
                                    title={`Creator : ${
                                      item?.tokenCreatorInfo?.name?.[0] !== ""
                                        ? item?.tokenCreatorInfo?.name?.[0]
                                        : item?.tokenCreator
                                    }`}
                                  >
                                    <img
                                      src={`${config.Back_URL}/images/${item?.tokenCreatorInfo?._id?.[0]}/${item?.tokenCreatorInfo?.image?.[0]}`}
                                      alt="Owner"
                                      className=""
                                    />
                                  </Link>
                                ) : (
                                  <Link
                                    to={`/user/${item?.UserAccountAddr}`}
                                    title={`Owner : ${Wallet_Details?.Regname}`}
                                  >
                                    <Avatars item="img-fluid"></Avatars>
                                  </Link>
                                )}
                              </div>
                              <div className="media-body flex_body">
                                <div>
                                  {/* <p className="mt-0 media_num">Creator</p>
                                <p className="mt-0 media_text  mb-0" >0x123456789</p> */}

                                  <p className="mt-0 media_num">Creator</p>
                                  <p
                                    className="mt-0 media_text  mb-0"
                                    title={Wallet_Details?.UserAccountAddr}
                                  >
                                    {Wallet_Details?.Regname !== undefined &&
                                    Wallet_Details?.Regname !== ""
                                      ? Wallet_Details?.Regname
                                      : String(Wallet_Details?.UserAccountAddr)
                                          .slice(0, 8)
                                          .concat("...")}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* color options */}
                      {console.log('propertiessssss',item?.Properties)}
                    {(item?.Properties?.length > 0 && item?.Properties?.some((val)=>!isEmpty(val))) && (
                      <div>
                        <p className="prop_tle">Properties</p>
                        <div className="d-flex align-items-start flex-column  gap-3 clr_row ovr_flw_y">
                          {console.log("ksjhfksfs", item)}
                          {item?.Properties.map((val) => (
                            <>
                            <div className="clr_border px-3 py-1 d-flex align-items-start gap-2">
                              <div className="mb-0 d-flex align-items-center prop_p">
                                <p className="mb-0 wht_spc_nowrp">{val.key}</p>
                              </div>
                              <div>
                                <p className="mb-0">:</p>
                              </div>
                              <div>
                                <p className="mb-0"> {val.value}</p>
                              </div>
                            </div>
                            </>
                          ))}
                        </div>
                      </div>
                    )}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="owners"
                      role="tabpanel"
                      aria-labelledby="owners-tab"
                    >
                      <div className="proposal_panel_overall">
                        <div className="inner_div_info">
                          {item && item.from !== "userCollection" ? (
                            <>
                              {" "}
                              {tokenUsers &&
                                tokenUsers.length > 0 &&
                                tokenUsers.map((itemCur, i) => {
                                  return (
                                    <div className="media follow_media info_media">
                                      <div className="info_media_img_div mr-3">
                                        {/* <Avatars item="img-fluid" /> */}

                                        {itemCur &&
                                        itemCur?.tusers?.image !== undefined &&
                                        itemCur &&
                                        itemCur?.tusers?.image != "" ? (
                                          <Link
                                            to={
                                              itemCur &&
                                              itemCur?.tusers &&
                                              itemCur?.tusers.customurl !== ""
                                                ? `/${itemCur?.tusers?.customurl}`
                                                : `/user/${itemCur?.tusers?.curraddress}`
                                            }
                                            title={`Owner : ${itemCur?.tusers?.name}`}
                                          >
                                            <img
                                              src={`${config.Back_URL}/images/${itemCur?.tusers?._id}/${itemCur?.tusers?.image}`}
                                              alt="Owner"
                                              className=""
                                            />
                                          </Link>
                                        ) : (
                                          <Link
                                            to={`/user/${itemCur?.tusers?.curraddress}`}
                                            title={`Owner : ${itemCur?.tusers?.curraddress}`}
                                          >
                                            <Avatars item="img-fluid" />
                                            {/* <Avatars classValue="img-fluid img_user_new"/> */}
                                          </Link>
                                        )}
                                      </div>
                                      <div className="media-body flex_body">
                                        <div>
                                          <p className="mt-0 media_num ownedby">
                                            Owned by
                                          </p>
                                          <p className="mt-0 media_text  mb-0">
                                            {itemCur?.tusers?.name !==
                                              undefined &&
                                            itemCur?.tusers?.name != "" ? (
                                              itemCur?.tusers?.name
                                            ) : (
                                              <span title={itemCur?.tokenOwner}>
                                                {(itemCur?.tokenOwner)
                                                  .slice(0, 10)
                                                  .concat("....")}
                                              </span>
                                            )}
                                          </p>

                                          {
                                            // <p className="mt-0 media_text mb-0">
                                            //   0/0 on sale for

                                            //   cake

                                            //   ($ 0)
                                            //   {'each'}</p>

                                            itemCur?.tokenPrice > 0 && (
                                              <p className="mt-0 media_text mb-0">
                                                {itemCur.balance}/
                                                {itemCur.quantity} on sale for
                                                <Convert
                                                  item={itemCur.tokenPrice}
                                                  coinName={itemCur.CoinName}
                                                  convertVal={1}
                                                />
                                                {itemCur.CoinName}
                                                {/* ($ {onwer_price.CoinName == "BLKZ" ? Wallet_Details.tokenAddress.BiddingToken_USD * onwer_price.tokenPrice : Wallet_Details.tokenAddress.XDC_Currency_USD * onwer_price.tokenPrice}) */}
                                                {itemCur.quantity > 0 && "each"}
                                              </p>
                                            )
                                          }
                                          {/* {
                                  <p className="mt-0 media_text mb-0">
                                    0/0 For Sale</p>} */}

                                          {itemCur?.tokenPrice <= 0 && (
                                            <p className="mt-0 media_text mb-0">
                                              {itemCur.balance}/
                                              {itemCur.quantity} For Sale
                                            </p>
                                          )}

                                          {/* {

                                  // <Button className="btn_outline_red ml-2 mb-2"  >Buy Now</Button>
                                } */}

                                          {itemCur.tokenPrice > 0 &&
                                            itemCur.balance > 0 &&
                                            itemCur.tokenOwner !==
                                              Wallet_Details.UserAccountAddr && (
                                              <Button
                                                className="btn_outline_red ml-2 mb-2"
                                                onClick={() =>
                                                  PurchaseNowForwardRef.current.PurchaseNow_Click(
                                                    item,
                                                    itemCur
                                                  )
                                                }
                                              >
                                                Buy Now
                                              </Button>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </>
                          ) : (
                            <div className="media follow_media info_media">
                              <div className="info_media_img_div mr-3">
                                {/* <Avatars item="img-fluid" /> */}

                                {Wallet_Details &&
                                Wallet_Details?.Profile !== "" ? (
                                  <Link
                                    to={
                                      Wallet_Details &&
                                      Wallet_Details?.Customurl !== ""
                                        ? `/${Wallet_Details?.Customurl}`
                                        : `/user/${Wallet_Details?.UserAccountAddr}`
                                    }
                                    title={`Owner : ${Wallet_Details?.Regname}`}
                                  >
                                    <img
                                      src={`${config.Back_URL}/images/${Wallet_Details?._id}/${Wallet_Details?.Profile}`}
                                      alt="Owner"
                                      className=""
                                    />
                                  </Link>
                                ) : (
                                  <Link
                                    to={`/user/${Wallet_Details?.UserAccountAddr}`}
                                    title={`Owner : ${Wallet_Details?.UserAccountAddr}`}
                                  >
                                    <Avatars item="img-fluid" />
                                    {/* <Avatars classValue="img-fluid img_user_new"/> */}
                                  </Link>
                                )}
                              </div>
                              <div className="media-body flex_body">
                                <div>
                                  <p className="mt-0 media_num">Owned by</p>
                                  <p className="mt-0 media_text  mb-0">
                                    {Wallet_Details?.Regname != "" ? (
                                      Wallet_Details?.Regname
                                    ) : (
                                      <span
                                        title={Wallet_Details?.UserAccountAddr}
                                      >
                                        {(Wallet_Details?.UserAccountAddr).slice(
                                          0,
                                          10
                                        ).concat("....")}
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="bid"
                      role="tabpanel"
                      aria-labelledby="bid-tab"
                    >
                      <div className="proposal_panel_overall">
                        <div className="inner_div_info">
                          {Bids &&
                          Bids.pending &&
                          Bids.pending.length > 0 &&
                          item.from !== "userCollection" ? (
                            Bids.pending.map((curBid) => {
                              return (
                                <div className="media follow_media info_media">
                                  <div className="info_media_img_div mr-3">
                                    {curBid.bidUsers && (
                                      <div className="img_prgo_re">
                                        <Link
                                          href={
                                            curBid.bidUsers !== undefined &&
                                            (curBid.bidUsers.customurl !== ""
                                              ? `/${curBid.bidUsers.customurl}`
                                              : `/user/${curBid.tokenBidAddress}`)
                                          }
                                        >
                                          {curBid.bidUsers.image !== "" && (
                                            <img
                                              src={`/images/${curBid.bidUsers._id}/${curBid.bidUsers.image}`}
                                              alt="User"
                                              className=""
                                            />
                                          )}
                                          {curBid.bidUsers.image === "" && (
                                            <Avatars item="img-fluid" />
                                          )}
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                  <div className="media-body flex_body">
                                    <div>
                                      <p className="mt-0 media_num mt-0 word_break_all">
                                        <Convert
                                          item={curBid.tokenBidAmt}
                                          coinName={"WENLAMBO"}
                                          convertVal={1}
                                        />
                                        {
                                          Wallet_Details.networkConfiguration
                                            .tokenSymbol
                                        }
                                        {/* ($ {onwer_price.CoinName == "BLKZ" ? Wallet_Details.tokenAddress.BiddingToken_USD * curBid.tokenBidAmt : Wallet_Details.tokenAddress.XDC_Currency_USD * curBid.tokenBidAmt}) */}
                                        by{" "}
                                        <span
                                          title={
                                            curBid.bidUsers !== undefined &&
                                            (curBid.bidUsers.name !== ""
                                              ? curBid.bidUsers.name
                                              : curBid.tokenBidAddress)
                                          }
                                        >
                                          {curBid.bidUsers !== undefined &&
                                            (curBid.bidUsers.name !== "" ? (
                                              curBid.bidUsers.name
                                            ) : (
                                              <span className="word_brak_txt">
                                                {curBid.tokenBidAddress}
                                              </span>
                                            ))}
                                        </span>{" "}
                                        {AllowedQuantity > 0 && (
                                          <span>
                                            for {curBid.pending}/
                                            {curBid.NoOfToken} edition
                                          </span>
                                        )}
                                      </p>
                                      <p className="my-0 media_text">
                                        {moment(curBid.timestamp).format(
                                          "MMMM Do YYYY, h:mm a"
                                        )}
                                      </p>

                                      {Wallet_Details.UserAccountAddr &&
                                        Wallet_Details.UserAccountAddr !==
                                          curBid.tokenBidAddress &&
                                        item &&
                                        item.tokenowners_current &&
                                        item.tokenowners_current.length > 0 &&
                                        item.tokenowners_current.findIndex(
                                          (e) =>
                                            e.tokenOwner ===
                                            Wallet_Details.UserAccountAddr
                                        ) > -1 && (
                                          <div className="ml-2 ml-cus">
                                            {item &&
                                            item.tokenBid === true &&
                                            item.tokenowners_current[0]
                                              .clocktime != null &&
                                            item.tokenowners_current[0]
                                              .endclocktime != null &&
                                            new Date(
                                              item.tokenowners_current[0].endclocktime
                                            ).getTime() > Date.now() ? (
                                              <Button className="primary_btn mb-2">
                                                You Can't Accept The Bid Until
                                                Auction Complete
                                              </Button>
                                            ) : (
                                              <Button
                                                className="primary_btn mb-2"
                                                onClick={() =>
                                                  PlaceABidForwardRef.current.AcceptBid_Select(
                                                    item,
                                                    curBid
                                                  )
                                                }
                                              >
                                                Accept
                                              </Button>
                                            )}
                                          </div>
                                        )}

                                      {Wallet_Details.UserAccountAddr &&
                                        Wallet_Details.UserAccountAddr ===
                                          curBid.tokenBidAddress &&
                                        item &&
                                        item.tokenBid === true && (
                                          // && item.tokenowners_current.clocktime == null
                                          // && item.tokenowners_current.endclocktime == null
                                          <Button
                                            className="create_btn  ml-1 mb-2"
                                            onClick={() =>
                                              PlaceABidForwardRef.current.CancelBid_Select(
                                                curBid
                                              )
                                            }
                                          >
                                            Cancel
                                          </Button>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <h1 className="nobidyet">No Bids Yet</h1>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="history"
                      role="tabpanel"
                      aria-labelledby="hostory-tab"
                    >
                      <div className="proposal_panel_overall">
                        <div className="inner_div_info">
                          {his.length > 0 &&
                            his.map((hisitem) => {
                              return (
                                <div className="media follow_media info_media">
                                  <div className="info_media_img_div mr-3">
                                    {hisitem?.OwnersHistory?.image !==
                                      undefined &&
                                    hisitem?.OwnersHistory?.image != "" ? (
                                      <Link
                                        to={
                                          hisitem &&
                                          hisitem?.OwnersHistory &&
                                          (hisitem?.OwnersHistory?.customurl !==
                                          ""
                                            ? `/${hisitem?.OwnersHistory?.customurl}`
                                            : `/user/${hisitem?.tokenOwner}`)
                                        }
                                        title={`Creator : ${
                                          hisitem?.OwnersHistory?.name !== ""
                                            ? hisitem?.OwnersHistory?.name
                                            : hisitem?.tokenOwner
                                        }`}
                                      >
                                        <img
                                          src={`${config.Back_URL}/images/${hisitem?.OwnersHistory?._id}/${hisitem?.OwnersHistory?.image}`}
                                          alt="Owner"
                                          className=""
                                        />
                                      </Link>
                                    ) : (
                                      <Link
                                        to={`/user/${hisitem?.tokenOwner}`}
                                        title={`Owner : ${hisitem?.tokenOwner}`}
                                      >
                                        <Avatars item="img-fluid"></Avatars>
                                      </Link>
                                    )}
                                  </div>
                                  <div className="media-body flex_body">
                                    <div>
                                      {/* <p className="mt-0 media_num">{`Owned by`}</p> */}

                                      <p className="mt-0 media_num">
                                        {hisitem?.balance === 0
                                          ? `Previous owned by`
                                          : `Owned by`}
                                      </p>

                                      {/* <p className="mt-0 media_text  mb-0" >0x1234556677</p> */}
                                      <p
                                        className="mt-0 media_text  mb-0"
                                        title={
                                          hisitem?.OwnersHistory?.name !==
                                            undefined &&
                                          hisitem?.OwnersHistory?.name != ""
                                            ? hisitem?.OwnersHistory?.name
                                            : hisitem?.tokenOwner
                                        }
                                      >
                                        {hisitem?.OwnersHistory?.name !==
                                          undefined &&
                                        hisitem?.OwnersHistory?.name != ""
                                          ? hisitem?.OwnersHistory?.name
                                          : String(hisitem.tokenOwner)
                                              .slice(0, 8)
                                              .concat("...")}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* color options */}
                  {/* {item?.Properties?.length > 0 && (
                    <div>
                      <p className="prop_tle">Properties</p>
                      <div className="d-flex align-items-center  gap-3 clr_row">
                        {console.log("ksjhfksfs", item)}
                        {item?.Properties.map((val) => (
                          <div className="clr_border px-3 py-1 d-flex align-items-center gap-2">
                            <i class="fa-solid fa-palette"></i>
                            <p className="mb-0 d-flex align-items-center prop_p">
                              {val.key} <span className="ms-1 me-1">:</span>
                              {val.value}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )} */}

                  <hr />
                  <div className="card info_big_card mb-0 mt-0">
                    <div className="card-body">
                      {Bids?.highestBid && Bids?.highestBid?.tokenBidAmt > 0 && (
                        <>
                          <div className="media follow_media">
                            <div className="info_media_img_div mr-3">
                              {Bids.highestBid.bidUsers &&
                                Bids.highestBid.bidUsers.image !== "" && (
                                  <img
                                    src={`${config.Back_URL}/images/${Bids.highestBid.bidUsers._id}/${Bids.highestBid.bidUsers.image}`}
                                    alt="User"
                                    className=""
                                  />
                                )}

                              {Bids.highestBid.bidUsers &&
                                Bids.highestBid.bidUsers.image === "" && (
                                  <Avatars item="img-fluid" />
                                )}
                            </div>
                            <div className="media-body flex_body">
                              <div>
                                <p className="mt-0 media_text_big_1">
                                  Highest bid by{" "}
                                  <span
                                    className="text_blk"
                                    title={
                                      Bids.highestBid.bidBy &&
                                      Bids.highestBid.bidBy.name
                                        ? Bids.highestBid.bidBy.name
                                        : Bids.highestBid.tokenBidAddress
                                    }
                                  >
                                    {Bids.highestBid &&
                                    Bids.highestBid.bidUsers &&
                                    Bids.highestBid.bidUsers.name
                                      ? Bids.highestBid.bidUsers.name
                                      : Bids.highestBid.tokenBidAddress
                                          .slice(0, 8)
                                          .concat("....")}
                                  </span>
                                </p>
                                <p className="mt-0 mb-0 media_text_big_2">
                                  <Convert
                                    item={
                                      Bids &&
                                      Bids.highestBid &&
                                      Bids.highestBid.tokenBidAmt
                                    }
                                    convertVal={1}
                                    coinName={"WENLAMBO"}
                                  />
                                  {" " +
                                    Wallet_Details.networkConfiguration
                                      .tokenSymbol}
                                  {/* ($ <Convert1
          item={Bids && Bids.highestBid && Bids.highestBid.tokenBidAmt}
          convertVal={config.WenlamboConvertVal}
        />) */}

                                  {/* ($ {Wallet_Details.tokenAddress.BiddingToken_USD * Bids.highestBid.tokenBidAmt}) */}
                                  <span className="text_blk"></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="mt-3 text-center buycnacel">
                        {item && item.from !== "userCollection" ? (
                          <>
                            {BuyOwnerDetailFirst &&
                              BuyOwnerDetailFirst.tokenOwner !==
                                Wallet_Details.UserAccountAddr &&
                              BuyOwnerDetailFirst.tokenPrice > 0 && (
                                <Button
                                  className="btn_outline_red mb-2"
                                  onClick={() =>
                                    PurchaseNowForwardRef.current.PurchaseNow_Click(
                                      item,
                                      BuyOwnerDetailFirst
                                    )
                                  }
                                >
                                  Buy Now
                                </Button>
                              )}

                            {MyTokenDetail &&
                            MyTokenDetail.tokenOwner ===
                              Wallet_Details.UserAccountAddr &&
                            MyTokenDetail.tokenPrice > 0 &&
                            MyTokenDetail.putonsalequantity > 0 ? (
                              <Button
                                className="btn_outline_cancel mb-2"
                                onClick={() =>
                                  CancelOrderForwardRef.current.CancelOrder_Click(
                                    item,
                                    token_owner_detail_first,
                                    MyTokenDetail
                                  )
                                }
                              >
                                Cancel Order
                              </Button>
                            ) : (
                              MyTokenDetail &&
                              MyTokenDetail.tokenOwner &&
                              item &&
                              item.tokenBid === true &&
                              item.tokenowners_current.clocktime != null &&
                              item.tokenowners_current.endclocktime != null &&
                              new Date(item.endclocktime) > Date.now() && (
                                <Button className="create_btn mb-2">
                                  Auction Not Complete Yet
                                </Button>
                              )
                            )}

                            {MyTokenDetail &&
                              MyTokenDetail.balance > 0 &&
                              (MyTokenDetail.tokenPrice === 0 ||
                                MyTokenDetail.tokenPrice === null) &&
                              MyTokenDetail &&
                              // && (MyTokenDetail.putonsalequantity === 0) && (MyTokenDetail.balance > 0)
                              MyTokenDetail &&
                              MyTokenDetail.balance > 0 &&
                              (MyTokenDetail.tokenPrice === 0 ||
                                MyTokenDetail.tokenPrice == null) &&
                              ((MyTokenDetail?.clocktime != null &&
                                MyTokenDetail?.endclocktime != null) ||
                                (MyTokenDetail?.clocktime == null &&
                                  MyTokenDetail?.endclocktime === null)) &&
                              // ?
                              new Date(MyTokenDetail?.endclocktime).getTime() <
                                Date.now() && (
                                <Button
                                  className="btn_outline_cancel mb-2"
                                  onClick={() =>
                                    PutOnSaleForwardRef.current.PutOnSale_Click(
                                      item,
                                      token_owner_detail_first,
                                      MyTokenDetail
                                    )
                                  }
                                >
                                  {MyTokenDetail?.ListNFT
                                    ? "Put On Sale"
                                    : "Deposit"}
                                </Button>
                              )}

                            {item &&
                            item.tokenBid === true &&
                            item?.tokenowners_current[0]?.clocktime != null &&
                            item?.tokenowners_current[0]?.endclocktime !=
                              null &&
                            new Date(item.tokenowners_current[0].endclocktime) <
                              Date.now() ? (
                              !isEmpty(Bids.highestBid) ? (
                                Bids &&
                                !isEmpty(Bids.highestBid) &&
                                Wallet_Details.UserAccountAddr &&
                                Wallet_Details.UserAccountAddr !==
                                  Bids.highestBid.tokenBidAddress &&
                                item &&
                                item.tokenowners_current &&
                                item.tokenowners_current.findIndex(
                                  (e) =>
                                    e.tokenOwner ===
                                    Wallet_Details.UserAccountAddr
                                ) > -1 ? (
                                  <Button
                                    className="btn_outline_red ml-2 mb-2"
                                    onClick={() =>
                                      PlaceABidForwardRef.current.AcceptBid_Select(
                                        item,
                                        Bids.highestBid
                                      )
                                    }
                                  >
                                    Accept
                                  </Button>
                                ) : (
                                  <Button className="btn_outline_red ml-2 mb-2 waiting_for_owner">
                                    Waiting for owner to accept the bid
                                  </Button>
                                )
                              ) : (
                                // <p className="btn_outline_red ml-2 mb-2">Waiting for owner to Accept</p>
                                <p className="btn_outline_red ml-2 mb-2 timed-aution-ended">
                                  Timed auction ended
                                </p>
                              )
                            ) : // MyTokenBalance>0 ?
                            AllowedQuantity > MyTokenBalance ? (
                              Bids && Bids.myBid && !Bids.myBid.status ? (
                                <Button
                                  className="btn_outline_red mb-2"
                                  onClick={() =>
                                    PlaceABidForwardRef.current.PlaceABid_Click(
                                      item,
                                      onwer_price,
                                      Bids
                                    )
                                  }
                                >
                                  Bid now
                                </Button>
                              ) : (
                                Bids &&
                                Bids.myBid &&
                                Bids.myBid.status &&
                                (Bids.myBid.status === "pending" ? (
                                  <Button
                                    className="btn_outline_red ml-2 mb-2"
                                    onClick={() =>
                                      PlaceABidForwardRef.current.PlaceABid_Click(
                                        item,
                                        onwer_price,
                                        Bids
                                      )
                                    }
                                  >
                                    Edit a bid
                                  </Button>
                                ) : (
                                  Bids &&
                                  Bids.myBid &&
                                  Bids.myBid.status &&
                                  Bids.myBid.status ===
                                    "partiallyCompleted" && (
                                    <Button
                                      className="btn_outline_red ml-2 mb-2"
                                      onClick={() =>
                                        PlaceABidForwardRef.current.CancelBid_Select(
                                          Bids.myBid
                                        )
                                      }
                                    >
                                      Cancel a bid
                                    </Button>
                                  )
                                ))
                              )
                            ) : (
                              ""
                            )}

                            {/* <Button className="create_btn mb-2 ml-1" data-target="#PutOnSale_modal" data-toggle="model">
                        Bid Now
                      </Button>
                      <Button className="create_btn mb-2 ml-1" data-target="#PutOnSale_modal" data-toggle="model">
                        Bid Now
                      </Button> */}
                          </>
                        ) : (
                          <Button
                            className="btn_outline_red ml-2 mb-2"
                            onClick={() =>
                              history.push(
                                `/list/${Wallet_Details?.UserAccountAddr}/${tokenidval}`,
                                { state: item }
                              )
                            }
                          >
                            List On MarkePlace
                          </Button>
                        )}
                      </div>
                      {onwer_price.tokenPrice > 0 && (
                        <p className="mt-0 media_text_big_1 text-center">
                          Service fee{" "}
                          {Wallet_Details.Service_Fee_buyer /
                            config.decimalvalues}
                          %,
                          <Convert
                            item={Number(onwer_price.tokenPrice)}
                            coinName={onwer_price.CoinName}
                            convertVal={1}
                          />
                          {onwer_price.CoinName}
                          {/* ($ {onwer_price.CoinName == "BLKZ" ? Wallet_Details.tokenAddress.BiddingToken_USD * onwer_price.tokenPrice : Wallet_Details.tokenAddress.XDC_Currency_USD * onwer_price.tokenPrice}) */}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    </div>
  );
}
